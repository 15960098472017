import React, { useEffect } from 'react';

import {
  Breakpoints,
  ButtonAppearance,
  ButtonCustomLink,
  buttonPaddingGenerator,
  CustomImage,
  DisableFocusWrapper,
  LinkButton,
  SupDisclaimer,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextType,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';

import { CustomCTAData } from '../../../../config/tier-1/model-order';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../../hooks-store/store';
import {
  ImageOfferData,
  OfferInformation,
} from '../../../../hooks-store/typings/incentive-store';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { useTranslations } from '../../../../services/use-translations';
import { AppConstants } from '../../../../utils/app-constants';
import {
  DisclaimerKeys,
  getDisclaimerKeyByModelAndPage,
} from '../../../../utils/disclaimers';
import { separateThousand, transformIrisUrl } from '../../../../utils/general';
import {
  onHandleBasicFormButtonLink,
  onHandlerBasicVehicleLink,
} from '../../../../utils/tagging-helpers';
import { NoResults } from '../model-offer-head/no-results';
import { carTilePriceTextStyle } from './helpers';
import {
  StyledBackground,
  StyledButtonSection,
  StyledButtonWrapper,
  StyledHeadOfferWrapper,
  StyledImageGroup,
  StyledImagePlaceholder,
  StyledImageWrapper,
  StyledModelCardWrapper,
  StyledNoResultsWrapper,
  StyledOffersTextSection,
  StyledTextWrapper,
  StyledTrimLabel,
  StyleLinkTextWrapper,
} from './styled';

interface ModelCardProps {
  readonly offerInformation: OfferInformation;
  readonly modelMsrp?: string;
}

export const ModelCard = ({
  offerInformation,
  modelMsrp,
}: ModelCardProps): JSX.Element | null => {
  const isMobile = useIsMobile(Breakpoints.b560);
  const {
    faHostServerUrl = '',
    source,
    trimName,
    carlineId,
    salesgroupId,
    modelId,
    modelYear,
    modelVersion,
    type,
    drEnabled,
    vin,
    vehicleImage,
  } = useFeatureAppConfig();
  const isMofaTrimLevel = (type === 'trim' && trimName) && source === 'showroom';
  const builderUrl: string = isMofaTrimLevel
    ? AppConstants.BuilderEngineUrl
    : AppConstants.BuilderUrl;
  const vdpUrl: string = AppConstants.VDPUrl;
  const [
    store,
    { [IncentiveConstants.SetModalFormData]: setModalFormData,
      [IncentiveConstants.SetDRModalData]: setDRModalData,
    },
  ] = useStore();

  const trackingManager = useTrackingManager();
  const translations = useTranslations().offersSection.modelOfferSection
    .modelOfferHead;

  const [imageMap, setImageMap] = React.useState<ImageOfferData>(
    offerInformation.imageData,
  );

  const isNotStandardModel =
    typeof offerInformation.modelCustomCta !== 'undefined';

  useEffect(() => {
    if (offerInformation.imageData) {
      setImageMap(offerInformation.imageData);
    }
  }, [offerInformation.imageData]);

  const setContactDealerFormData = () => {
    setModalFormData(
      {
        modelDisplayName: offerInformation?.modelDisplayName,
        modelKey: offerInformation?.modelKey,
        mediaImageUrl:
          transformIrisUrl(
            imageMap?.modelImage,
            AppConstants.IrisImgPositionE13,
            '960',
            '432',
            imageMap?.modelYear,
            offerInformation.modelKey,
            imageMap?.isNewImage,
          ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
      },
      store.dealer,
      AppConstants.ContactDealerFormType,
      AppConstants.ContactDealerFormName,
    );
    onHandleBasicFormButtonLink(
      offerInformation,
      translations.contactDealer,
      trackingManager,
      store,
    );
  };

  const setGetAQuoteFormData = () => {
    setModalFormData(
      {
        modelDisplayName: offerInformation.modelDisplayName,
        modelKey: offerInformation.modelKey,
        mediaImageUrl:
          transformIrisUrl(
            imageMap?.modelImage,
            AppConstants.IrisImgPositionE13,
            '960',
            '432',
            imageMap?.modelYear,
            offerInformation.modelKey,
            imageMap?.isNewImage,
          ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
      },
      store.dealer,
      AppConstants.GetAQuoteFormType,
      AppConstants.GetAQuoteFormName,
    );
    onHandleBasicFormButtonLink(
      offerInformation,
      translations.getAQuote,
      trackingManager,
      store,
    );
  };

  const setModelCustomCtaFormData = () => {
    onHandleBasicFormButtonLink(
      offerInformation,
      offerInformation.modelCustomCta?.ctaLabel || '',
      trackingManager,
      store,
      offerInformation.modelCustomCta?.url,
    );
  };

  const goToVDP = () => {
    let newUrl = vdpUrl;

    if (vin) {
      const formattedVin = vin.replace(/\./g, '-');
      newUrl = newUrl.replace('{vin}', formattedVin);

      onHandleBasicFormButtonLink(
        offerInformation,
        translations.viewVehicle,
        trackingManager,
        store,
        newUrl,
      );
      window.open(newUrl, '_self');
    }
  };

  const onOpenDRMOdal = () => {
    setDRModalData(
      {
        active: true,
      }
    );
    onHandleBasicFormButtonLink(
      offerInformation,
      translations.buildMyDeal,
      trackingManager,
      store,
    );
  };


  const ctaActions = {
    getAQuote: setGetAQuoteFormData,
    contactDealer: setContactDealerFormData,
    buildReserve: setModelCustomCtaFormData,
    viewVehicle: goToVDP,
    buildMyDeal: onOpenDRMOdal,
  };

  const getCTAs = (): CustomCTAData[] => {
    const modelCustomArrayCta = offerInformation?.modelCustomArrayCta;
    const modelCustomArrayCtaEnabled =
      offerInformation?.modelCustomArrayCtaEnabled;
    const modelCustomCta = offerInformation?.modelCustomCta;

    if (
      modelCustomArrayCtaEnabled &&
      modelCustomArrayCta &&
      source !== 'inventoryResults'
    ) {
      return modelCustomArrayCta;
    } else if (source === 'inventoryResults') {
      return [
        {
          type: ButtonAppearance.Secondary,
          actionType: 'viewVehicle',
          ctaLabel: translations.viewVehicle,
        },
        drEnabled
          ? {
              type: ButtonAppearance.Primary,
              actionType: 'buildMyDeal',
              ctaLabel: translations.buildMyDeal,
            }
          : {
              type: ButtonAppearance.Primary,
              actionType: 'getAQuote',
              ctaLabel: translations.getAQuote,
            },
      ];
    } else if (isNotStandardModel) {
      return [
        {
          actionType: 'buildReserve',
          ...modelCustomCta,
        },
      ];
    } else {
      return [
        {
          type: ButtonAppearance.Secondary,
          actionType: 'getAQuote',
          ctaLabel: translations.getAQuote,
        },
        {
          type: ButtonAppearance.Primary,
          actionType: 'contactDealer',
          ctaLabel: translations.contactDealer,
        },
      ];
    }
  };

  const ctas = getCTAs();

  const disclaimerKey = getDisclaimerKeyByModelAndPage(
    DisclaimerKeys.LandingKey,
    offerInformation.slug,
  );

  const handleRedirectToBuilder = (slug: string) => {
    let newUrl = builderUrl;

    const formattedSlug = slug.replace(/\./g, '-');
    newUrl = newUrl.replace('{slug}', formattedSlug);

    if (
      type=== 'trim' &&
      source == 'showroom' &&
      salesgroupId &&
      carlineId &&
      modelYear &&
      modelId &&
      modelVersion &&
      trimName
    ) {
      const formattedTrim = trimName.toLowerCase().replace(/\s/g, '-');
      newUrl = newUrl.replace(/{trim}/g, formattedTrim);
      newUrl = newUrl.replace('{salesGroupId}', salesgroupId);
      newUrl = newUrl.replace('{carlineId}', carlineId);
      newUrl = newUrl.replace('{modelYear}', modelYear);
      newUrl = newUrl.replace('{modelId}', modelId);
      newUrl = newUrl.replace('{modelVersion}', modelVersion);
    }

    onHandlerBasicVehicleLink(
      newUrl || undefined,
      translations.buildAndPrice,
      formattedSlug || undefined,
      trackingManager,
      undefined,
    );

    window.open(newUrl, '_self');
  };

  return (
    <StyledModelCardWrapper>
      <StyledHeadOfferWrapper className="header-offer-wrapper">
        <StyledImageGroup>
          {offerInformation.modelKey !== AppConstants.AllOfferModelKey && (
            <StyledBackground
              data-bg={imageMap?.background}
              backgroundColor="#F3F4F5"
            />
          )}
          <StyledTextWrapper
            headTextColor="#00000"
            backgroundSection={imageMap?.background || '#BD3319'}
            applyBg={
              offerInformation.modelKey === AppConstants.AllOfferModelKey
            }
          >
            <TextCustom
              appearance={TextAppearance.headline200}
              style={{
                [Breakpoints.default]: {
                  fontWeight: TextWeight.bold,
                  fontSize: TextSize.TextSize32,
                  lineHeight: TextLineHeight.TextLineHeight36,
                },
              }}
            >
              {offerInformation.modelDisplayName}
            </TextCustom>
            {(isMofaTrimLevel || source === 'inventoryResults') && trimName && (
              <StyledTrimLabel>
                <TextCustom
                  appearance={TextAppearance.headline200}
                  style={{
                    [Breakpoints.default]: {
                      fontWeight: TextWeight.regular,
                      fontSize: TextSize.TextSize20,
                      lineHeight: TextLineHeight.TextLineHeight24,
                    },
                  }}
                >
                  {trimName}
                </TextCustom>
              </StyledTrimLabel>
            )}
            {modelMsrp && (
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize16,
                    lineHeight: TextLineHeight.TextLineHeight24,
                    fontWeight: TextWeight.bold,
                  },
                }}
              >
                {source !== 'inventoryResults'
                  ? translations.startingMsrp
                  : translations.totalMsrp}
                {separateThousand(modelMsrp ?? '0')}{' '}
                <TextCustom {...carTilePriceTextStyle}>
                  <SupDisclaimer>{disclaimerKey}</SupDisclaimer>
                </TextCustom>
              </TextCustom>
            )}
            {source && source === 'builder' ? (
              <></>
            ) : (
              <StyleLinkTextWrapper>
                <LinkButton
                  isInline
                  onClick={() => handleRedirectToBuilder(offerInformation.slug)}
                >
                  <TextCustom
                    appearance={TextAppearance.label100}
                    tag={TextTag.p}
                    style={{
                      [Breakpoints.default]: {
                        fontSize: TextSize.TextSize14,
                        lineHeight: TextLineHeight.TextLineHeight16,
                        color: TextColor.inherit,
                        fontWeight: TextWeight.bold,
                      },
                    }}
                  >
                    {translations.buildAndPrice}
                  </TextCustom>
                </LinkButton>
              </StyleLinkTextWrapper>
            )}
          </StyledTextWrapper>
          {offerInformation.modelKey !== AppConstants.AllOfferModelKey && (
            <>
              <StyledImagePlaceholder>
                <StyledImageWrapper className="image-wrapper">
                  <CustomImage
                    alt={offerInformation.modelDisplayName || ''}
                    srcPlaceholder={AppConstants.PlaceHolder480x216 as string}
                    srcErrorImage={
                      faHostServerUrl + AppConstants.Ngw6Logo480x216
                    }
                    src={
                      vehicleImage ? vehicleImage : transformIrisUrl(
                        imageMap?.modelImage,
                        AppConstants.IrisImgPositionE13,
                        '480',
                        '216',
                        imageMap?.modelYear,
                        offerInformation?.modelKey,
                        imageMap?.isNewImage,
                      ) ?? faHostServerUrl + AppConstants.Ngw6Logo480x216
                    }
                  />
                </StyledImageWrapper>
                {!isMobile && (
                  <StyledOffersTextSection>
                    <TextCustom
                      appearance={TextAppearance.label100}
                      style={{
                        [Breakpoints.default]: {
                          fontSize: TextSize.TextSize24,
                          lineHeight: TextLineHeight.TextLineHeight28,
                          fontWeight: TextWeight.regular,
                          fontType: TextType.Headline,
                        },
                      }}
                    >
                      {`${offerInformation.offerCount} ${
                        offerInformation.offerCount === 1 ? 'Offer' : 'Offers'
                      }`}
                    </TextCustom>
                  </StyledOffersTextSection>
                )}
              </StyledImagePlaceholder>
              <StyledButtonWrapper className="buttons-wrapper">
                {ctas?.map((cta: CustomCTAData) => (
                  <StyledButtonSection>
                    <DisableFocusWrapper>
                      <ButtonCustomLink
                        appearance={cta?.type || ButtonAppearance.Secondary}
                        padding={buttonPaddingGenerator(12, 20, 30)}
                        stretchContent
                        href={cta?.url}
                        target={cta?.openNewTab ? '_blank' : '_self'}
                        onClick={cta?.actionType && ctaActions[cta?.actionType]}
                      >
                        <Text
                          color={TextColor.inherit}
                          appearance={TextAppearance.label150}
                        >
                          {cta?.ctaLabel}
                        </Text>
                      </ButtonCustomLink>
                    </DisableFocusWrapper>
                  </StyledButtonSection>
                ))}
              </StyledButtonWrapper>
              {isMobile && (
                <StyledOffersTextSection>
                  <TextCustom
                    appearance={TextAppearance.label100}
                    style={{
                      [Breakpoints.default]: {
                        fontSize: TextSize.TextSize16,
                        lineHeight: TextLineHeight.TextLineHeight20,
                        fontWeight: TextWeight.light,
                        fontType: TextType.Headline,
                      },
                    }}
                  >
                    {`${offerInformation.offerCount} ${
                      offerInformation.offerCount === 1 ? 'Offer' : 'Offers'
                    }`}{' '}
                    for the model {offerInformation.modelDisplayName}
                  </TextCustom>
                </StyledOffersTextSection>
              )}
            </>
          )}
        </StyledImageGroup>
      </StyledHeadOfferWrapper>
      {offerInformation.offerCount === 0 && (
        <StyledNoResultsWrapper>
          <NoResults
            offerInformation={offerInformation}
            imageMap={imageMap}
            setContactDealerFormData={setContactDealerFormData}
            setGetAQuoteFormData={setGetAQuoteFormData}
            setModelCustomCtaFormData={setModelCustomCtaFormData}
            ctas={ctas}
            ctasActions={ctaActions}
          />
        </StyledNoResultsWrapper>
      )}
    </StyledModelCardWrapper>
  );
};
