import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import {
  FeatureAppDefinition,
  FeatureServices,
  Logger,
} from '@feature-hub/core';
import { HistoryServiceV1 } from '@feature-hub/history-service';
import { ReactFeatureApp } from '@feature-hub/react';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ServerRequestV1 } from '@feature-hub/server-request';
import { DisclaimerManagerV1 } from '@volkswagen-onehub/disclaimer-manager';
import { FeatureappServiceV1 } from '@volkswagen-onehub/featureapp-service';
import { NavigationServiceV1 } from '@volkswagen-onehub/navigation-service';
import { ThemeProvider } from '@vw-marketing/us-components';
import { makeVar } from '@apollo/client';

import { CarConfigurationServiceV1 } from '@volkswagen-onehub/car-configuration-feature-service';

import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';

import { ServiceConfigProviderV1 } from '@volkswagen-onehub/service-config-provider';

import { ShowroomNavigationManagerV1 } from '@volkswagen-onehub/showroom-navigation';

import { TrackingManagerV1 } from '@volkswagen-onehub/tracking-service';

import React from 'react';
import { App } from './app';
import { MappedTrimNameModel } from './config/mapped-trim-name';
import { ModelOrder } from './config/tier-1/model-order';
import configureIncentiveStore from './hooks-store/incentive-store';
import { StoreModel } from './hooks-store/typings/store';
import {
  FeatureAppMemoryStoreV1,
  featureAppMemoryStoreDefinition,
} from './services/feature-app-memory-store';
// import {getStringifiedServiceConfigsServiceConfig} from './get-stringified-service-configs-service-config';
import { FeatureAppEnvironmentContext } from './services/use-feature-app-environment';
import { HistoryContext } from './services/use-history';

import { ZipManagerV1, defineZipManager } from '@volkswagen-onehub/zip-manager';
import { cleanStore } from './hooks-store/store';
import { TranslationsContext } from './services/use-translations';
import { translations } from './translations';

import { styled } from '@vw-marketing/us-components';
import { FeatureServicesContext } from './context/use-feature-services';
declare var __VERSION__: String;

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { InventoryDisclaimers } from './typings/offer-disclaimer';
import { VehicleModel } from './typings/general';

export interface FeatureAppDependencies extends FeatureServices {
  readonly 's2:async-ssr-manager'?: AsyncSsrManagerV1;
  readonly 's2:history': HistoryServiceV1;
  readonly 's2:logger'?: Logger;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1;
  readonly 's2:server-request'?: ServerRequestV1;
  readonly 'car-configuration': CarConfigurationServiceV1;
  readonly 'disclaimer-manager': DisclaimerManagerV1;
  readonly 'zip-manager': ZipManagerV1;
  readonly 'feature-app-memory-store': FeatureAppMemoryStoreV1<StoreModel>;
  readonly 'featureapp-service': FeatureappServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'service-config-provider': ServiceConfigProviderV1;
  'navigation-service': NavigationServiceV1;
  readonly 'showroom-navigation': ShowroomNavigationManagerV1;
  readonly 'tracking'?: TrackingManagerV1;
}

export type FeatureAppMode =
  | 'default'
  | 'standalone'
  | 'module'
  | 'tier-1'
  | 'tier-2'
  | 'modal';

export type ModalPageName =
  | 'default'
  | 'showroom'
  | 'inventory'
  | 'builder'
  | 'inventoryResults';

export interface VehicleCategories {
  category: string;
  vehicles: string[];
}

export interface FeatureAppConfig {
  readonly disclaimers?: InventoryDisclaimers;
  readonly mode?: FeatureAppMode;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly footerDisclaimersFeatureAppUrl?: string;
  readonly scrollTopOffset?: number;
  readonly configureFeatureAppId?: string;
  readonly faServicesUrl: string;
  readonly modelOrder?: ModelOrder[];
  readonly mappedTrimName?: MappedTrimNameModel;
  readonly filterOrder?: filterOrderList;
  readonly hideViewModelDetailsCTA?: boolean;
  readonly hideSearchInventoryCTA?: boolean;
  readonly faHostServerUrl?: string;
  readonly InventoryRedirectPageName?: string;
  readonly InventoryRedirectFAName?: string;
  readonly sofaRedirectPageName?: string;
  readonly sofaRedirectFAName?: string;
  readonly offersWithoutNationalText?: number[];
  readonly wseBannerConfig?: WSEBannerConfig;
  readonly activeNationalOffers?: boolean;
  readonly testDriveQuestion?: string;
  readonly testDriveScheduleLabel?: string;
  readonly testDriveTimeLabel?: string;
  readonly enableTestDrive?: boolean;
  readonly specialEventConfig?: SpecialEventConfig;
  readonly specialOffersRedesignEnable?: boolean;
  readonly vehicleCategories?: VehicleCategories[];
  readonly modelName?: string;
  readonly trimName?: string;
  readonly carlineId?: string;
  readonly salesgroupId?: string;
  readonly modelId?: string;
  readonly modelYear?: string;
  readonly modelVersion?: string;
  readonly source?: ModalPageName;
  readonly type?: string;
  readonly drEnabled?: boolean;
  readonly vin?: string;
  readonly vehicleImage?: string
  readonly vehicle?: VehicleModel;
  readonly informationVendor?: string;
  readonly obLink?: string;
  readonly isLinkModified?: boolean;
  readonly symbol?: string;
}

export interface SpecialEventConfig {
  filterOptions?: SpecialEventFilterOption[];
}
export interface SpecialEventFilterOption {
  isActive?: boolean;
  value?: string;
  text?: string;
}
interface WSEBannerConfig {
  readonly landingPage?: Images;
}

interface Images {
  readonly mobileImage?: string;
  readonly desktopImage?: string;
  readonly enabled: boolean;
}

export type filterOrderList = string[];

export const featureAppDefinition: FeatureAppDefinition<
  ReactFeatureApp,
  FeatureAppDependencies,
  FeatureAppConfig
> = {
  dependencies: {
    featureServices: {
      's2:history': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'car-configuration': '^1.0.0',
      'disclaimer-manager': '^1.1.0',
      'zip-manager': '^1.2.1',
      'feature-app-memory-store': '^1.0.0',
      'featureapp-service': '^1.3.0',
      'locale-service': '^1.0.0',
      'service-config-provider': '^1.0.0',
      'showroom-navigation': '^1.1.0',
      'navigation-service': '^1.1.0',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:async-ssr-manager': '^1.0.0',
      's2:logger': '^1.0.0',
      's2:server-request': '^1.0.0',
      'tracking': '^1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [
    featureAppMemoryStoreDefinition,
    defineZipManager(),
  ],

  create: env => {
    let appVersion;
    const { baseUrl, featureServices } = env;
    // const {featureServices} = env;

    const {
      's2:async-ssr-manager': asyncSsrManager,
      's2:history': historyService,
      's2:serialized-state-manager': serializedStateManager,
      // 'disclaimer-manager': disclaimerManager,
      'feature-app-memory-store': memoryStore,
      // 'locale-service': localeService,
      // 'service-config-provider': serviceConfigProvider,
      // 's2:server-request': serverRequest,
      // 'featureapp-service': featureAppService
    } = featureServices;

    const history = asyncSsrManager
      ? historyService.createStaticHistory()
      : historyService.createBrowserHistory();

    const serializedState = serializedStateManager.getSerializedState();

    if (serializedState && !memoryStore.getState()) {
      memoryStore.setState(JSON.parse(serializedState));
    }

    serializedStateManager.register(() =>
      JSON.stringify(memoryStore.getState()),
    );

    /*
    disclaimerManager.setDefaultScopeForConsumer(
      efficiencyLayerDisclaimerConfig.footerDisclaimersFeatureAppId,
      efficiencyLayerDisclaimerConfig.disclaimerManagerScope
    );
    */
    // const {countryCode, currency, language} = localeService;
    // const {mockIds, mocksBaseUrl, configureFeatureAppId = 'configure'} = config;
    // const {configureFeatureAppId = 'configure'} = config;

    /*
    const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
      serviceConfigProvider,
      serverRequest
    );
      */

    cleanStore(memoryStore);
    configureIncentiveStore();

    const StyledVersionContainer: any = styled.span`
      display: none;
    `;

    try {
      appVersion = __VERSION__;
    } catch (_) {
      appVersion = '';
    }
    return {
      render: () => (
        <HistoryContext.Provider value={history}>
          <FeatureAppEnvironmentContext.Provider value={env}>
            <FeatureServicesContext.Provider value={featureServices}>
              <TranslationsContext.Provider value={translations.en}>
                <ThemeProvider theme="main">
                  <StyledVersionContainer>
                    Feature App Version :: SOFA :: {appVersion}
                  </StyledVersionContainer>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <App baseUrl={baseUrl} />
                  </LocalizationProvider>
                </ThemeProvider>
              </TranslationsContext.Provider>
            </FeatureServicesContext.Provider>
          </FeatureAppEnvironmentContext.Provider>
        </HistoryContext.Provider>
      ),
    };
  },
};

export default featureAppDefinition;
