import {
  createTagOverride,
  TextCustom,
  TextAppearance,
  TextTag,
  TextSize,
  TextLineHeight,
  TextColor,
  TextCustomProps,
  TextType,
  TextWeight,
} from '@vw-marketing/us-components';

const textStyle: TextCustomProps = {
  appearance: TextAppearance.headline100,
  tag: TextTag.span,
  style: {
    fontSize: TextSize.TextSize18,
    lineHeight: TextLineHeight.TextLineHeight24,
    color: TextColor.primary,
    fontType: TextType.Headline,
    fontWeight: TextWeight.light,
  },
};

export const interstitialCardOverrides = {
  p: createTagOverride(TextCustom, textStyle),
};
