import {BreakpointWrapper, Breakpoints} from '@vw-marketing/us-components';
import React from 'react';
import {DealerView} from '..';
import {useStore} from '../../../../hooks-store/store';
import {DealerModel} from '../../../../hooks-store/typings/incentive-store';
import DealerList from './dealer-list';
import DealerMap from './dealer-map';
import ModalDealerCard from './modal-dealer-card';
import {DealerListContainer, FindDealerContainer, MapContainer} from './styled';
import {useTrackingManager} from '../../../../context/use-tracking-manager';
import {onHandleDealerTrackingEvent} from '../../../../utils/tagging-helpers';

/**
 * Defines selected dealer needed data
 */
export interface ActiveDealerState {
  dealerId?: string;
  center: {
    lat: number;
    lng: number;
  };
}
/**
 * Interal Modal state
 */
interface ActiveMobileModalState {
  active: boolean;
  dealer?: DealerModel;
}

/**
 * Find a dealer component definition
 */
export interface FindDealerProps {
  activeView: DealerView;
}

const FindDealer = ({activeView}: FindDealerProps): JSX.Element => {
  const store = useStore()[0];
  const [mobileModalState, setMobileModalState] = React.useState<
    ActiveMobileModalState
  >({active: false});

  const trackingManager = useTrackingManager();

  // Initialize selected dealer with aor dealer
  const [activeDealer, setActiveDealer] = React.useState<ActiveDealerState>({
    dealerId: store.dealer?.dealerid,
    center: {
      lat: store.dealer?.lat
        ? parseFloat(store.dealer.lat)
        : store.location.lat,
      lng: store.dealer?.lng ? parseFloat(store.dealer.lng) : store.location.lng
    }
  });

  /**
   * Update all state related with selected deaer
   * @param dealer
   * @param showModal
   */
  const updateActiveDealer = (
    dealer?: DealerModel,
    showModal?: boolean,
    isTracking?: boolean
  ) => {
    if (dealer) {
      if (activeDealer.dealerId !== dealer?.dealerid) {
        setActiveDealer({
          dealerId: dealer.dealerid,
          center: {
            lat: parseFloat(dealer.lat),
            lng: parseFloat(dealer.lng)
          }
        });
        if (showModal) {
          setMobileModalState({active: true, dealer});
        }
      }
      // Tracking section
      isTracking && onHandleDealerTracking(dealer, dealer.name, 'map icon');
    }
  };

  React.useEffect(() => {
    updateActiveDealer(store.dealer, false, false);
  }, [store.location]);

  React.useEffect(() => {
    const closeOnResize = () => setMobileModalState({active: false});
    window.addEventListener('resize', closeOnResize);
    return () => window.removeEventListener('resize', closeOnResize);
  }, []);

  const onHandleDealerTracking = (
    dealer?: DealerModel,
    name?: string,
    type?: 'map icon' | 'button click'
  ) => {
    onHandleDealerTrackingEvent(dealer, name, trackingManager, store, type);
  };

  return (
    <FindDealerContainer>
      <BreakpointWrapper max={Breakpoints.b560}>
        <ModalDealerCard
          active={mobileModalState.active}
          onCloseModal={() => setMobileModalState({active: false})}
          dealer={mobileModalState.dealer}
          onHandleDealerTracking={onHandleDealerTracking}
        />
      </BreakpointWrapper>
      <MapContainer activeView={activeView === 'map'}>
        <DealerMap
          dealers={store.dealers}
          activeDealer={activeDealer}
          updateActiveDealer={updateActiveDealer}
          zoom={11}
          userLocation={store.location}
        ></DealerMap>
      </MapContainer>
      <DealerListContainer activeView={activeView === 'list'}>
        <DealerList
          dealers={store.dealers}
          activeDealerId={activeDealer.dealerId}
          setActiveDealer={updateActiveDealer}
          onHandleDealerTracking={onHandleDealerTracking}
        />
      </DealerListContainer>
    </FindDealerContainer>
  );
};

export default FindDealer;
