import React from 'react';
import {useFeatureServices} from './use-feature-services';

export function useDisclaimerReference(
  disclaimerText: string | undefined,
  scope?: string
): string | undefined {
  const disclaimerManager = useFeatureServices()[
    'disclaimer-manager'
  ].getScopedDisclaimerManager(scope);

  const [disclaimerReference, setDisclaimerReference] = React.useState<
    string | undefined
  >();

  React.useEffect(() => {
    if (!disclaimerText) {
      return;
    }

    const registeredDisclaimerReference = disclaimerManager.registerReferenceDisclaimer(
      'footer-reference',
      disclaimerText
    );

    setDisclaimerReference(registeredDisclaimerReference);

    return () => {
      disclaimerManager.unregisterReferenceDisclaimer(
        'footer-reference',
        disclaimerText
      );
    };
  }, [disclaimerText]);

  return disclaimerReference;
}
