import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledInterstitialCard = styled.div`
  ${({ theme }) => `
  width: 100%;
  border-radius: 5px;
  background-color: rgb(223, 228, 232, 0.2);
  display: grid;
  grid-column-gap: ${theme.size.grid001};
  grid-auto-rows: min-content;
  grid-template-areas: 'icon content' '. content';
  padding: ${theme.size.grid001};
  @media (min-width: ${Breakpoints.b560}px) {
    grid-template-areas: 'icon content';
    grid-auto-rows: min-content;
    align-items: baseline;
  }
  @media (min-width: ${Breakpoints.b960}px) {
    grid-column-gap: calc(${theme.size.grid001}/2);
    padding: calc(${theme.size.grid001}/2);
    flex-flow: column;
    align-items: self-start;
    justify-items: center;
    grid-template-areas: 'icon content';
  }
  `}
`;

export const StyledInterstitialIcon = styled.div`
  border-radius: 50%;
  background-color: #ffffff;
  color: #001e50;
  grid-area: icon;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  @media (min-width: ${Breakpoints.b960}px) {
    width: 85px;
    height: 85px;
  }
`;

export const StyledInterstitialStepContainer = styled.div`
  width: 100%;
  @media (max-width: ${Breakpoints.b960}px) {
    display: flex;
    align-items: center;
    flex-flow: row;
  }
  @media (min-width: ${Breakpoints.b960}px) {
    display: flex;
    align-items: center;
    flex-flow: column;
  }
`;

export const StyledInterstitialContent = styled.div`
  grid-area: content;
`;
