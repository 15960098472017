import React, { useMemo, useState } from 'react';

import {
  Breakpoints,
  BreakpointWrapper,
  DisableFocusWrapper,
  Filter,
  LinkButton,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';

import InputZip from '../../../components/input-zip';
import { LoaderSpinnerModal } from '../../../components/loader-spinner';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../hooks-store/store';
import { useHistory } from '../../../services/use-history';
import { useStickyHeader } from '../../../services/use-sticky-header';
import { useTranslations } from '../../../services/use-translations';
import { disabledScroll, scrollToTop } from '../../../utils/general';
import FilterButton from '../filter/filter-button';
import { FilterTabs } from '../filter/filter-tabs';
import { MatchingOffersContainer } from '../filter/matching-offers-container';
import { NewFilterArea } from '../filter/new-filter-area';
import {
  StyledChipsContainer,
  StyledFilterContainer,
  StyledFilterIconSection,
  StyledFiltersContainer,
  StyledIconWrapper,
  StyledInputZipWrapper,
  StyledOffersTabContainer,
  StyledSideContainer,
} from './styled';

export function FilterContainerSection(): JSX.Element | null {
  const [
    store,
    {
      [IncentiveConstants.ClearFilters]: clearFilters,
      [IncentiveConstants.ActivateMobileFilterModal]: activateMobileFilterModal,
      [IncentiveConstants.SetPendingProcessState]: setPendingProcessState,
    },
  ] = useStore();
  const history = useHistory();
  const translations = useTranslations().filtersSection;
  const [modalActive, setModalActive] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const isTablet = useIsMobile(Breakpoints.b960);
  const { isSticky } = useStickyHeader();

  const clearFiltersSelected = () => {
    clearFilters(history, true);
    setTimeout(() => {
      setPendingProcessState(false);
      scrollToTop(500);
    }, 1000);
  };

  const showFilteredBy = (): boolean => {
    const modelsAndYearFilters = store.appliedFilters?.filter(
      filter =>
        filter.filterKey !== 'offers' && filter.filterKey !== 'special-event',
    );
    return !!modelsAndYearFilters?.length;
  };

  const getAppliedFilterAmount = (): string => {
    const modelsAndYearFilters = store.appliedFilters?.filter(
      filter =>
        filter.filterKey !== 'offers' && filter.filterKey !== 'special-event',
    );
    return modelsAndYearFilters?.length
      ? `${modelsAndYearFilters.length}`
      : '0';
  };

  const onShowOffersResult = () => {
    setShowLoading(true);

    setTimeout(() => {
      setShowLoading(false);
    }, 1000);
  };

  const offersLabel = useMemo(() => {
    const amount = getAppliedFilterAmount();
    if (amount !== '0') return `Filter (${amount})`;
    return 'Filter';
  }, [getAppliedFilterAmount]);

  return (
    <StyledSideContainer>
      {showLoading ? (
        <LoaderSpinnerModal />
      ) : (
        <>
          <StyledFilterContainer>
            <FilterButton
              activateMobileFilterModal={activateMobileFilterModal}
              appliedFilterAmount={getAppliedFilterAmount()}
              appliedFilters={store.appliedFilters}
              disabledScroll={disabledScroll}
              filters={store.filterAttributes}
              matchingOffers={store.zipNoDealers ? 0 : store.offersAmount}
              mobileModalFilterActivated={store.mobileModalFilterActivated}
              modalActive={modalActive}
              onClearAllFilters={clearFiltersSelected}
              setModalState={setModalActive}
              showOffers={onShowOffersResult}
            />

            {isSticky && isTablet ? (
              <></>
            ) : (
              <StyledInputZipWrapper isTablet={isTablet}>
                <DisableFocusWrapper>
                  <InputZip
                    errorText={translations.inputZipError}
                    altErrortext={translations.noDealersError}
                    maxLength={5}
                    placeholder={translations.zipcodePlaceholder}
                  />
                </DisableFocusWrapper>
              </StyledInputZipWrapper>
            )}
          </StyledFilterContainer>
          {showFilteredBy() ? (
            <BreakpointWrapper min={Breakpoints.b960}>
              <StyledChipsContainer>
                <TextCustom
                  appearance={TextAppearance.label100}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight16,
                      color: TextColor.primary,
                      fontWeight: TextWeight.bold,
                    },
                  }}
                >
                  {translations.filteredByLabel}
                </TextCustom>
                <NewFilterArea />
              </StyledChipsContainer>
            </BreakpointWrapper>
          ) : (
            <></>
          )}
          <StyledOffersTabContainer>
            <BreakpointWrapper max={Breakpoints.b960}>
              <StyledFiltersContainer>
                <MatchingOffersContainer
                  matchingOffers={store.offersAmount}
                  isVisible={store.offersAmount > 0}
                />

                <StyledFilterIconSection>
                  <Text
                    appearance={TextAppearance.copy100}
                    color={TextColor.primary}
                  >
                    <DisableFocusWrapper>
                      <LinkButton
                        onClick={() => {
                          disabledScroll(true);
                          activateMobileFilterModal(true);
                        }}
                      >
                        <StyledIconWrapper>
                          <Filter variant="small" />
                          {offersLabel}
                        </StyledIconWrapper>
                      </LinkButton>
                    </DisableFocusWrapper>
                  </Text>
                </StyledFilterIconSection>
              </StyledFiltersContainer>
            </BreakpointWrapper>
            <FilterTabs />
          </StyledOffersTabContainer>
        </>
      )}
    </StyledSideContainer>
  );
}
