import { styled, Breakpoints } from '@vw-marketing/us-components';

export const StyledFormContainer: any = styled.div`
  width: 100%;
`;

export const StyledOfferInfoContainer: any = styled.div`
  width: 100%;
`;

export const StyledTestCheckWrapper: any = styled.div`
  grid-area: testcheck;
  margin-bottom: 40px;
  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 40px;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 40px;
  }
  label {
    font-family: vw-text, Helvetica, Arial, sans-serif;
    color: #001e50;
  }
  label input {
    height: 22px;
    width: 22px;
    accent-color: #0040c5;
    margin-right: 12px;
  }
`;

export const StyledTestScheduleMainSection: any = styled.div`

  &.notdisplay {
    display: none;
  }

`;

export const StyledTestScheduleWrapper: any = styled.div`

&.contact-dealer-test-picker.notdisplay {
  display: none;
}

  grid-area: testschedule;
  margin-bottom: 40px;
  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 20px;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 40px;
  }

  button[label='pick time'].MuiButtonBase-root.MuiTab-root {
    display: none;
  }

  .MuiSelect-select {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 16px;
    padding-top: 16px;
  }

  &.contact-dealer-test-picker
    div.MuiFormControl-root{
    margin: 0 20px 10px 0;
  }

  &.contact-dealer-test-picker
    div.MuiFormControl-root
    + div.MuiFormControl-root {
    margin: 0px;
  }

`;

