import {History} from 'history';
import isBrowser from 'is-browser';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {
  fetchInitialDataCSR,
  fetchInitialDataSSR
} from '../../../fetch-data/tier-2';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import {useStore} from '../../../hooks-store/store';
import {getStringifiedServiceConfigsServiceConfig} from '../../../services/get-stringified-service-configs-service-config';
import {useFeatureAppConfig} from '../../../services/use-feature-app-config';
import {useFeatureServices} from '../../../services/use-feature-services';
import {AppConstants} from '../../../utils/app-constants';
import {manageClassById} from '../../../utils/general';
import {getLmaVanityUrl, getUrlParam} from '../../../utils/navigation-url';
import {ErrorComponent} from '../../error-component';
import {PageLoad} from './page-load';
import { IpToZip } from '@volkswagen-onehub/zip-manager';

interface HandleDataLoadProps {
  readonly children: React.ReactNode;
  readonly baseUrl?: string;
  readonly page: string;
}

// This variable helps to prevent unnecessary calls to the endpoints
let fetchDataInProcess = false;

const HandleDataLoad: React.FC<HandleDataLoadProps> = (
  props
): JSX.Element | null => {
  // Component props
  const {children, page} = props;

  // Call different services used to apply fetches and update the store data
  const {
    faServicesUrl,
    mockIds,
    mocksBaseUrl,
    mappedTrimName,
    modelOrder
  } = useFeatureAppConfig();

  const history = useHistory();

  const {
    'locale-service': localeService,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager
  } = useFeatureServices();

  const {
    configs: { iptozip: ipToZip, "graphql-server": graphQLServer  },
  } = serviceConfigProvider;

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest
  );

  const lmaVanityUrl = getLmaVanityUrl(history);
  const [urlOffer] = useState<any>(getUrlParam(history, "offerId"));
  // App store
  const [
    store,
    {[IncentiveConstants.SetInitialDataTier2]: setInitialData}
  ] = useStore();

  // Result of fetch data in the SSR
  let initialData: any = null;

  try {
    // Get data using SSR or CSR according to the app load
    if (!store.status && !isBrowser && !fetchDataInProcess) {
      fetchDataInProcess = true;
      initialData = fetchInitialDataSSR(
        lmaVanityUrl,
        faServicesUrl,
        mockIds,
        mocksBaseUrl,
        mappedTrimName,
        modelOrder      );
    } else if (!store.status && isBrowser && !fetchDataInProcess) {
      fetchDataInProcess = true;
      fetchInitialDataCSR({
        lmaVanityUrl,
        zip: zipManager.getZipCookie() || '',
        localeService,
        serviceConfigsServiceConfig: serviceConfigsServiceConfig as string,
        zipManager,
        setInitialData,
        history: history as History,
        faServicesUrl,
        mockIds,
        mocksBaseUrl,
        mappedTrimName,
        generalmodelOrder: modelOrder,
        modelsConfig: store.modelsConfig,
        ipToZip: ipToZip as IpToZip,
        graphQLServer
      })

    }
  } catch (error) {
    return <ErrorComponent />;
  }

  useEffect(() => {
    manageClassById(
      true,
      AppConstants.FeatureAppSectionId,
      AppConstants.OffersFAClass
    );
  }, []);


  // Validate the application data before rendering the components
  const applicationData = initialData ? initialData : store;

  return (
    <>
      {applicationData &&
        applicationData.status === AppConstants.StoreStatusLoaded &&
        faServicesUrl !== '' && (
          <PageLoad page={page} applicationData={applicationData} offerId={urlOffer}>
            {(fetchDataInProcess = false)}
            {children}
          </PageLoad>
        )}
      {faServicesUrl === '' && 'Error - no faservice'}
    </>
  );
};

export default HandleDataLoad;
