import React from 'react';

interface StickyHeaderState {
  isSticky: boolean;
  isScrollDirectionUp: boolean;
}

export const StickyHeaderContext = React.createContext<
  StickyHeaderState | undefined
>(undefined);

export function useStickyHeader(): StickyHeaderState {
  const stickyHeader = React.useContext(StickyHeaderContext);

  if (!stickyHeader) {
    throw new Error('No stickyHeaderState context value was provided.');
  }

  return stickyHeader;
}
