import {AppConstants} from '../utils/app-constants';

export interface OfferImagesMapModel {
  [key: string]: ImageCategoryMapModel;
}

export interface ImageCategoryMapModel {
  [key: string]: ImageMapModel;
}

export interface ImageMapModel {
  readonly modelYear: string;
  readonly background: string;
  readonly modelImage: string;
  readonly modelKey: string;
}

export const defaultImage: ImageCategoryMapModel = {
  default: {
    modelKey: 'default',
    modelYear: '',
    background: '#dfe4e8',
    modelImage: AppConstants.Ngw6Logo480x216
  }
};

export const allImage: ImageCategoryMapModel = {
  default: {
    modelKey: 'all',
    modelYear: '',
    background: '#dfe4e8',
    modelImage: AppConstants.Ngw6Logo480x216
  }
};

export const localOfferImagesMap: OfferImagesMapModel = {
  acs: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=BC&paint=0Q0Q&pov=E13,CGN&vehicle=2020_CMC2NZ_2ER_5K7_2020_02_23&resp=png',
      modelYear: '2020',
      background: '#555659',
      modelKey: 'acs'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=BC&paint=0Q0Q&pov=E13,CGN&vehicle=2020_CMC2NZ_2ER_5K7_2020_02_23&resp=png',
      modelYear: '2020',
      background: '#555659',
      modelKey: 'acs'
    }
  },
  // tiguan: {
  //   '2020': {
  //     modelImage:
  //       'https://www.vwimg.com/iris/iris?vehicle=2020_BW22VS_2020_01_05&paint=2B2B&fabric=FY&pov=E13,CGD&bkgnd=transparent&resp=png',
  //     modelYear: '2020',
  //     background: '#21405c',
  //     modelKey: 'tiguan'
  //   },
  //   'default': {
  //     modelImage:
  //       'https://www.vwimg.com/iris/iris?vehicle=2020_BW22VS_2020_01_05&paint=2B2B&fabric=FY&pov=E13,CGD&bkgnd=transparent&resp=png',
  //     modelYear: '2020',
  //     background: '#21405c',
  //     modelKey: 'tiguan'
  //   }
  // },
  atlas: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2021_CA25UR_2020_02_23&paint=P2P2&fabric=PG&pov=E13,CGD&bkgnd=transparent&resp=png',
      modelYear: '2021',
      background: '#2a3f56',
      modelKey: 'atlas'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2021_CA25UR_2020_02_23&paint=P2P2&fabric=PG&pov=E13,CGD&bkgnd=transparent&resp=png',
      modelYear: '2021',
      background: '#2a3f56',
      modelKey: 'atlas'
    }
  },
  jetta: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=BC&paint=0Q0Q&pov=E13,CGN&vehicle=2020_BU32M2_2019_10_27&resp=png',
      modelYear: '2020',
      background: '#f0efea',
      modelKey: 'jetta'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=BC&paint=0Q0Q&pov=E13,CGN&vehicle=2020_BU32M2_2019_10_27&resp=png',
      modelYear: '2020',
      background: '#f0efea',
      modelKey: 'jetta'
    }
  },
  passat: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2020_A343P6_2019_01_13&paint=8E8E&fabric=HL&pov=E13,CGD&bkgnd=transparent&resp=png',
      modelYear: '2020',
      background: '#c9cdce',
      modelKey: 'passat'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2020_A343P6_2019_01_13&paint=8E8E&fabric=HL&pov=E13,CGD&bkgnd=transparent&resp=png',
      modelYear: '2020',
      background: '#c9cdce',
      modelKey: 'passat'
    }
  },
  golfgti: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=TL&paint=0Q0Q&pov=E13,CGN&vehicle=2020_AU29V2_W30_2020_01_05&resp=png',
      modelYear: '2020',
      background: '#c6cacf',
      modelKey: 'golfgti'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=TL&paint=0Q0Q&pov=E13,CGN&vehicle=2020_AU29V2_W30_2020_01_05&resp=png',
      modelYear: '2020',
      background: '#c6cacf',
      modelKey: 'golfgti'
    }
  },
  gsw: {
    '2019': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BX63MS_P15_W04_2018_09_16&bkgnd=transparent&fabric=TW&paint=G2G2&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#992b35',
      modelKey: 'gsw'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BX63MS_P15_W04_2018_09_16&bkgnd=transparent&fabric=TW&paint=G2G2&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#992b35',
      modelKey: 'gsw'
    }
  },
  alltrack: {
    '2019': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BX6CN6_W06_2018_09_16&bkgnd=transparent&fabric=TW&paint=0Q0Q&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#f0efea',
      modelKey: 'alltrack'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BX6CN6_W06_2018_09_16&bkgnd=transparent&fabric=TW&paint=0Q0Q&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#f0efea',
      modelKey: 'alltrack'
    }
  },
  golf: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=XW&paint=2B2B&pov=E13,CGN&vehicle=2020_AU23M2_2019_09_29&resp=png',
      modelYear: '2020',
      background: '#21405c',
      modelKey: 'golf'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=XW&paint=2B2B&pov=E13,CGN&vehicle=2020_AU23M2_2019_09_29&resp=png',
      modelYear: '2020',
      background: '#21405c',
      modelKey: 'golf'
    }
  },
  beetle: {
    '2019': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_5C23P6_2018_09_02&bkgnd=transparent&fabric=JU&paint=V9V9&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#904131',
      modelKey: 'beetle'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_5C23P6_2018_09_02&bkgnd=transparent&fabric=JU&paint=V9V9&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#904131',
      modelKey: 'beetle'
    }
  },
  beetleconvertible: {
    '2019': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_5C83P6_W2L_2018_09_02&bkgnd=transparent&fabric=NJ&paint=2BSM&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#21405c',
      modelKey: 'beetleconvertible'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_5C83P6_W2L_2018_09_02&bkgnd=transparent&fabric=NJ&paint=2BSM&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#21405c',
      modelKey: 'beetleconvertible'
    }
  },
  golfr: {
    '2019': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BQ1RST_WOC_2018_07_01&bkgnd=transparent&fabric=TL&paint=L9L9&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#172340',
      modelKey: 'golfr'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BQ1RST_WOC_2018_07_01&bkgnd=transparent&fabric=TL&paint=L9L9&pov=E13%2CCGD&resp=png',
      modelYear: '2019',
      background: '#172340',
      modelKey: 'golfr'
    }
  },
  egolf: {
    '2019': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BE23D1_2018_11_25&paint=0Q0Q&fabric=EB&pov=E13%2CCGD&bkgnd=transparent&resp=png',
      modelYear: '2019',
      background: '#c6cacf',
      modelKey: 'egolf'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2019_BE23D1_2018_11_25&paint=0Q0Q&fabric=EB&pov=E13%2CCGD&bkgnd=transparent&resp=png',
      modelYear: '2019',
      background: '#c6cacf',
      modelKey: 'egolf'
    }
  },
  arteon: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2020_3H72QA_2019_07_07&paint=0Q0Q&fabric=TI&pov=E13,CGD&bkgnd=transparent&resp=png',
      modelYear: '2020',
      background: '#f0efea',
      modelKey: 'arteon'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?vehicle=2020_3H72QA_2019_07_07&paint=0Q0Q&fabric=TI&pov=E13,CGD&bkgnd=transparent&resp=png',
      modelYear: '2020',
      background: '#f0efea',
      modelKey: 'arteon'
    }
  },
  jettagli: {
    '2020': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=TL&paint=J2J2&pov=E13,CGN&vehicle=2020_BU39VZ_WYL_2019_10_27&resp=png',
      modelYear: '2020',
      background: '#555659',
      modelKey: 'jettagli'
    },
    'default': {
      modelImage:
        'https://www.vwimg.com/iris/iris?bkgnd=transparent&fabric=TL&paint=J2J2&pov=E13,CGN&vehicle=2020_BU39VZ_WYL_2019_10_27&resp=png',
      modelYear: '2020',
      background: '#555659',
      modelKey: 'jettagli'
    }
  },
  taos: {
    '2021': {
      modelImage:
        'https://r-media.volkswagen.com/v1/VW/2GM/2021/MP2/20210607/en-US-us/0Q0Q/DB/0A2,0AF,0B3,0BA,0FL,0IH,0K0,0N1,0NB,0P0,0RG,0SC,0TA,0VM,0Y4,0YG,1A7,1AS,1D0,1E7,1EB,1G9,1J0,1N3,1NL,1PA,1S4,1SA,1T0,1U8,1W0,1X0,1Z2,1ZL,2DN,2EJ,2FB,2G7,2H0,2JB,2W3,2WA,31N,3B7,3C8,3CA,3FA,3H0,3L1,3NU,3Q9,3S2,3U0,3ZD,41S,4A0,4AF,4D0,4E0,4GF,4I8,4KC,4L2,4P6,4QT,4R4,4UM,4X3,4Z0,4ZE,51A,5C1,5D2,5K7,5MA,5RR,5SH,5XX,5ZF,6A0,6E1,6FJ,6I0,6K0,6KL,6LZ,6NA,6PB,6Q1,6XC,7B0,7CB,7E6,7J1,7K6,7L6,7M5,7N0,7P0,7Q0,7U2,7W0,7X0,7Y0,8G0,8GU,8IT,8L6,8M1,8N8,8Q0,8RJ,8S4,8SL,8T2,8TA,8W1,8WA,8X0,8Y0,8Z6,9C9,9E1,9JA,9M0,9P7,9T0,9TC,9U0,9V0,9WT,9ZX,A8B,AV2,B0D,BU2,C00,E0A,EA0,EF0,EL2,EM0,ER3,F0A,FB0,FM9,G05,G1F,GP0,H6C,I8I,IG0,J0S,K8G,KA1,KH6,KK3,KL1,L0L,L15,M5D,N0B,NY2,NZ4,Q1A,QD0,QE0,QG1,QH0,QI7,QJ0,QK0,QQ0,QR8,QV0,S0B,T3G,U5C,U9B,UC7,UD0,UG1,UH1,UK1,V0A,VF0,VL0/D6MainStageExterior/b19d7b4a-df80-487c-908a-47785dbb35fc/79aee83e7408a845a8b9170064b98a6644701721cba687444ce2f264ce213aca.png?quality=90',
      modelYear: '2021',
      background: '#dedede',
      modelKey: 'taos'
    },
    'default': {
      modelImage:
        'https://r-media.volkswagen.com/v1/VW/2GM/2021/MP2/20210607/en-US-us/0Q0Q/DB/0A2,0AF,0B3,0BA,0FL,0IH,0K0,0N1,0NB,0P0,0RG,0SC,0TA,0VM,0Y4,0YG,1A7,1AS,1D0,1E7,1EB,1G9,1J0,1N3,1NL,1PA,1S4,1SA,1T0,1U8,1W0,1X0,1Z2,1ZL,2DN,2EJ,2FB,2G7,2H0,2JB,2W3,2WA,31N,3B7,3C8,3CA,3FA,3H0,3L1,3NU,3Q9,3S2,3U0,3ZD,41S,4A0,4AF,4D0,4E0,4GF,4I8,4KC,4L2,4P6,4QT,4R4,4UM,4X3,4Z0,4ZE,51A,5C1,5D2,5K7,5MA,5RR,5SH,5XX,5ZF,6A0,6E1,6FJ,6I0,6K0,6KL,6LZ,6NA,6PB,6Q1,6XC,7B0,7CB,7E6,7J1,7K6,7L6,7M5,7N0,7P0,7Q0,7U2,7W0,7X0,7Y0,8G0,8GU,8IT,8L6,8M1,8N8,8Q0,8RJ,8S4,8SL,8T2,8TA,8W1,8WA,8X0,8Y0,8Z6,9C9,9E1,9JA,9M0,9P7,9T0,9TC,9U0,9V0,9WT,9ZX,A8B,AV2,B0D,BU2,C00,E0A,EA0,EF0,EL2,EM0,ER3,F0A,FB0,FM9,G05,G1F,GP0,H6C,I8I,IG0,J0S,K8G,KA1,KH6,KK3,KL1,L0L,L15,M5D,N0B,NY2,NZ4,Q1A,QD0,QE0,QG1,QH0,QI7,QJ0,QK0,QQ0,QR8,QV0,S0B,T3G,U5C,U9B,UC7,UD0,UG1,UH1,UK1,V0A,VF0,VL0/D6MainStageExterior/b19d7b4a-df80-487c-908a-47785dbb35fc/79aee83e7408a845a8b9170064b98a6644701721cba687444ce2f264ce213aca.png?quality=90',
      modelYear: '2021',
      background: '#dedede',
      modelKey: 'taos'
    }
  },
  all: allImage,
  default: defaultImage
};
