import { makeStyles } from '@mui/styles';
import { Breakpoints, styled } from '@vw-marketing/us-components';

export const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #dfe4e8',
  },
  tabIndicator: {
    height: '2px',
    backgroundColor: '#0040C5 !important',
  },
}));

export const StyledDetailsTabContainer = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: ${Breakpoints.b960}px) {
    width: 100%;
  }
`;

export const StyledFadedBox = styled.div`
  top: 0;
  right: -2px;
  width: 32px;
  height: 56px;
  position: absolute;
  pointer-events: none;
  background: rgba(255, 255, 255, 0.4);
  background: -moz-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );

  @media (min-width: ${Breakpoints.b960}px) {
    width: 50px;
  }

  @media only screen and (min-width: 768px) {
    display: none;
  }
`;

export const StyledTabsWrapper = styled.div`
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  .MuiTabScrollButton-root {
    visibility: hidden;
  }

  .MuiTabs-flexContainer {
    display: flex;
    min-width: 100%;

    @media only screen and (min-width: 600px) {
      gap: 32px;
      overflow-x: hidden;
    }
  }

  .tab {
    width: auto;
    padding: 0;
    opacity: 1;
    margin-right: 32px;

    @media only screen and (min-width: 600px) {
      min-width: max-content;
      margin-right: 0;
    }

    &--mr-0 {
      margin-right: 0;
    }

    &.Mui-selected .offer-text {
      font-weight: 700;
      color: #0040c5;
    }

    .offer-text {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      color: #001e50;
      text-transform: capitalize;
    }
  }

  .spacing-tab {
    height: 0px;
    min-width: 25px;
    padding: 0px 5px;
  }
`;
