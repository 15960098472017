import {
  Breakpoints,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight,
  styled
} from '@vw-marketing/us-components';
import React from 'react';

const StyledTitleWrapper: any = styled.div`
  max-width: 560px;

  @media (min-width: ${Breakpoints.b1280}px) {
    max-width: 730px;
  }

  @media (min-width: ${Breakpoints.b1920}px) {
    max-width: 1100px;
  }
`;

export interface MastheadInformationProps {
  readonly text?: string;
}

export const MastheadInformation: React.FC<MastheadInformationProps> = props => {
  const {text} = props;

  /**
   * Convert the text in an object to show it in the masthead
   * @param label string text
   */
  const getTextObj = (label: string) => {
    const textHelper = 'Volkswagen Dealers of';
    const result = {label1: '', label2: label};
    if (label.toLowerCase().includes(textHelper.toLowerCase())) {
      const textArray = label.split(/Volkswagen Dealers of/gi);
      result.label1 = textHelper;
      result.label2 = textArray[1].trim();
    }

    return result;
  };

  const textObj = getTextObj(text || '');

  return (
    <StyledTitleWrapper>
      <TextCustom
        appearance={TextAppearance.headline200}
        style={{
          [Breakpoints.default]: {
            color: TextColor.inherit,
            fontSize: TextSize.TextSize36,
            lineHeight: TextLineHeight.TextLineHeight42,
            fontWeight: TextWeight.light
          },
          [Breakpoints.b1280]: {
            fontSize: TextSize.TextSize48,
            lineHeight: TextLineHeight.TextLineHeight56
          },
          [Breakpoints.b1920]: {
            fontSize: TextSize.TextSize72,
            lineHeight: TextLineHeight.TextLineHeight80
          }
        }}
      >
        {textObj.label1 ? `Welcome to ${textObj.label1}` : ""}
        {textObj.label2 ? <b>{` ${textObj.label2}`}</b> : ''}
      </TextCustom>
    </StyledTitleWrapper>
  );
};
