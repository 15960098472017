import { styled } from '@vw-marketing/us-components';

export const DisclaimerModalWrapper = styled.div`
  .disclaimer-modal-container {
    z-index: 9999;
  }

  .disclaimer-text-container {
    width: 100%;
  }
`;
