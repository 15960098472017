import {
  Breakpoints,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight
} from '@vw-marketing/us-components';
import React from 'react';
import {OffersModel} from '../../../../../../hooks-store/typings/incentive-store';
import {getOfferTypeLabelInfo} from '../../../../../../utils/general';
import {
  StyledBlackContainer,
  StyledInformationOfferContainer,
  StyledOfferCardWrapper,
  StyledOfferMicroText,
  StyledOfferTypeLabel,
  StyledOfferTypeLabelWrapper
} from './styled';

interface OfferDetailsCardProps {
  readonly offer: OffersModel;
}

export const OfferDetailsCard: React.FC<OfferDetailsCardProps> = (
  props
): JSX.Element | null => {
  const {offer} = props;

  const offerTypeLabelInfo = getOfferTypeLabelInfo(offer.dealOfferTypes && offer.dealOfferTypes[0] ? offer.dealOfferTypes[0] : "");

  return (
    <StyledOfferCardWrapper>
      <StyledOfferTypeLabelWrapper>
        <StyledBlackContainer>
          <StyledOfferTypeLabel
            backgroundColor={offerTypeLabelInfo.backgroundColor}
            textColor={offerTypeLabelInfo.textColor}
          >
            <TextCustom
              appearance={TextAppearance.label100}
              tag={TextTag.span}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize14,
                  lineHeight: TextLineHeight.TextLineHeight20,
                  color: TextColor.inherit,
                  fontWeight: TextWeight.bold
                }
              }}
            >
              {offerTypeLabelInfo.label}
            </TextCustom>
          </StyledOfferTypeLabel>
        </StyledBlackContainer>
      </StyledOfferTypeLabelWrapper>
      <StyledInformationOfferContainer>
        <StyledOfferMicroText>
          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.h2}
            style={{
              [Breakpoints.default]: {
                fontWeight: TextWeight.bold,
                color: TextColor.primary,
                fontSize: TextSize.TextSize24,
                lineHeight: TextLineHeight.TextLineHeight28
              }
            }}
          >
            {offer.dealTitle}
          </TextCustom>

          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.p}
            style={{
              [Breakpoints.default]: {
                color: TextColor.primary,
                fontSize: TextSize.TextSize14,
                lineHeight: TextLineHeight.TextLineHeight20,
                fontWeight: TextWeight.regular
              }
            }}
          >
            {offer.dealSubtitle}
          </TextCustom>

          {offer.dealCopy && (
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.p}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize14,
                  lineHeight: TextLineHeight.TextLineHeight20,
                  fontWeight: TextWeight.regular,
                  color: TextColor.inherit
                }
              }}
            >
              {offer.dealCopy}
            </TextCustom>
          )}
          {offer.dealLegal && (
            <TextCustom
              appearance={TextAppearance.headline200}
              tag={TextTag.p}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize14,
                  lineHeight: TextLineHeight.TextLineHeight20,
                  fontWeight: TextWeight.regular,
                  color: TextColor.inherit
                }
              }}
            >
              {offer.dealLegal}
            </TextCustom>
          )}
        </StyledOfferMicroText>
      </StyledInformationOfferContainer>
    </StyledOfferCardWrapper>
  );
};
