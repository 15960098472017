export const handleServerResponse = (obj: any, status: boolean) => {
    if (status) {
      return {
        errorStatus: true,
        data: obj,
      };
    }
  
    return {
      errorStatus: false,
      data: obj,
    };
  };