import { TextLineHeight, TextSize, TextWeight } from '@vw-marketing/us-components';
import {
  Breakpoints,
  TextAppearance,
  TextColor,
  TextTag,
} from '@vw-marketing/us-components';

export const carTilePriceTextStyle = {
  tag: TextTag.span,
  appearance: TextAppearance.headline200,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.bold,
      color: TextColor.inherit,
      fontSize: TextSize.TextSize20,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
    [Breakpoints.b960]: {
      fontWeight: TextWeight.bold,
      color: TextColor.inherit,
      fontSize: TextSize.TextSize16,
      lineHeight: TextLineHeight.TextLineHeight20,
    },
    [Breakpoints.b1600]: {
      fontWeight: TextWeight.bold,
      color: TextColor.inherit,
      fontSize: TextSize.TextSize24,
      lineHeight: TextLineHeight.TextLineHeight28,
    },
  },
};
