import { Tab, Tabs } from '@mui/material';
import {
  Breakpoints,
  BreakpointWrapper,
  Text,
  useIsMobile,
} from '@vw-marketing/us-components';
import React, { useEffect, useState } from 'react';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../../hooks-store/store';
import { IncentiveStoreModel } from '../../../../hooks-store/typings/incentive-store';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { useHistory } from '../../../../services/use-history';
import { scrollToTop } from '../../../../utils/general';
import {
  onHandleFilterClickAction,
  onHandlerFilterSeachLoad,
} from '../../../../utils/tagging-helpers';
import { MatchingOffersContainer } from '../matching-offers-container';
import {
  StyledDetailsTabContainer,
  StyledFadedBox,
  StyledTabsWrapper,
  useStyles,
} from './styled';

export const FilterTabs: React.FC = () => {
  const [store] = useStore();
  const { mode } = useFeatureAppConfig();
  const isModal = mode === 'modal';

  const offersAttributes = store.filterAttributes?.find(
    filter => filter.name === 'Offers',
  );

  const offers = offersAttributes?.attributes.filter(filter =>
    ['offers', 'special-event'].includes(filter.filterKey),
  );

  const {
    [IncentiveConstants.UpdateAppliedFilter]: updateAppliedFilter,
    [IncentiveConstants.SetPendingProcessState]: setPendingProcessState,
  } = useStore(false)[1];
  const classes = useStyles();

  const tabSelected = () => {
    const offersFilters = store.appliedFilters.filter(filter =>
      ['offers', 'special-event'].includes(filter.filterKey),
    );

    const selected =
      offersFilters.length &&
      offers &&
      offers.findIndex(
        offer =>
          offer.text === offersFilters[0].text ||
          offer.value === offersFilters[0].value,
      );

    return selected && selected >= 0 && store.appliedFilters.length > 0
      ? selected
      : 0;
  };

  const [value, setValue] = useState(tabSelected);

  const isMobile = useIsMobile(Breakpoints.b560);
  const isTablet = useIsMobile(600);
  const trackingManager = useTrackingManager();
  const history = useHistory();

  //@ts-ignore
  const handleChange = (event: any, newValue: number) => {
    const selectedTabLabel = event.target?.textContent;
    const updatedOffer = offers?.filter(
      offer => offer.text === selectedTabLabel,
    );

    setValue(newValue);
    const updatedStore: IncentiveStoreModel = updateAppliedFilter(
      updatedOffer ? updatedOffer[0] : [],
      history,
      true,
      true,
    );
    setTimeout(() => {
      setPendingProcessState(false);
      scrollToTop(500);
    }, 1000);
    //tracking section
    onHandleFilterClickAction(
      'offers',
      selectedTabLabel,
      trackingManager,
      updatedStore,
    );
    onHandlerFilterSeachLoad(trackingManager, updatedStore);
  };

  useEffect(() => {
    if (store.appliedFilters.length <= 0) setValue(0);
  }, [store.appliedFilters]);

  const tabItemAttributes = (index: string) => {
    return {
      'id': `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  return (
    <StyledDetailsTabContainer className="details-tab-container">
      <StyledTabsWrapper>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          aria-label="scrollable auto tabs"
          className="tabs"
          classes={{
            root: classes.root,
            indicator: classes.tabIndicator,
          }}
        >
          {offers &&
            offers.map((offer, index) => (
              <Tab
                label={<Text className="offer-text">{offer.text}</Text>}
                disableRipple
                key={offer.value}
                {...tabItemAttributes(offer.value)}
                className={
                  index === offers.length - 1 ? 'tab tab--mr-0' : 'tab'
                }
              />
            ))}
          {isMobile && <Tab disabled className="spacing-tab"></Tab>}
          {store.offersAmount > 0 && !store.zipNoDealers && !isModal && (
            <BreakpointWrapper min={Breakpoints.b960}>
              <MatchingOffersContainer
                matchingOffers={store.offersAmount}
                isVisible
              />
            </BreakpointWrapper>
          )}
        </Tabs>
      </StyledTabsWrapper>
      {(isMobile || isTablet) && <StyledFadedBox />}
    </StyledDetailsTabContainer>
  );
};
