import {
  DisableFocusWrapper,
  LinkButton,
  Menu,
  Text,
  TextAppearance,
  TextColor
} from '@vw-marketing/us-components';
import React from 'react';
import {StyledFilterIconSection, StyledIconWrapper} from './styled';

interface BtnLinkButtonProps {
  readonly btnLabel?: string;
  readonly iconLabel?: JSX.Element;
  readonly btnAction?: () => void;
}

export const BtnLinkButton: React.FC<BtnLinkButtonProps> = props => {
  const {btnLabel, btnAction, iconLabel} = props;

  return (
    <StyledFilterIconSection>
      <DisableFocusWrapper>
        <Text appearance={TextAppearance.copy100} color={TextColor.primary}>
          <LinkButton onClick={btnAction}>
            <StyledIconWrapper>
              {iconLabel || <Menu variant="small" />} {btnLabel}
            </StyledIconWrapper>
          </LinkButton>
        </Text>
      </DisableFocusWrapper>
    </StyledFilterIconSection>
  );
};
