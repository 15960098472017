import {
  Breakpoints,
  ButtonAppearance,
  ButtonCustom,
  DisableFocusWrapper,
  Text,
  TextAlignment,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight,
  buttonPaddingGenerator
} from '@vw-marketing/us-components';
import React from 'react';
import {
  StyledButtonSection,
  StyledButtonWrapper,
  StyledHeaderGroup,
  StyledHeaderWrapper,
  StyledNoResultsWrapper,
  StyledSubHeaderWrapper
} from './styled';

interface NoResultsProps {
  readonly header: string;
  readonly subHeader: string;
  readonly firstBtnAction: () => void;
  readonly firstBtnLabel: string;
  readonly firstBtnPadding?: number[];
  readonly secondBtnAction: () => void;
  readonly secondBtnLabel: string;
  readonly secondBtnPadding?: number[];
}

export const NoResults: React.FC<NoResultsProps> = (
  props
): JSX.Element | null => {
  const {
    header,
    subHeader,
    firstBtnAction,
    firstBtnLabel,
    firstBtnPadding = [12, 20, 30, 30],
    secondBtnAction,
    secondBtnLabel,
    secondBtnPadding = [12, 20, 30, 30]
  } = props;

  return (
    <StyledNoResultsWrapper>
      <StyledHeaderGroup>
        <StyledHeaderWrapper>
          <TextCustom
            appearance={TextAppearance.headline200}
            style={{
              [Breakpoints.default]: {
                fontWeight: TextWeight.bold,
                fontSize: TextSize.TextSize32,
                lineHeight: TextLineHeight.TextLineHeight36,
                textAlign: TextAlignment.center
              }
            }}
          >
            {header}
          </TextCustom>
        </StyledHeaderWrapper>
        <StyledSubHeaderWrapper>
          <TextCustom
            appearance={TextAppearance.headline200}
            style={{
              [Breakpoints.default]: {
                fontSize: TextSize.TextSize20,
                lineHeight: TextLineHeight.TextLineHeight24,
                fontWeight: TextWeight.light,
                textAlign: TextAlignment.center
              }
            }}
          >
            {subHeader}
          </TextCustom>
        </StyledSubHeaderWrapper>
      </StyledHeaderGroup>

      <StyledButtonWrapper>
        <StyledButtonSection>
          <DisableFocusWrapper>
            <ButtonCustom
              appearance={ButtonAppearance.Secondary}
              onClick={() => firstBtnAction()}
              padding={buttonPaddingGenerator(...firstBtnPadding)}
              stretchContent
            >
              <Text
                color={TextColor.inherit}
                appearance={TextAppearance.label150}
              >
                {firstBtnLabel}
              </Text>
            </ButtonCustom>
          </DisableFocusWrapper>
        </StyledButtonSection>
        <StyledButtonSection>
          <DisableFocusWrapper>
            <ButtonCustom
              appearance={ButtonAppearance.Primary}
              onClick={() => secondBtnAction()}
              padding={buttonPaddingGenerator(...secondBtnPadding)}
              stretchContent
            >
              <Text
                color={TextColor.inherit}
                appearance={TextAppearance.label150}
              >
                {secondBtnLabel}
              </Text>
            </ButtonCustom>
          </DisableFocusWrapper>
        </StyledButtonSection>
      </StyledButtonWrapper>
    </StyledNoResultsWrapper>
  );
};
