
export const OFFERS_DEALERS_QUERY = {query: `
query GetDealersByZip($zipCode: String!) {
  dealers: getDealersByZip(zipCode: $zipCode) {
    generatedDate
    dealerid
    name
    dealername
    seolookupkey
    address1
    address2
    city
    state
    postalcode
    country
    url
    phone
    latlong
    staticMapsUrl
    distance
    inventoryCount
    aor
    isSatellite
    isAssessing
    lmaId
  }
}
`};
