import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledInterstitialContainer = styled.div`
  ${({ theme }) => `
  position: relative;
  overflow-y: auto;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  grid-template-areas:
    'header'
    'steps-line'
    'steps-cards'
    'dealer-web-site';
  padding: 45px 31px  0 31px;
  @media (min-width: ${Breakpoints.b560}px) {
    height: auto;
    padding-top: 55px;
    padding-left: ${theme.size.grid002};
    padding-right: ${theme.size.grid002};
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: calc(100vw - ${theme.size.grid004});
    padding-top: 70px;
    padding-left: ${theme.size.grid001};
    padding-right: ${theme.size.grid001};
  }
  `}
`;

export const StyledInterstitialTitle = styled.div`
  grid-area: header;
  margin-bottom: 31px;
  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 20px;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 50px;
  }
`;

export const StyledInterstitialStepLines = styled.div`
  grid-area: steps-line;
  width: 100%;
`;

export const StyledInterstitialCards = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  order: 3;
  row-gap: 20px;
  padding-bottom: 60px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: baseline;
  @media (min-width: ${Breakpoints.b960}px) {
    order: 2;
    width: 50%;
    row-gap: 0;
    padding-bottom: 0px;
  }
`;
export const StyledInterstitialStepCards = styled.div`
  grid-area: steps-cards;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  .vehicle-card-wrapper {
    width: 100%;
    order: 1;
  }
  @media (min-width: ${Breakpoints.b960}px) {
    .vehicle-card-wrapper {
      width: 50%;
      padding-right: ${props => props.theme.size.grid001};
    }
  }
`;
interface ButtonStyleProps {
  isLarge: boolean;
}
export const StyledInterstitialDealerWebSite = styled.div<ButtonStyleProps>`
  ${() => `
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  padding-top: 20px;
  margin-bottom: 50px;
  a {
    width: 195px;
  }
  button {
    width: 46px;
  }
  a svg {
    margin: -0.11em 0 0.11em 0;
    margin-left:8px;
  }
  a div {
    font-size: 14px;
  }
`}
`;

export const StyledDealerWebSiteContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  order: 2;
  @media (min-width: ${Breakpoints.b1280}px) {
    order: 3;
  }
`;

export const StyledInterstitialScroll = styled.div`
  ${({ theme }) => `
    height: calc(100vh - 80px);
    width: 100vw;
    z-index: 999;
    display: flex;
    flex-flow: column;
    position: relative;
    padding: 45px 31px  0 31px;
    @media (min-width: ${Breakpoints.b560}px) {
      height: auto;
      padding-top: 55px;
      padding-left: ${theme.size.grid002};
      padding-right: ${theme.size.grid002};
    }
    @media (min-width: ${Breakpoints.b1280}px) {
      width: calc(100vw - ${theme.size.grid004});
      padding-top: 70px;
      padding-left: ${theme.size.grid001};
      padding-right: ${theme.size.grid001};
    }
`}
`;
