import { SpinnerIcon, Text, TextAppearance } from '@vw-marketing/us-components';
import React from 'react';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import {
  StyledModalWrapper,
  StyledSpinnerContainer,
  StyledSpinnerWrapper,
} from './styled';

interface LoaderModalProps {
  readonly label?: string;
}

export const LoaderSpinnerModal: React.FC<LoaderModalProps> = (
  props,
): JSX.Element => {
  const { label } = props;
  const { specialOffersRedesignEnable } = useFeatureAppConfig();

  return (
    <StyledModalWrapper isSpecialOffersRedesign={specialOffersRedesignEnable}>
      <StyledSpinnerContainer>
        <StyledSpinnerWrapper>
          <SpinnerIcon iconSize="60" />
          <Text appearance={TextAppearance.copy200}>
            {label ? label : 'Loading...'}
          </Text>
        </StyledSpinnerWrapper>
      </StyledSpinnerContainer>
    </StyledModalWrapper>
  );
};
