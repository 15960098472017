import { debounce } from 'lodash';
import React from 'react';

export interface ElementBounding {
  readonly bottom: number;
  readonly height: number;
  readonly left: number;
  readonly right: number;
  readonly top: number;
  readonly width: number;
  readonly x: number;
  readonly y: number;
}

export function useElementBounding(): [
  ElementBounding | undefined,
  (element: HTMLElement | null) => void,
] {
  const [elementBounding, setElementBounding] = React.useState<
    ElementBounding | undefined
  >(undefined);
  const elementRef = React.useRef<HTMLElement | null>(null);

  const ref = React.useCallback((element: HTMLElement | null) => {
    elementRef.current = element;

    if (element !== null) {
      setElementBounding(element.getBoundingClientRect());
    }
  }, []);

  React.useEffect(() => {
    if (elementRef.current && elementRef.current.clientHeight > 0) {
      setElementBounding(elementRef.current.getBoundingClientRect());
    }
  }, [elementRef]);

  React.useEffect(() => {
    const handleResize = () => {
      if (elementRef.current) {
        setElementBounding(elementRef.current.getBoundingClientRect());
      }
    };
    if (elementRef.current) handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const debouncedResizeHandler = debounce(() => {
    if (elementRef.current) {
      const elementHeightAfterUpdate = elementRef.current.getBoundingClientRect();
      if (
        JSON.stringify(elementHeightAfterUpdate) !==
        JSON.stringify(elementBounding)
      ) {
        setElementBounding(elementHeightAfterUpdate);
      }
    }
  }, 150);

  React.useLayoutEffect(debouncedResizeHandler, [elementBounding]);

  const currentElementBounding = elementRef?.current?.getBoundingClientRect();
  if (
    currentElementBounding &&
    JSON.stringify(currentElementBounding) !== JSON.stringify(elementBounding)
  )
    return [currentElementBounding, ref];

  return [elementBounding, ref];
}
