
export const PRODUCT_NATIONAL_OFFER_QUERY = { query: `
  query productNationalOfferQuery($modelName: String!) {
    offers: getNationalOffersByModel(modelName: $modelName) {
      __typename
      dealAdditionalTerms
      dealCarModel
      dealCarModelName
      dealCarTrim
      dealCarYear
      dealCopy
      dealEndDate
      dealExclusions
      dealId
      dealLegal
      dealLocaleType
      dealOfferTypes
      dealPricePerMonth
      dealPriority
      dealSubtitle
      dealTitle
      dealCustomImageName
      dealDaaCode
      dealDaaName
      dealLocaleTypeOrder
      dealRate
      dealRegionCode
      dealRegionName
      dealStartDate
      dealTerm
      dealerCode
      dealerName
      stateCode
      stateName
      dealCustomImageLocation
    }
  }
`};
