import React, { useEffect } from 'react';
import { Route, Router, Switch } from 'react-router';

import isBrowser from 'is-browser';

import {
  ModalDisclaimer,
  ModalDisclaimersService,
} from '@vw-marketing/us-components';
import { ErrorComponent } from './components/error-component';
import HandleDataLoadTier1 from './components/handle-data-load/tier-1';
import HandleDataLoadTier2 from './components/handle-data-load/tier-2';
import { ModelOrder, localModelOrder } from './config/tier-1/model-order';
import { DisclaimerConfigurationContext } from './hooks/use-disclaimer-configuration';
import { OffersModal } from './pages/offers-modal';
import LandingTier1 from './pages/tier-1/landing';
import { AllOffers } from './pages/tier-2/all-offers';
import LandingTier2 from './pages/tier-2/landing';
import { Tier1SimplifiedVersion } from './pages/tier1-simplified-version';
import { useDisclaimerReference } from './services/use-disclaimer-reference';
import { useFeatureAppConfig } from './services/use-feature-app-config';
import { useFeatureAppModel } from './services/use-featureapp-model';
import { useHistory } from './services/use-history';
import { IsNationalOfferContext } from './services/use-is-national-offer';
import { ModelsConfigContext } from './services/use-models-config';
import { ShowroomModel } from './typings/general';
import { AppConstants } from './utils/app-constants';
import { getDisclaimerKeys } from './utils/disclaimers';
import {
  appendGoogleApiScript,
  appendSectionFAStyle,
  getModelNameByCarlineId,
  historyListener,
  unsetPagemainZindex,
} from './utils/general';
import { LoaderContainer, LoaderSpinner } from './components/loader-spinner';

export interface AppProps {
  readonly baseUrl?: string;
}

declare var vwa_d6_cms: any;

// tslint:disable-next-line:cyclomatic-complexity
export function App({ baseUrl }: AppProps): JSX.Element | null {
  const history = useHistory();
  const disclaimerService = useDisclaimerReference;
  const {
    mode,
    modelOrder: modelOrderConfig,
    activeNationalOffers = true,
    disclaimers,
    modelName,
    carlineId,
  } = useFeatureAppConfig();
  const isTier1 = !mode || mode === 'tier-1' || mode === 'default';
  const isTier2 = mode === 'tier-2';
  const isModule = mode === 'module';
  const isModal = mode === 'modal';

  const initialDisclaimerData: ModalDisclaimer = {
    active: false,
  };

  const disclaimersConfig = {
    disclaimers: disclaimers
      ? getDisclaimerKeys(disclaimers, disclaimerService)
      : [],
  };

  const modelOverview = useFeatureAppModel() ?? {};

  if (isTier1 || isTier2) {
    historyListener(history);
  }

  useEffect(() => {
    if (isBrowser) {
      if (!isTier2) {
        appendGoogleApiScript();
      }
      if (isTier1 || isTier2) {
        appendSectionFAStyle();
      }
      // Avoid the navigation menu overlap issue
      unsetPagemainZindex();
    }
  }, []);

  let moduleModelKey = '';
  let moduleModelName = '';
  let showroomModel: ShowroomModel | undefined = undefined;
  let modalModelKey = modelName;

  if (isModal && carlineId) {
    modalModelKey = getModelNameByCarlineId(carlineId, modelOverview);
  }

  if (isModule && isBrowser) {
    try {
      if (
        modelOverview &&
        typeof vwa_d6_cms !== undefined &&
        vwa_d6_cms &&
        vwa_d6_cms.browserRegistry &&
        vwa_d6_cms.browserRegistry.singletonInstances &&
        vwa_d6_cms.browserRegistry.singletonInstances.CarConfigurationService &&
        vwa_d6_cms.browserRegistry.singletonInstances.CarConfigurationService.instance.get()
      ) {
        showroomModel = vwa_d6_cms.browserRegistry.singletonInstances.CarConfigurationService.instance.get();
        // Just for the local version
        // showroomModel = {
        //   category: 'private',
        //   carlineId: '31691',
        //   salesGroupId: '38940',
        //   trimName: 'S',
        //   modelYear: '2020',
        //   exteriorId: 'F14 0Q0Q',
        //   interiorId: 'F56     BG',
        //   buildabilityStatus: 'buildable',
        //   modelId: 'BW22VS',
        // };
        if (!showroomModel || !showroomModel.carlineId)
          throw new Error('No showroom carlineId defined');
        const useModelOrder = modelOrderConfig
          ? modelOrderConfig
          : localModelOrder;

        const modelOrderEle: ModelOrder | undefined = useModelOrder.find(
          (model: ModelOrder) =>
            model.carlineId && model.carlineId === showroomModel?.carlineId,
        );

        moduleModelKey = modelOrderEle ? modelOrderEle.modelKey : '';

        // if (specialOffersRedesignEnable) {
        //   setTimeout(() => {
        //     setModuleModelKey(modelOrderEle ? modelOrderEle.modelKey : '');
        //   }, 2500);
        // } else {
        //   setModuleModelKey(modelOrderEle ? modelOrderEle.modelKey : '');
        // }

        moduleModelName = modelOrderEle
          ? modelOrderEle.modelName.replace(/ /g, '-')
          : '';
      }
    } catch (e) {
      console.log('Global vwa_d6_cms ', e);

      return <ErrorComponent />;
    }
  }

  return (
    <ModelsConfigContext.Provider value={modelOverview}>
      <DisclaimerConfigurationContext.Provider value={disclaimersConfig}>
        <ModalDisclaimersService disclaimerData={initialDisclaimerData}>
          <IsNationalOfferContext.Provider
            value={activeNationalOffers && isTier1}
          >
            <Router history={history}>
              {isTier1 ? (
                <Switch>
                  <Route exact path="/">
                    <HandleDataLoadTier1
                      baseUrl={baseUrl}
                      page={AppConstants.PageLandingTier1 as string}
                    >
                      <LandingTier1 />
                    </HandleDataLoadTier1>
                  </Route>
                </Switch>
              ) : isTier2 ? (
                <Switch>
                  <Route exact path="/">
                    <HandleDataLoadTier2
                      baseUrl={baseUrl}
                      page={AppConstants.PageLandingTier2 as string}
                    >
                      <LandingTier2 />
                    </HandleDataLoadTier2>
                  </Route>
                  <Route exact path="/all-offers">
                    <HandleDataLoadTier2
                      baseUrl={baseUrl}
                      page={AppConstants.PageAllOffersTier2 as string}
                    >
                      <AllOffers />
                    </HandleDataLoadTier2>
                  </Route>
                </Switch>
              ) : isModule ? (
                <Switch>
                  <Route exact path="/">
                    <HandleDataLoadTier1
                      baseUrl={baseUrl}
                      page={AppConstants.Tier1SimplifiedVersion as string}
                      modelKey={moduleModelKey}
                    >
                      <Tier1SimplifiedVersion
                        modelKey={moduleModelKey}
                        modelName={moduleModelName}
                        carlineId={showroomModel?.carlineId}
                      />
                    </HandleDataLoadTier1>
                  </Route>
                </Switch>
              ) : isModal ? (
                <HandleDataLoadTier1
                  baseUrl={baseUrl}
                  page={AppConstants.OffersModal as string}
                  modelKey={modalModelKey}
                >
                  {modalModelKey ? (
                    <OffersModal modelName={modalModelKey} />
                  ) : (
                    <LoaderContainer>
                      <LoaderSpinner />
                    </LoaderContainer>
                  )}
                </HandleDataLoadTier1>
              ) : null}
            </Router>
          </IsNationalOfferContext.Provider>
        </ModalDisclaimersService>
      </DisclaimerConfigurationContext.Provider>
    </ModelsConfigContext.Provider>
  );
}
