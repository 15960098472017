import React from 'react';
import {DealerModel, OfferInformation} from '../../../hooks-store/typings/incentive-store';
import {ModelOfferHead} from './model-offer-head';
import {OfferCardSection} from './offer-card-section';
import {StyledModelOfferSectionWrapper} from './styled';

interface ModelOfferSectionProps {
  readonly offerInformation: OfferInformation;
  readonly dealer?: DealerModel;
  readonly isTier1?: boolean;
}

export const ModelOfferSection: React.FC<ModelOfferSectionProps> = (
  props
): JSX.Element | null => {
  const {offerInformation, dealer, isTier1} = props;

  return (
    <StyledModelOfferSectionWrapper>
      <ModelOfferHead offerInformation={offerInformation} />
      {offerInformation.offerCount !== 0 && (
        <OfferCardSection offerInformation={offerInformation} dealer={dealer} isTier1={isTier1}/>
      )}
    </StyledModelOfferSectionWrapper>
  );
};
