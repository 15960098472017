import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledNoResultsWrapper: any = styled.div`
  width: 100%;
  padding-top: 0;
  margin-bottom: 0;

  @media (min-width: ${Breakpoints.b1280}px) {
    margin-bottom: 30px;
    padding-top: 35px;
  }
`;

export const StyledHeaderGroup: any = styled.div`
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledHeaderWrapper: any = styled.div`
  margin-bottom: 20px;
  max-width: 430px;
`;

export const StyledSubHeaderWrapper: any = styled.div`
  max-width: 220px;

  @media (min-width: ${Breakpoints.b560}px) {
    max-width: 380px;
  }
`;

export const StyledButtonWrapper: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  & > :first-child {
    margin-right: 16px;
  }
  & > :last-child {
    margin-left: 16px;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: auto;
`;
