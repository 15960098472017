import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledSpinnerContainer: any = styled.div`
  min-height: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;

  @media (min-width: ${Breakpoints.b560}px) {
    min-height: 500px;
    padding: 30px;
  }
`;
