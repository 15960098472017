import { Checkbox, DisableFocusWrapper } from '@vw-marketing/us-components';
import React from 'react';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../../hooks-store/store';
import { IncentiveStoreModel } from '../../../../hooks-store/typings/incentive-store';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { useHistory } from '../../../../services/use-history';
import { scrollToTop } from '../../../../utils/general';
import {
  onHandleFilterClickAction,
  onHandlerFilterSeachLoad,
} from '../../../../utils/tagging-helpers';
import { StyledFilterAtrributeWrapper } from './styled';

export const FilterAttribute: React.FC<any> = ({
  attribute,
  appliedFilters,
  category,
}: any): JSX.Element => {
  const history = useHistory();
  const {
    [IncentiveConstants.UpdateAppliedFilter]: updateAppliedFilter,
    [IncentiveConstants.SetPendingProcessState]: setPendingProcessState,
  } = useStore(false)[1];

  const trackingManager = useTrackingManager();
  const { specialOffersRedesignEnable } = useFeatureAppConfig();

  /**
   * Update the applied filters using the check event
   */
  const updateFilter = () => {
    const updatedStore: IncentiveStoreModel = updateAppliedFilter(
      attribute,
      history,
      true,
      specialOffersRedesignEnable,
    );
    setTimeout(() => {
      setPendingProcessState(false);
      !specialOffersRedesignEnable && scrollToTop(500);
    }, 1000);

    //tracking section
    onHandleFilterClickAction(
      category,
      attribute.text,
      trackingManager,
      updatedStore,
    );
    onHandlerFilterSeachLoad(trackingManager, updatedStore);
  };

  /**
   * Update the checkbox mark using the applied filters in the store
   */
  const isSelected = () => {
    const selected = appliedFilters.filter(
      (filter: any) => filter.value === attribute.value,
    );

    return selected.length > 0;
  };

  return (
    <StyledFilterAtrributeWrapper className="filter-attribute-wrapper">
      <DisableFocusWrapper>
        <Checkbox
          value={attribute.value}
          label={attribute.text}
          onClick={updateFilter}
          checked={isSelected()}
        />
      </DisableFocusWrapper>
    </StyledFilterAtrributeWrapper>
  );
};
