import 'isomorphic-fetch';
import { BffLoggerCall, FeatureAppModelConfig } from '../typings/general';
export interface FetchModelConfigOptions {
  readonly baseUrl?: string;
  readonly serviceConfigsServiceConfig?: string;
  readonly mockIds?: string;
  readonly mocksBaseUrl?: string;
  readonly faServicesUrl?: string;
}

function createBffUrl(
  params: URLSearchParams,
  faServicesUrl: string | undefined,
): string {
  const bffPath = faServicesUrl?.endsWith('/')
    ? 'modelsConfig'
    : '/modelsConfig';

  return `${faServicesUrl || ''}${bffPath}?${params.toString()}`;
}

export class ModelConfigFetchError extends Error {
  public constructor(
    message: string,
    public readonly bffLoggerCalls?: BffLoggerCall[],
  ) {
    super(message);
  }
}

export async function fetchModelConfig({
  serviceConfigsServiceConfig,
  mockIds,
  mocksBaseUrl,
  faServicesUrl,
}: FetchModelConfigOptions): Promise<FeatureAppModelConfig> {
  const params = new URLSearchParams();

  if (serviceConfigsServiceConfig) {
    params.set('serviceConfigsServiceConfig', serviceConfigsServiceConfig);
  }

  if (mockIds) {
    params.set('mockIds', mockIds);
  }

  if (mocksBaseUrl) {
    params.set('mocksBaseUrl', mocksBaseUrl);
  }

  params.set('useGlobalConfig', 'true');

  params.set('includeModelYears', 'true');
  params.set('isInventory', 'true');

  const response = await fetch(createBffUrl(params, faServicesUrl));
  const modelOverview = await response.json();

  if (response.status >= 500) {
    throw new ModelConfigFetchError(
      modelOverview.message,
      modelOverview.bffLoggerCalls,
    );
  }
  return modelOverview;
}
