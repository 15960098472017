import {
  Breakpoints,
  ButtonAppearance,
  ButtonCustom,
  DisableFocusWrapper,
  LinkButton,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  buttonPaddingGenerator,
} from '@vw-marketing/us-components';
import React from 'react';
import { ImageMapModel } from '../../../../config/offer-images-map';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../../hooks-store/store';
import { OffersModel } from '../../../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../../../utils/app-constants';
import {
  clearText,
  getDateFormat,
  getOfferTypeLabelInfo,
  transformIrisUrl,
} from '../../../../utils/general';
import {
  StyledBlackContainer,
  StyledButtonSection,
  StyledButtonWrapper,
  StyledInformationOfferContainer,
  StyledInformationWrapper,
  StyledModelNameWrapper,
  StyledOfferCardWrapper,
  StyledOfferMicroText,
  StyledOfferSubtitle,
  StyledOfferTitle,
  StyledOfferTypeLabel,
  StyledOfferTypeLabelWrapper,
  StyledOpacityWrapper,
  StyledSectionButton,
  StyledShowOfferDetails,
} from './styled';
import { useTranslations } from '../../../../services/use-translations';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import { onHandleFormButtonTracking } from '../../../../utils/tagging-helpers';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';

interface OfferCardProps {
  readonly offer: OffersModel;
  readonly imageMap?: ImageMapModel;
  readonly opacity?: boolean;
}

export const OfferCard: React.FC<OfferCardProps> = React.memo(
  (props): JSX.Element | null => {
    const { faHostServerUrl = '' } = useFeatureAppConfig();
    const { offer, imageMap, opacity } = props;
    const modelImage =
      offer?.modelKey !== 'all'
        ? imageMap?.modelImage
        : faHostServerUrl + AppConstants.Ngw6Logo480x216;
    const [
      store,
      { [IncentiveConstants.SetModalFormData]: setModalFormData },
    ] = useStore();
    const translations = useTranslations().tier1simplifiedVersion.localOffers
      .offerCard;
    const trackingManager = useTrackingManager();

    const setContactDealerFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap?.modelYear,
              imageMap?.modelKey,
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
        },
        store.dealer,
        AppConstants.ContactDealerFormType,
        AppConstants.ContactDealerFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.contactDealer,
        trackingManager,
        store,
      );
    };

    const setGetAQuoteFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap?.modelYear,
              imageMap?.modelKey,
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
        },
        store.dealer,
        AppConstants.GetAQuoteFormType,
        AppConstants.GetAQuoteFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.getAQuote,
        trackingManager,
        store,
      );
    };

    const setDetailsFormData = () => {
      setModalFormData(
        {
          ...offer,
          mediaImageUrl:
            transformIrisUrl(
              modelImage,
              AppConstants.IrisImgPositionE13,
              '960',
              '432',
              imageMap?.modelYear,
              imageMap?.modelKey,
            ) || faHostServerUrl + AppConstants.Ngw6Logo960x432,
        },
        store.dealer,
        AppConstants.OfferDetailsFormType,
        AppConstants.OfferDetailsFormName,
      );
      onHandleFormButtonTracking(
        offer,
        translations.offerDetails,
        trackingManager,
        store,
      );
    };

    const offerTypeLabelInfo = getOfferTypeLabelInfo(offer.dealOfferTypes[0]);

    return (
      <StyledOfferCardWrapper>
        <StyledInformationWrapper>
          <StyledOpacityWrapper isOpacity={opacity} />
          <StyledOfferTypeLabelWrapper>
            <StyledBlackContainer>
              <StyledOfferTypeLabel
                backgroundColor={offerTypeLabelInfo.backgroundColor}
                textColor={offerTypeLabelInfo.textColor}
              >
                <TextCustom
                  appearance={TextAppearance.label100}
                  tag={TextTag.span}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight20,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.bold,
                    },
                  }}
                >
                  {offerTypeLabelInfo.label}
                </TextCustom>
              </StyledOfferTypeLabel>
            </StyledBlackContainer>
          </StyledOfferTypeLabelWrapper>
          <StyledInformationOfferContainer>
            <StyledModelNameWrapper>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.modelKey &&
                offer.modelKey === AppConstants.AllOfferModelKey
                  ? offer.modelDisplayName
                  : `${offer.dealCarYear[0] || ''} ${offer.modelDisplayName ||
                      ''} ${offer.dealCarTrimName[0] || ''}`}
              </TextCustom>
            </StyledModelNameWrapper>
            <StyledOfferTitle>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.h2}
                style={{
                  [Breakpoints.default]: {
                    fontWeight: TextWeight.bold,
                    fontSize: TextSize.TextSize24,
                    lineHeight: TextLineHeight.TextLineHeight29,
                  },
                }}
              >
                {clearText(offer.dealTitle)}
              </TextCustom>
            </StyledOfferTitle>
            <StyledOfferSubtitle>
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.dealSubtitle}
              </TextCustom>
            </StyledOfferSubtitle>
            <StyledOfferMicroText>
              {offer.dealAdditionalTerms && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {offer.dealAdditionalTerms}
                </TextCustom>
              )}
              {offer.dealExclusions && (
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.p}
                  style={{
                    [Breakpoints.default]: {
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.inherit,
                      fontWeight: TextWeight.regular,
                    },
                  }}
                >
                  {offer.dealExclusions}
                </TextCustom>
              )}
              <TextCustom
                appearance={TextAppearance.headline200}
                tag={TextTag.p}
                style={{
                  [Breakpoints.default]: {
                    fontSize: TextSize.TextSize14,
                    lineHeight: TextLineHeight.TextLineHeight18,
                    color: TextColor.inherit,
                    fontWeight: TextWeight.regular,
                  },
                }}
              >
                {offer.dealLocaleType === 'Daa'
                  ? `Local Offer. Offer ends ${getDateFormat(
                      offer.dealEndDate,
                    )}`
                  : `${offer.dealLocaleType} Offer. Offer ends ${getDateFormat(
                      offer.dealEndDate,
                    )}`}
              </TextCustom>
            </StyledOfferMicroText>
            {store.dealer && (
              <StyledShowOfferDetails>
                <DisableFocusWrapper>
                  <LinkButton onClick={() => setDetailsFormData()} isInline>
                    <TextCustom
                      appearance={TextAppearance.label100}
                      tag={TextTag.p}
                      style={{
                        [Breakpoints.default]: {
                          fontSize: TextSize.TextSize14,
                          lineHeight: TextLineHeight.TextLineHeight16,
                          color: TextColor.inherit,
                          fontWeight: TextWeight.bold,
                        },
                      }}
                    >
                      {translations.offerDetails}
                    </TextCustom>
                  </LinkButton>
                </DisableFocusWrapper>
              </StyledShowOfferDetails>
            )}
          </StyledInformationOfferContainer>
        </StyledInformationWrapper>
        {store.dealer && (
          <StyledSectionButton>
            <StyledButtonWrapper>
              <StyledButtonSection>
                <DisableFocusWrapper>
                  <ButtonCustom
                    appearance={ButtonAppearance.Secondary}
                    onClick={() => setGetAQuoteFormData()}
                    padding={buttonPaddingGenerator(0)}
                    stretchContent
                    className={'get-a-quote'}
                  >
                    <Text
                      color={TextColor.inherit}
                      appearance={TextAppearance.label150}
                    >
                      {translations.getAQuote}
                    </Text>
                  </ButtonCustom>
                </DisableFocusWrapper>
              </StyledButtonSection>

              <StyledButtonSection>
                <DisableFocusWrapper>
                  <ButtonCustom
                    appearance={ButtonAppearance.Primary}
                    onClick={() => setContactDealerFormData()}
                    padding={buttonPaddingGenerator(0, 22, 22, 22)}
                    stretchContent
                    className={'contact-dealer'}
                  >
                    <Text
                      color={TextColor.inherit}
                      appearance={TextAppearance.label150}
                    >
                      {translations.contactDealer}
                    </Text>
                  </ButtonCustom>
                </DisableFocusWrapper>
              </StyledButtonSection>
            </StyledButtonWrapper>
          </StyledSectionButton>
        )}
      </StyledOfferCardWrapper>
    );
  },
);
