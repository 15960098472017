import {
  Breakpoints,
  styled,
  ThemeProviderProps,
} from '@vw-marketing/us-components';

interface DropdownState {
  isLongerBar: boolean;
  isSticky: boolean;
  topOffset: number;
  theme: ThemeProviderProps;
  modalActive: boolean;
}

export const StyledOpacityWrapper = styled.div<DropdownState>`
  ${({ isLongerBar, isSticky, topOffset, theme, modalActive }) => `
  @keyframes modalAnimation {
    0%   {z-index: 10005; top: 100vh;}
    100% {z-index: 1000; top: 0; }
  }
  position: fixed;
  display: flex;
  top: ${
    isSticky && !modalActive
      ? `calc(-1 * var(${
          isLongerBar ? '--sticky-bar-height-up' : '--sticky-bar-height-down'
        }))`
      : '0'
  };

  left: ${isSticky && !modalActive ? '-30px' : '0'};
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 1;
  @media (min-width: ${Breakpoints.b560}px) {
    width: 100vw;
    height: 100vh;
    opacity: 0.15;
    top: ${
      isSticky
        ? `calc(232px - var(${
            isLongerBar ? '--sticky-bar-height-up' : '--sticky-bar-height-up'
          }))`
        : '0'
    };
    left: ${isSticky ? `calc(-1 * ${theme.size.grid002})` : '0'};
    background-color: #000000;
    transition: top 0.5s;
  }
  `}
`;
