import { styled } from '@vw-marketing/us-components';

interface OffersAmountProps {
  isTablet: boolean;
  isVisible?: boolean;
}

export const StyledOffersAmount = styled.div<OffersAmountProps>`
  justify-content: flex-end;
  display: flex;
  align-items: center;
  flex-grow: ${(props: OffersAmountProps) => (props.isTablet ? '0' : '1')};
  visibility: ${(props: OffersAmountProps) =>
    props.isVisible ? 'visible' : 'hidden'};
`;
