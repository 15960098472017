import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledDynamicFormHeader: any = styled.div`
  width: 100%;
  border-top: 1px solid #dfe4e8;
  padding-top: 16px;
  margin-bottom: 44px;

  @media (min-width: ${Breakpoints.b960}px) {
    padding-top: 0px;
    border: none;
  }
`;

export const StyledDealerInfoContainer: any = styled.div`
  width: 100%;
  display: block;
  margin-top: 15px;

  a {
    display: inline-block;
  }

  & > :not(:last-child) {
    margin-bottom: 15px;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    display: flex;
    justify-content: space-between;

    & > :not(:last-child) {
      margin-bottom: 0px;
    }
  }
`;

export const StyledLinkWrapper: any = styled.div`
  margin-top: 0;
`;

export const StyledDisclaimerInformationWrapper:  any = styled.div`
padding-top: 15px;
@media (min-width: ${Breakpoints.b1280}px) {
  padding-top: 0;
}
`
