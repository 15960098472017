import {
  ChevronDown,
  ChevronUp,
  Text,
  TextAlignment,
  TextAppearance,
  TextTag,
} from '@vw-marketing/us-components';
import { uniq } from 'lodash';
import React, { memo, useState } from 'react';
import { filterOrderList } from '../../../..';
import { localFilterOrder } from '../../../../config/filter-order';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { FilterAttribute } from '../filter-attribute';
import {
  StyledAttributesWrapper,
  StyledFilterName,
  StyledFilterSectionWrapper,
} from './styled';

export const sortModelFilters = (
  attributes: any,
  filterOrder?: filterOrderList,
) => {
  const getAttribute = (name: string) => {
    return attributes.find((attr: any) => attr.text === name);
  };
  let newOrderAttributes: any[] = [];
  if (!filterOrder) {
    console.log('WARNING: Sorted model filter was NOT applied');
    return uniq(attributes);
  }
  filterOrder.map((filterOrderElement: string) => {
    const model = getAttribute(filterOrderElement);
    if (model) {
      newOrderAttributes.push(model);
    }
  });
  return uniq(newOrderAttributes);
};

export const FilterSection: React.FC<any> = memo(
  ({
    name,
    attributes,
    appliedFilters,
    shouldStartOpen,
    triggerTrackingEvent,
  }: any): JSX.Element => {
    const [isOpen, setIsOpen] = useState(shouldStartOpen);
    const { filterOrder} = useFeatureAppConfig();
    if (name === 'Model') {
      attributes = sortModelFilters(
        attributes,
        filterOrder || localFilterOrder,
      );
    }

    const toggleFilterSection = () => {
      setIsOpen(!isOpen);
      triggerTrackingEvent(!isOpen, name);
    };

    return (
      <StyledFilterSectionWrapper className="filter-section-wrapper">
        <StyledFilterName isOpen={isOpen} onClick={toggleFilterSection}>
          <Text
            tag={TextTag.span}
            appearance={TextAppearance.headline200}
            textAlign={TextAlignment.left}
            bold
          >
            {name}
          </Text>
          {isOpen && attributes && attributes.length > 0 ? (
            <ChevronUp />
          ) : (
            <ChevronDown />
          )}
        </StyledFilterName>
        {isOpen && attributes && (
          <StyledAttributesWrapper className="attributes-wrapper">
            {attributes.map((attribute: any) => (
              <FilterAttribute
                key={attribute.value}
                attribute={attribute}
                appliedFilters={appliedFilters}
                category={name}
              />
            ))}
          </StyledAttributesWrapper>
        )}
      </StyledFilterSectionWrapper>
    );
  },
);
