import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledLandingWrapper: any = styled.div`
  overflow-x: hidden;
  width: 100%;
`;

export const StyledInputZipWrapper: any = styled.div`
  display: inline-table;
  width: 100%;
`;

export const StyledInputZipContainer: any = styled.div`
  display: block;
  padding: 0;
  margin-bottom: 50px;
  margin-bottom: 30px;

  @media (min-width: ${Breakpoints.b1280}px) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
  }
`;

export const LayoutWrapper: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  overflow-x: visible;
  padding: 30px 20px 0 20px;
  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0 ${(props: any) => props.theme.size.grid002};
    padding-top: 30px;
  }
`;
