import {
  FailedView,
  ModalContainer,
  ModalContainerProps,
  ProcessingView,
  SuccessView,
  defaulInitialValues,
  defaultValidationSchemaConfig,
  getDynamicFormValidationSchema,
  modelInitialValues
} from '@vw-marketing/us-components';
import {Formik} from 'formik';
import _ from 'lodash';
import React, {useRef} from 'react';
import {
  DealerModel,
  OffersModel
} from '../../../hooks-store/typings/incentive-store';
import {AppConstants} from '../../../utils/app-constants';
import {cleanUpCharacters, disabledScroll} from '../../../utils/general';
import ModalFormContainer from './modal-form-container';
import {ModalFormWrapper, StyledModalFormWrapper} from './styled';
import {getSubmitHandler} from './submit-handler';
import {useFeatureServices} from '../../../services/use-feature-services';
import {useFeatureAppConfig} from '../../../services/use-feature-app-config';
import {getStringifiedServiceConfigsServiceConfig} from '../../../services/get-stringified-service-configs-service-config';
import {FeatureAppTrackingManager} from '../../../context/use-tracking-manager';
import {
  addUrlParamWithPush,
  removeUrlParam
} from '../../../utils/navigation-url';
import {useHistory} from '../../../services/use-history';
import {
  onHandleLoadTracking,
  onHandlerServiceLayerLoadEvent
} from '../../../utils/tagging-helpers';
import { useIsNationalOffers } from '../../../services/use-is-national-offer';



export interface ModalFormProps extends ModalContainerProps {
  tier2: boolean;
  zipCode: string;
  offer?: OffersModel;
  dealer?: DealerModel;
  formType: string;
  formName: string;
  trackingManager: FeatureAppTrackingManager;
}

export interface ModalFormState {
  state: 'filling' | 'submitting' | 'failed' | 'success';
}

export const ModalForm: React.FC<ModalFormProps> = props => {
  let {
    tier2,
    dealer,
    offer,
    formType,
    formName,
    onCloseHandler,
    active,
    zipCode,
    trackingManager
  } = props;
  const [internalActive, setInternalActive] = React.useState(active);
  const [formState, setFormState] = React.useState<ModalFormState>({
    state: 'filling'
  });

  const {
    mockIds,
    mocksBaseUrl,
    faServicesUrl,
    faHostServerUrl = ''
  } = useFeatureAppConfig();
  const isNationalOffers = useIsNationalOffers();
  const {
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest
  } = useFeatureServices();
  const history = useHistory();

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest
  );

  formType =
    isNationalOffers && formType === AppConstants.OfferDetailsFormType
      ? AppConstants.NationalOffersOnlyFormType
      : formType;

  const isScheduleService =
    formType === AppConstants.ScheduleServiceFormType ||
    formType === AppConstants.ScheduleServiceDetailFormType;

  const isDetailsForm =
    formType === AppConstants.OfferDetailsFormType ||
    formType === AppConstants.NationalOffersOnlyFormType;

  let testDriveComment=useRef(false)
  const updateTestDriveComment=(value)=>{ testDriveComment.current=value }

  // const formLabel = 'Contact Dealer';
  const onSubmitForm = getSubmitHandler(
    dealer,
    offer,
    setFormState,
    zipCode,
    faServicesUrl,
    formName,
    tier2,
    serviceConfigsServiceConfig,
    mockIds,
    mocksBaseUrl,
    trackingManager,
    isScheduleService,
    formType,
    testDriveComment
  );

  const onHandleDetailsUrl = (action: 'add' | 'remove') => {
    action === 'add'
      ? addUrlParamWithPush(history, 'offerId', offer?.dealId.toString() || '')
      : removeUrlParam(history, 'offerId');
  };

  React.useEffect(() => {
    setInternalActive(active);
    disabledScroll(active);
    active &&
      !isScheduleService &&
      onHandleLoadTracking(
        'load',
        formType,
        formName,
        offer,
        dealer,
        trackingManager
      );
    isDetailsForm && onHandleDetailsUrl('add');
    active &&
      isScheduleService &&
      onHandlerServiceLayerLoadEvent(
        formType,
        formName,
        dealer,
        trackingManager,
        offer
      );
  }, [active]);

  /**
   * Handle Close, helps to clean data after close action
   * avoiding close when form is submitting
   */
  const handleClose = () => {
    if (formState.state === 'submitting') {
      return;
    }
    setFormState({state: 'filling'});
    isDetailsForm && onHandleDetailsUrl('remove');
    !isScheduleService &&
      onHandleLoadTracking(
        'close',
        formType,
        formName,
        offer,
        dealer,
        trackingManager
      );
    onCloseHandler();
  };

  const validationSchema = getDynamicFormValidationSchema(
    defaultValidationSchemaConfig,
    isScheduleService
  );

  return (
    <ModalFormWrapper>
      {formState.state === 'submitting' && <ProcessingView active={true} />}
      {active && (
        <Formik
          initialValues={
            isScheduleService
              ? {...defaulInitialValues, ...modelInitialValues}
              : defaulInitialValues
          }
          initialStatus={false}
          onSubmit={onSubmitForm}
          validationSchema={validationSchema}
          validateOnMount={true}
          isInitialValid={false}
          enableReinitialize
        >
          <ModalContainer
            active={internalActive}
            onCloseHandler={handleClose}
            hideCloseButton={formState.state === 'submitting'}
          >
            <StyledModalFormWrapper>
              {formState.state === 'filling' ||
              formState.state === 'submitting' ? (
                <ModalFormContainer
                  dealer={dealer}
                  offer={cleanUpCharacters(offer)}
                  formType={formType}
                  formName={formName}
                  tier2={tier2}
                  handleClose={handleClose}
                  trackingManager={trackingManager}
                  updateTestDriveComment={updateTestDriveComment}
                />
              ) : formState.state === 'success' ? (
                <SuccessView
                  imageUrl={offer?.mediaImageUrl}
                  imageAlt={offer?.modelDisplayName}
                  imagePlacegholder={AppConstants.PlaceHolder960x432 as string}
                  srcErrorImage={faHostServerUrl + AppConstants.Ngw6Logo960x432}
                  okHandler={handleClose}
                />
              ) : (
                <FailedView
                  formName={formName}
                  setFormStateHandler={() => setFormState({state: 'filling'})}
                />
              )}
            </StyledModalFormWrapper>
          </ModalContainer>
        </Formik>
      )}
    </ModalFormWrapper>
  );
};
