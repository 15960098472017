import {Breakpoints, styled} from '@vw-marketing/us-components';

interface FilterModalProps {
  readonly filterModalActivated: boolean;
}

export const StyledFilterWrapper: any = styled.div<FilterModalProps>`
  display: none;

  z-index: ${props => (props.filterModalActivated ? '300;' : '0;')};
  ${(props: FilterModalProps) =>
    props.filterModalActivated && {
      width: '100%',
      position: 'fixed',
      top: '0',
      left: '0',
      right: '0',
      bottom: '0',
      background: '#fff',
      overflowX: 'hidden',
      overflowY: 'auto',
      zindex: '300',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between'
    }}

    @media (min-width: ${Breakpoints.b1280}px) {
    display: block;
    position: inherit;
  }
`;

export const StyledButtonWrapper: any = styled.div`
  width: 100%;
  display: flex;
  padding: 30px 20px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.5);
  box-shadow: 0px 0px 10px 0px rgba(170, 170, 170, 0.5);

  & > :first-child {
    margin-right: 10px;
  }
  & > :last-child {
    margin-left: 10px;
  }

  @media (min-width: ${Breakpoints.b560}px) {
    padding: 30px 40px;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 30px 80px;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: 50%;
`;

export const StyledSectionFilter: any = styled.div`
  width: 100%;
  padding: 34px 20px 110px;

  @media (min-width: ${Breakpoints.b560}px) {
    padding: 40 40px 0;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    padding: 40 80px 0;
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    padding: 0;
  }
`;

export const StyledSectionButton: any = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  background: white;

  @media (min-width: ${Breakpoints.b1280}px) {
    display: none;
  }
`;

export const StyledFilterHeaderWrapper: any = styled.div`
  position: relative;
  width: 100%;

  @media (min-width: ${Breakpoints.b1280}px) {
    display: none;
  }
`;

export const StyledFilterHeader: any = styled.div`
  width: 100%;
  padding: 30px 0px;
`;

export const StyledCloseIconWrapper: any = styled.div<FilterModalProps>`
  position: fixed;
  width: 100%;
  background: white;
  z-index: 1;
  display: ${(props: FilterModalProps) =>
    props.filterModalActivated ? 'flex' : 'none'};
  justify-content: flex-end;
  padding: 20px 20px 0;
`;

export const StyledFilterSectionWrapper: any = styled.div`
  width: 100%;
  border-top: solid 1px #ddd;
`;
