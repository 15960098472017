import fetch from 'node-fetch';
import { createAuthorizationHeader } from './helpers/create-authorization';
import { ServiceConfig } from './typing';

export async function fetchGraphQL(
  serviceConfig: ServiceConfig,
  body?: string,
): Promise<any> {
  let { credentials: auth, urlOrigin: origin, urlPath: path } = serviceConfig;
  const authHeader = createAuthorizationHeader(auth?.username, auth?.password);

  let requestUrl = origin + path;
  const response = await fetch(requestUrl, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    headers: {
      ...authHeader,
      'content-type': 'application/json;charset=UTF-8',
    },
    body: body,
  });

  if (!response || response.status > 300) {
    const error = await response.json();
    throw new Error(`Unable to fetch graphQL Server: ` + JSON.stringify(error));
  }
  const responseJson = await response.json();
  if (responseJson.errors && responseJson.errors.length)
    throw new Error(
      `Unable to fetch graphQL Server: ` + JSON.stringify(responseJson.errors),
    );

  return responseJson;
}
