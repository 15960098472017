import { fetchGraphQL } from './fetch-graphql';
import { createQueryString } from './helpers/create-query';
import { handleServerResponse } from './helpers/handle-server-response';
import { validateGraphQLServer } from './helpers/validations';
import { PRODUCT_NATIONAL_OFFER_QUERY } from './queries/national';
import { FetchNationalOffers, ServiceConfig } from './typing';

export const fetchOffersNational = async ({
  graphQLServer,
  modelName
}: FetchNationalOffers) => {
  const requestBody = createQueryString(PRODUCT_NATIONAL_OFFER_QUERY, {
    modelName: modelName ?? 'all',
  });
  try {
    validateGraphQLServer(graphQLServer);
    const response = await fetchGraphQL(
      graphQLServer as ServiceConfig,
      requestBody,
    );
    if (response.data && response.data.offers) {
      return handleServerResponse(response.data.offers, false);
    }
    return handleServerResponse(response, true);
  } catch (error) {
    return handleServerResponse(error, true);
  }
};
