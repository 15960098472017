import {
  MappedTrimNameModel,
  localMappedTrimName,
} from '../../config/mapped-trim-name';
import { ModelOrder, localModelOrder } from '../../config/tier-1/model-order';
import {
  DealerModel,
  OfferInformation,
} from '../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../utils/app-constants';
import { FeatureAppModelConfig } from '../../typings/general';
import {
  cleanUpCharacters,
  getModelImageData,
  getModelWithCustomCta,
} from '../../utils/general';
import { ModelConfig } from '../../typings/model-config';

const getDataByModelKey = (
  dealCarModel: string,
  modelOrderConfig: ModelOrder[],
) =>
  modelOrderConfig.filter(
    (ele: ModelOrder) => ele.modelKey === dealCarModel,
  )[0];

/**
 * Create a mapping using different criteria to be displayed on the app
 * @param offersData All offers from the feed
 */
export const mapOffersData = (
  offersData: any,
  modelOrder?: ModelOrder[],
  mappedTrimName?: MappedTrimNameModel,
  modelsConfig?: FeatureAppModelConfig,
  modelName = AppConstants.AllOfferModelKey,
) => {
  // CMS configuration
  const modelOrderConfig = modelOrder ? modelOrder : localModelOrder;
  const mappedTrimNameConfig = mappedTrimName
    ? mappedTrimName
    : localMappedTrimName;

  // Through the map, this process standardizes or add fields
  let offerAux: OfferInformation[] = offersData.map((offer: any) => ({
    ...offer,
    dealOfferTypes: [getSashType(offer)],
    dealCarTrimName: addTrimNameMapping(offer, mappedTrimNameConfig),
  }));

  if (modelName === AppConstants.AllOfferModelKey) {
    // Create a hash obj using the slug as a key {tiguan: {}, atlas: {},...}
    offerAux = offerAux.reduce((next: any, offer: any) => {
      // If there isn't a car model name the 'all' category is created to add this information
      if (!offer.dealCarModel || !offer.dealCarModel.length) {
        return {
          ...next,
          all: next.all
            ? {
                ...next.all,
                offers: [...next.all.offers, offer],
                offerCount: next.all.offerCount + 1,
              }
            : {
                offers: [offer],
                offerCount: 1,
                modelDisplayName: getModelDisplayName(
                  AppConstants.AllOfferModelKey,
                  modelOrderConfig,
                ),
                modelKey: AppConstants.AllOfferModelKey,
                slug: getModelDisplayName(
                  AppConstants.AllOfferModelKey,
                  modelOrderConfig,
                )
                  .toLowerCase()
                  .replace(/ /g, '-'),
                imageData: getModelImageData(
                  AppConstants.AllOfferModelKey,
                  modelOrderConfig,
                  modelsConfig,
                ),
                              },
        };
      }

      // Push the offer to the specific slug key, dynamically

      const modelKey = offer.dealCarModel[0];
      const modelData = getDataByModelKey(modelKey, modelOrderConfig);

      return {
        ...next,
        [offer.dealCarModel]: next[offer.dealCarModel]
          ? {
              ...next[offer.dealCarModel],
              offers: [...next[offer.dealCarModel].offers, offer],
              offerCount: next[offer.dealCarModel].offerCount + 1,
            }
          : {
              offers: [offer],
              offerCount: 1,
              modelKey: modelKey,
              modelTag: modelData?.modelTag,
              modelDisplayName: getModelDisplayName(modelKey, modelOrderConfig),
              slug: getModelDisplayName(modelKey, modelOrderConfig)
                .toLowerCase()
                .replace(/ /g, '-'),
              imageData: getModelImageData(
                modelKey,
                modelOrderConfig,
                modelsConfig,
              ),
              modelCustomCta: getModelWithCustomCta(
                modelKey,
                modelOrderConfig,
                modelsConfig,
              ),
              modelCustomArrayCta: modelData?.modelCustomArrayCta,
              modelCustomArrayCtaEnabled: modelData?.modelCustomArrayCtaEnabled,
            },
      };
    }, {});
  }
  // A config obj to determine the order and which elements will be displayed (to order and filter)
  offerAux = getOrdering(offerAux, modelOrderConfig, modelsConfig);

  return cleanUpCharacters(offerAux);
};

const getModelDisplayName = (
  dealCarModel: string,
  modelOrderConfig: ModelOrder[],
) => {
  const modelObj = modelOrderConfig.filter(
    (ele: ModelOrder) => ele.modelKey === dealCarModel,
  )[0];

  return modelObj ? modelObj.modelName : '';
};

/**
 * Check the dealOfferTypes value if is undefined change it for 'OTHER'
 * @param offer An offer data obj
 */
const getSashType = (offer: any): string => {
  if (offer.dealOfferTypes.length !== 1) {
    return 'OTHER';
  }

  return offer.dealOfferTypes[0];
};

/**
 * Use a config file obj to get the trim name, using the 'year-model-trim' pattern
 * @param offer An offer from the feed
 */
const addTrimNameMapping = (
  offer: any,
  mappedTrimNameConfig: MappedTrimNameModel,
) => {
  const trimNameList: any = [];

  if (
    !offer.dealCarTrim.length ||
    !offer.dealCarYear.length ||
    !offer.dealCarModel.length
  ) {
    return trimNameList;
  }

  for (
    let i = 0;
    i < offer.dealCarYear.length &&
    i < offer.dealCarModel.length &&
    i < offer.dealCarTrim.length;
    i++
  ) {
    const year = offer.dealCarYear[i];
    const model = offer.dealCarModel[i];
    const trim = offer.dealCarTrim[i];

    if (!trim || trim === AppConstants.AllOfferModelKey) {
      trimNameList.push('');
    } else {
      const key = `${year}-${model}-${trim}`;
      if (mappedTrimNameConfig[key] && mappedTrimNameConfig[key].trimName) {
        trimNameList.push(mappedTrimNameConfig[key].trimName);
      }
    }
  }

  return trimNameList;
};

/**
 * A config obj to determine the order and which elements will be displayed (to order and filter)
 * @param offers all the offer from the feed
 */
const getOrdering = (
  offers: any,
  modelOrderConfig: ModelOrder[],
  modelsConfig?: ModelConfig,
) =>
  modelOrderConfig.reduce((next: any, { modelKey, modelName }: any) => {
    if (offers[modelKey]) {
      return {
        ...next,
        [modelKey]: offers[modelKey],
      };
    }
    const modelData = getDataByModelKey(modelKey, modelOrderConfig);

    return {
      ...next,
      [modelKey]: {
        offers: [],
        offerCount: 0,
        modelKey,
        modelDisplayName: modelName,
        slug: modelName.toLowerCase().replace(/ /g, '-'),
        imageData: getModelImageData(modelKey, modelOrderConfig, modelsConfig),
        modelCustomCta: getModelWithCustomCta(
          modelKey,
          modelOrderConfig,
          modelsConfig,
        ),
        modelCustomArrayCta: modelData?.modelCustomArrayCta,
        modelCustomArrayCtaEnabled: modelData?.modelCustomArrayCtaEnabled,
      },
    };
  }, {});

/**
 * Get the aor dealer or the closest dealer by distance
 * @param dealers Dealers list from zipcode
 */
export const getAorDealer = (dealers: DealerModel[]): DealerModel => {
  let dealerFiltered = dealers?.filter(
    (dealer: DealerModel) => dealer.aor === 'true',
  );

  if (dealerFiltered.length === 0) {
    dealerFiltered = dealers.sort(
      (a: DealerModel, b: DealerModel) => a.distance - b.distance,
    );
  }

  return dealerFiltered[0];
};
