import React from "react";
import { IconProps } from "./typing";

const CarDocument: React.FC<IconProps> = ({ iconSize }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSize}
    height={iconSize}
    viewBox={`0 0 45 45`}
    xmlSpace="preserve"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <g>
          <g>
            <path
              d="M18.938 9.544v2.493h-5.85c-1.004.112-1.919.61-2.555 1.383l-.202.268-2.1 4.725c3.07.353 6.14.624 9.197.694l1.528.018v2.55c-3.265-.094-6.544-.305-9.814-.644l-1.96-.218-1.37 3.112v.169l-.15.187c-.538.896-.912 1.88-1.106 2.907 2.31.315 4.692.594 7.127.798l1.836.139-.207 2.456c-2.46-.18-4.86-.42-7.2-.71l-1.743-.227v10.631c0 .14.055.273.154.371.065.066.146.112.235.136l.136.018h3.75c.26 0 .48-.177.544-.418l.018-.126v-3.394h26.588v3.413c0 .14.055.273.154.371.065.066.146.112.235.136l.136.018h3.75c.248 0 .456-.173.51-.405l.015-.12V29.587c-2.25.316-4.572.582-6.947.792l-1.79.146-.188-2.475c2.415-.18 4.77-.432 7.055-.727l1.701-.23 2.555-.41c.09.489.147.985.17 1.485l.013.5v11.626c-.01 1.57-1.21 2.858-2.745 3.005l-.274.014h-3.75c-1.575 0-2.868-1.206-3.007-2.744l-.012-.275v-.919H11.738v.9c-.01 1.587-1.229 2.886-2.78 3.025l-.277.013h-3.75c-1.574 0-2.867-1.206-3.006-2.744l-.012-.275V28.688c.001-1.848.468-3.663 1.353-5.278l.278-.479 1.087-2.419c-.962-.137-1.916-.274-2.868-.423l-1.425-.233.112-1.237.206-1.238H.77c1.083.192 2.17.367 3.258.525l1.635.225 2.475-5.625c.982-1.585 2.611-2.648 4.443-2.917l.395-.045h5.963zm3.6 18.487c1.035 0 1.875.84 1.875 1.875 0 1.036-.84 1.875-1.875 1.875-1.036 0-1.875-.84-1.875-1.875 0-1.035.839-1.875 1.875-1.875zM44.371.628v23.119H23.128V.628h21.244zm-2.494 2.494H25.622v18.131h16.256V3.122zm-3.44 10.631v2.494h-9.376v-2.494h9.375zm0-5.625v2.494h-9.376V8.128h9.375z"
              transform="translate(-560.000000, -364.000000) translate(540.000000, 344.000000) translate(20.000000, 20.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CarDocument;
