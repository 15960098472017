import {Breakpoints, styled} from '@vw-marketing/us-components';

export const ModalZipWrapper: any = styled.div`
  position: fixed;
  z-index: 999;
`;

export const StyledZipContainerGroup: any = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 20px;
  max-width: 370px;

  @media (min-width: ${Breakpoints.b560}px) {
    max-width: 440px;
  }
`;

export const StyledHeaderContainer: any = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;

  & > svg {
    margin-right: 14px;
    margin-top: -8px;
  }

  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 24px;
  }
`;

export const StyledSubHeaderContainer: any = styled.div`
  width: 100%;
  margin-bottom: 55px;

  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 60px;
  }
`;

export const StyledButtonGroupWrapper: any = styled.div`
  display: inline-table;
`;

export const StyledButtonGroupContainer: any = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;

  & > :first-child {
    margin-bottom: 40px;
  }

  @media (min-width: ${Breakpoints.b560}px) {
    max-width: 320px;
  }
`;

export const StyledButtonContainer: any = styled.div`
  width: 150px;
`;
