import {
  Breakpoints,
  Button,
  ButtonAppearance,
  DisableFocusWrapper,
  FindCar,
  Link,
  Text,
  TextAlignment,
  TextAppearance,
  TextColor,
  TextCustom,
  TextSize,
  TextTag,
  TextWeight
} from '@vw-marketing/us-components';
import isBrowser from 'is-browser';
import React, {useEffect, useRef} from 'react';
import InputZip from '../input-zip';
import {ModalZipContainer} from './modal-zip-container';
import {
  ModalZipWrapper,
  StyledButtonContainer,
  StyledButtonGroupContainer,
  StyledButtonGroupWrapper,
  StyledHeaderContainer,
  StyledSubHeaderContainer,
  StyledZipContainerGroup
} from './styled';
// import {scrollToDocumentTop} from '../../utils/general';
import {onVWBasicLayerloadEvent} from '../../utils/tagging-helpers';
import {FeatureAppTrackingManager} from '../../context/use-tracking-manager';

interface ModalZipProps {
  readonly active: boolean;
  readonly trackingManager: FeatureAppTrackingManager;
  readonly offerId?: number;
}

export const ModalZip = React.memo((props: ModalZipProps) => {
  const {active, trackingManager, offerId} = props;
  const childRef = useRef<any>();

  useEffect(() => {
    if (isBrowser) {
      active
        ? (document.body.style.overflow = 'hidden')
        : (document.body.style.overflow = 'inherit');
      active && onVWBasicLayerloadEvent(trackingManager);
    }
    return () => {
      if (isBrowser) {
        document.body.style.overflow = 'inherit';
        // setTimeout(function() {
        //   scrollToDocumentTop();
        // }, 1000);
      }
    };
  }, [active]);

  return (
    <ModalZipWrapper>
      <ModalZipContainer active={active}>
        <StyledZipContainerGroup>
          <StyledHeaderContainer>
            <FindCar variant="large" />
            <TextCustom
              appearance={TextAppearance.headline200}
              style={{
                [Breakpoints.default]: {
                  fontSize: TextSize.TextSize32,
                  fontWeight: TextWeight.bold
                },
                [Breakpoints.b1280]: {
                  fontSize: TextSize.TextSize44
                }
              }}
            >
              Set your location
            </TextCustom>
          </StyledHeaderContainer>
          <StyledSubHeaderContainer>
            <TextCustom
              appearance={TextAppearance.headline200}
              style={{
                [Breakpoints.default]: {
                  fontWeight: TextWeight.regular,
                  fontSize: TextSize.TextSize20,
                  textAlign: TextAlignment.center
                }
              }}
            >
              To find dealers, inventory, and offers near you, we’ll need your
              zip code.
            </TextCustom>
          </StyledSubHeaderContainer>
          <StyledButtonGroupWrapper>
            <StyledButtonGroupContainer>
              <InputZip
                placeholder="Enter ZIP Code"
                label="Enter your ZIP code"
                errorText={'Invalid ZIP code'}
                maxLength={5}
                ref={childRef}
                isModal
                offerId={offerId}
              />
              <StyledButtonContainer>
                <DisableFocusWrapper>
                  <Button
                    appearance={ButtonAppearance.Primary}
                    onClick={() => childRef.current.applyZip()}
                    stretchContent
                  >
                    <Text
                      color={TextColor.inherit}
                      appearance={TextAppearance.copy100}
                    >
                      Go
                    </Text>
                  </Button>
                </DisableFocusWrapper>
              </StyledButtonContainer>
            </StyledButtonGroupContainer>
          </StyledButtonGroupWrapper>
          <DisableFocusWrapper>
            <Text
              appearance={TextAppearance.copy100}
              tag={TextTag.p}
              bold
              textAlign={TextAlignment.center}
            >
              <Link href={'/en/privacy.html'} target={'_blank'} isInline>
                View Privacy Policy
              </Link>
            </Text>
          </DisableFocusWrapper>
        </StyledZipContainerGroup>
      </ModalZipContainer>
    </ModalZipWrapper>
  );
});
