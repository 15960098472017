import {
  ArrowRight,
  Breakpoints,
  DisableFocusWrapper,
  Link,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight
} from '@vw-marketing/us-components';
import React from 'react';

interface BtnLinkProps {
  readonly btnLabel?: string;
  readonly iconLabel?: JSX.Element;
  readonly btnAction?: () => void;
}

export const BtnLink: React.FC<BtnLinkProps> = props => {
  const {btnLabel, btnAction, iconLabel} = props;

  return (
    <DisableFocusWrapper>
      <TextCustom
        appearance={TextAppearance.copy100}
        tag={TextTag.h2}
        style={{
          [Breakpoints.default]: {
            fontSize: TextSize.TextSize14,
            lineHeight: TextLineHeight.TextLineHeight16,
            fontWeight: TextWeight.bold
          },
          [Breakpoints.b1280]: {
            fontSize: TextSize.TextSize16,
            lineHeight: TextLineHeight.TextLineHeight20
          }
        }}
      >
        <Link onClick={btnAction} isInline>
          {btnLabel} { iconLabel || <ArrowRight variant="small" />}
        </Link>
      </TextCustom>
    </DisableFocusWrapper>
  );
};
