import React from 'react';
import { DisclaimerModalWrapper } from './styles';

const ModalDisclaimerWrapper: React.FC<any> = ({
  children,
}): React.JSX.Element => {
  return (
    <DisclaimerModalWrapper className="disclaimer-wrapper">
      {children}
    </DisclaimerModalWrapper>
  );
};

export default ModalDisclaimerWrapper;
