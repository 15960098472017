import React from 'react';

import { NoDealers } from '../../../components/no-dealers';
import { useIsNationalOffers } from '../../../services/use-is-national-offer';
import { useStore } from '../../../hooks-store/store';
import { NewOffersContainer } from '../offers-container/NewOffersContainer';

export const BottomContainerSection = () => {
  const [store] = useStore();
  const isNationalOffer = useIsNationalOffers();

  return (
    <>
      {!store.dealers.length && !isNationalOffer ? (
        <NoDealers />
      ) : (
        <NewOffersContainer />
      )}
    </>
  );
};
