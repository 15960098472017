import React, { ReactNode } from 'react';
import Marker from './markers/marker';
import UserMarker from './markers/user-marker';
import { DealerMapWrapper } from './styled';
import GoogleMap from '../../../../../components/google-map';
import {
  DealerModel,
  LocationModel,
} from '../../../../../hooks-store/typings/incentive-store';
import { ActiveDealerState } from '..';
import { useFeatureAppConfig } from '../../../../../services/use-feature-app-config';

interface DealerMapProps {
  zoom: number;
  dealers: DealerModel[];
  userLocation: LocationModel;
  activeDealer: ActiveDealerState;
  updateActiveDealer: (
    dealer: DealerModel,
    showModal: boolean,
    isTracking?: boolean,
  ) => void;
  children?: ReactNode;
  updateMap?: boolean;
}

const DealerMap = ({
  dealers,
  userLocation,
  zoom,
  activeDealer,
  updateActiveDealer,
}: DealerMapProps) => {
  const { faHostServerUrl = '' } = useFeatureAppConfig();

  const renderDealerMarkers = (activeDealerId?: string) => {
    return dealers.map((dealer: DealerModel) => {
      const { dealerid } = dealer;
      return (
        <Marker
          key={dealerid}
          dealer={dealer}
          active={activeDealerId === dealerid}
          setNewDealer={updateActiveDealer}
          lat={parseFloat(dealer.lat || '0')}
          lng={parseFloat(dealer.lng || '0')}
          faHostServerUrl={faHostServerUrl}
        />
      );
    });
  };
  return (
    <DealerMapWrapper>
      <GoogleMap zoom={zoom} center={activeDealer.center} dealers={dealers}>
        {renderDealerMarkers(activeDealer.dealerId)}
        {!userLocation.error && (
          <UserMarker
            key={'userLocation'}
            markerSize={'44'}
            lat={userLocation.lat}
            lng={userLocation.lng}
          />
        )}
      </GoogleMap>
    </DealerMapWrapper>
  );
};

export default DealerMap;
