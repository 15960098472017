import React from "react";
import { IconProps } from "./typing";

const Chat = ({ iconSize }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSize}
    height={iconSize}
    viewBox={`0 0 45 45`}
    xmlSpace="preserve"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <g>
          <g>
            <path
              d="M41.269 12.769c1.035 0 1.875.84 1.875 1.875v16.875c0 1.035-.84 1.875-1.875 1.875h-3.75v7.5c.008.245-.144.468-.376.55-.232.081-.49.003-.637-.194-1.974-2.695-4.367-5.056-7.087-6.994-.975-.537-2.058-.851-3.169-.919h-5.625c-1.036 0-1.875-.839-1.875-1.875v-3.75h2.494v3.207h5.006c1.523.073 3.01.49 4.35 1.219 1.63 1.011 3.112 2.241 4.406 3.656v-4.875h5.625V15.3H37.5v-2.531zM33.75 3.394c1.036 0 1.875.84 1.875 1.875v18.75c0 1.035-.84 1.875-1.875 1.875h-15c-1.111.067-2.194.38-3.169.919-2.72 1.921-5.12 4.263-7.106 6.937-.067.164-.218.279-.394.3-.152.005-.3-.052-.41-.158-.11-.106-.171-.252-.171-.405v-7.5H3.75c-1.036 0-1.875-.839-1.875-1.874V5.363c0-1.036.84-1.876 1.875-1.876h30zm-.638 2.587H4.35V23.4h5.625v4.875c1.292-1.418 2.775-2.648 4.406-3.656 1.345-.732 2.84-1.149 4.369-1.219h14.362V5.981z"
              transform="translate(-835.000000, -364.000000) translate(815.000000, 344.000000) translate(20.000000, 20.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Chat;
