import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StyledOfferTypeLabelProps {
  readonly backgroundColor: string;
  readonly textColor: string;
}

interface StyledOpacityWrapperProps {
  isOpacity?: boolean;
}

export const StyledOfferCardWrapper: any = styled.div`
  border: 1px solid #dfe4e8;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  min-width: 100%;
  height: 550px;
  margin-top: 12px;

  @media (min-width: ${Breakpoints.b960}px) and (max-width: ${Breakpoints.b1600}px) {
    min-width: 290px;
    max-width: 400px;
  }

  @media (max-width: ${Breakpoints.b960}px) and (min-width: ${Breakpoints.b560}px) {
    min-width: 270px;
  }
`;

export const StyledInformationOfferContainer: any = styled.div`
  width: 100%;
  padding: 30px 20px 0;
  max-height: 400px;
  position: relative;
  overflow: hidden;
`;

export const StyledOfferMicroText: any = styled.div`
  width: 100%;
  margin-bottom: 20px;
  color: #758189;
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const StyledTextGradient: any = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
  );

  height: 80px;
`;

interface StyledButtonWrapperProps {
  oneElement?: boolean;
}

export const StyledButtonWrapper: any = styled.div<StyledButtonWrapperProps>`
  align-items: center;
  border-top: 1px solid #dfe4e8;
  display: flex;
  justify-content: ${(props: StyledButtonWrapperProps) =>
    props.oneElement ? 'center' : 'space-between'};
  padding: 20px 10px;
  width: 100%;
  & > :first-child {
    margin-right: 10px;
  }
  & > :last-child {
    margin-left: 10px;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: auto;
`;

export const StyledSectionButton: any = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
`;

export const StyledModelNameWrapper: any = styled.div`
  margin-bottom: 0px;
  min-height: 48px;
`;

export const StyledOfferTitle: any = styled.div`
  min-height: 85px;
  margin-bottom: 15px;
`;

export const StyledOfferSubtitle: any = styled.div`
  margin-bottom: 20px;

  p:not(:only-child):not(:last-child) {
    margin-bottom: 15px;
  }
`;

export const StyledShowOfferDetails: any = styled.div`
  margin-bottom: 30px;
  padding: 0 20px 0;
`;

export const StyledOfferTypeLabelWrapper: any = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledBlackContainer: any = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(10px, -50%);
  padding: 0 0;
  width: max-content;
`;

export const StyledOfferTypeLabel: any = styled.div<StyledOfferTypeLabelProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
`;

export const StyledOpacityWrapper: any = styled.div<StyledOpacityWrapperProps>`
  ${(props: StyledOpacityWrapperProps) =>
    props.isOpacity &&
    ` position: absolute;
    width: 100%;
    height: calc(100% + 15px);
    top: -15px;
    right: 0;
    background-color: white;
    opacity: 0.5;
    z-index: 1;
  `}
`;
interface StyledInformationWrapperPorps {
  readonly wrapperHeight: number;
}
export const StyledInformationWrapper: any = styled.div<
  StyledInformationWrapperPorps
>`
  ${(props: StyledInformationWrapperPorps) =>
    `max-height: ${props.wrapperHeight}px;`}
  width: 100%;
  position: relative;
`;
