import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledLandingWrapper: any = styled.div`
  overflow-x: hidden;
  width: 100%;
  max-width: 100vw;
`;

interface StyledInputZipWrapperProps {
  specialOffersRedesignEnable: boolean;
}

export const StyledInputZipWrapper: any = styled.div<StyledInputZipWrapperProps>`
  display: inline-table;
  width: ${(props: StyledInputZipWrapperProps) => props.specialOffersRedesignEnable ? 'auto' : '100%'};

  ${(props: StyledInputZipWrapperProps) => props.specialOffersRedesignEnable && `
    @media (max-width: ${Breakpoints.b560}px) {
      width: 100%;
    }
  `}
`;

export const StyledInputZipContainer: any = styled.div<StyledInputZipWrapperProps>`
  display: block;
  padding: 0;
  margin-bottom: ${(props: StyledInputZipWrapperProps) => props.specialOffersRedesignEnable ? '28px' : '44px'};

  @media (min-width: ${Breakpoints.b1280}px) {
    display: flex;
    justify-content: flex-end;
  }
`;

export const LayoutWrapper: any = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0;
  overflow-x: visible;
  padding: 30px 20px 0 20px;
  @media (min-width: ${Breakpoints.b960}px) {
    padding: 0 ${(props: any) => props.theme.size.grid002};
    padding-top: 30px;
  }
`;
