

export const DEALERS_LMA_BY_ZIPCODE = {query: `query GetLmaDealersByZip($zipCode: String!) {
  dealers: getLmaDealersByZip(zipCode: $zipCode) {
    generatedDate
    dealerid
    name
    dealername
    seolookupkey
    address1
    address2
    city
    state
    postalcode
    country
    url
    phone
    latlong
    staticMapsUrl
    distance
    inventoryCount
    aor
    isSatellite
    isAssessing
    lmaId
  }
}`}