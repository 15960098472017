import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledSectionWrapper: any = styled.div`
  width: 100%;
  margin-bottom: 70px;
`;

interface StyledHeaderContainerProps {
  specialOffersRedesignEnable: boolean;
}

export const StyledHeaderContainer: any = styled.div<StyledHeaderContainerProps>`
  display: flex;
  justify-content: ${(props: StyledHeaderContainerProps) => props.specialOffersRedesignEnable ? 'flex-start' : 'space-between'};
  ${(props: StyledHeaderContainerProps) => props.specialOffersRedesignEnable && 'gap: 60px;'}
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: solid 1px #dfe4e8;

  ${(props: StyledHeaderContainerProps) => !props.specialOffersRedesignEnable && `
      & > :first-child {
        margin-right: 60px;
      }
    `
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    ${(props: StyledHeaderContainerProps) => !props.specialOffersRedesignEnable && 'justify-content: flex-start;'}
    margin-bottom: 25px;
  }

  ${(props: StyledHeaderContainerProps) => props.specialOffersRedesignEnable &&
    `
      @media (max-width: ${Breakpoints.b560}px) {
      justify-content: space-between;
    }
  `
  }

`;

export const StyledCursorAction: any = styled.div`
  cursor: pointer;
`;
