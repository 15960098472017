import React from 'react';

import {
  Breakpoints,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

import { BtnLink } from './btn-link';
import { BtnLinkButton } from './btn-link-button';
import {
  StyledCursorAction,
  StyledHeaderContainer,
  StyledSectionWrapper,
} from './styled';
import {
  StyledInputZipContainer,
  StyledInputZipWrapper,
} from '../tier1-simplified-version/styled';
import { useTranslations } from '../../services/use-translations';
import InputZip from '../../components/input-zip';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';
import { SkeletonBox } from '../../components/skeleton-box';

type ModelButtonType = 'link' | 'button';

interface SectionWithHeaderProps {
  readonly title: string;
  readonly btnLabel?: string;
  readonly iconLabel?: JSX.Element;
  readonly btnAction?: () => void;
  readonly buttonType?: ModelButtonType;
  readonly showButton?: boolean;
  readonly children: React.ReactNode;
  readonly isLoading?: boolean;
}

export const SectionWithHeader: React.FC<SectionWithHeaderProps> = props => {
  const {
    title,
    btnLabel,
    btnAction,
    buttonType,
    showButton = true,
    children,
    iconLabel,
    isLoading = false,
  } = props;

  const translations = useTranslations().tier1simplifiedVersion;
  const { specialOffersRedesignEnable } = useFeatureAppConfig();

  return (
    <StyledSectionWrapper>
      <StyledHeaderContainer
        specialOffersRedesignEnable={specialOffersRedesignEnable}
      >
        <TextCustom
          appearance={TextAppearance.headline200}
          tag={TextTag.h2}
          style={{
            [Breakpoints.default]: {
              fontSize: TextSize.TextSize24,
              fontWeight: TextWeight.bold,
            },
            [Breakpoints.b1280]: {
              fontSize: TextSize.TextSize32,
              lineHeight: TextLineHeight.TextLineHeight44,
            },
          }}
        >
          {title}
        </TextCustom>

        {showButton && buttonType === 'link' ? (
          <StyledCursorAction>
            <BtnLink
              btnAction={btnAction}
              btnLabel={btnLabel}
              iconLabel={iconLabel}
            />
          </StyledCursorAction>
        ) : showButton && buttonType === 'button' ? (
          <BtnLinkButton
            btnAction={btnAction}
            btnLabel={btnLabel}
            iconLabel={iconLabel}
          />
        ) : null}
      </StyledHeaderContainer>
      {specialOffersRedesignEnable && (
        <StyledInputZipWrapper
          specialOffersRedesignEnable={specialOffersRedesignEnable}
        >
          <StyledInputZipContainer
            specialOffersRedesignEnable={specialOffersRedesignEnable}
          >
            {isLoading ? (
              <SkeletonBox
                height="50px"
                width="200px"
                responsiveWidth={{ mobile: '100%' }}
              />
            ) : (
              <InputZip
                placeholder={translations.inputZipPlaceholder}
                errorText={translations.inputZipError}
                maxLength={5}
              />
            )}
          </StyledInputZipContainer>
        </StyledInputZipWrapper>
      )}
      {children}
    </StyledSectionWrapper>
  );
};
