export interface DealerAssociations {
  [key: string]: LmaData;
}

export interface LmaData {
  readonly lmaId: string;
  readonly name: string;
  readonly lmaVanityName: string;
  readonly lmaVanityUrlName: string;
  readonly mastheadImage: string;
  readonly region: string;
  readonly modelOrder: ModelOrder[];
}

export interface ModelOrder {
  readonly modelKey: string;
  readonly modelName: string;
}

// Regex to find every modelOrder Array that is not empty
// "modelOrder: \[[[\n]+[^\]]+\]"

export const dealerAssociations: DealerAssociations[] = [
  {
    lmaData: {
      lmaId: '193',
      name: 'LMC',
      lmaVanityName: '',
      lmaVanityUrlName: '',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: '',
      modelOrder: []
    }
  },
  {
    lmaData: {
      lmaId: '650',
      name: 'Greenville LMC',
      lmaVanityName: 'Volkswagen Dealers of Greenville NC',
      lmaVanityUrlName: 'vw-greenville-nc',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: '',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '855',
      name: 'Central Coast',
      lmaVanityName: 'Volkswagen Dealers of Central Coast',
      lmaVanityUrlName: 'vw-central-coast',
      mastheadImage: '/assets/feature-app/static/beach.jpg',
      region: '',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '991',
      name: 'Northeast Region Generic LMC',
      lmaVanityName: 'Volkswagen Dealers of Northeast Region Generic',
      lmaVanityUrlName: 'ner-ner-local-marketing-fund-dealer',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: '',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '992',
      name: 'Southeast Region Generic LMC',
      lmaVanityName: 'Volkswagen Dealers of Southeast Region Generic',
      lmaVanityUrlName: 'vw-charlotte-nc',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '993',
      name: 'Midwest Region LMC',
      lmaVanityName: 'Volkswagen Dealers of the Midwest Region',
      lmaVanityUrlName: 'vw-cincinnatti-oh',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: '',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '994',
      name: 'South Central Region Generic LMC',
      lmaVanityName: 'Volkswagen Dealers of South Central Region',
      lmaVanityUrlName: '',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: '',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '995',
      name: 'Pacific Region Generic LMC',
      lmaVanityName: 'Volkswagen Dealers of Pacific Region Generic',
      lmaVanityUrlName: 'vw-pacific-region',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: '',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '194',
      name: 'LMC',
      lmaVanityName: '',
      lmaVanityUrlName: '',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: []
    }
  },
  {
    lmaData: {
      lmaId: '211',
      name: 'Chicago and Northwest Indiana',
      lmaVanityName: 'Volkswagen Dealers of Chicago and Northwest Indiana',
      lmaVanityUrlName: 'vw-chicago-nw-indiana',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '217',
      name: 'Kansas City LMC',
      lmaVanityName: 'Volkswagen Dealers of Kansas City',
      lmaVanityUrlName: 'vw-kansas-city',
      mastheadImage:
        '/assets/feature-app/static/urban_sunlight.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '218',
      name: 'Milwaukee LMC',
      lmaVanityName: 'Volkswagen Dealers of Milwaukee',
      lmaVanityUrlName: 'vw-milwaukee',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '219',
      name: 'Minneapolis LMC',
      lmaVanityName: 'Minnesota',
      lmaVanityUrlName: 'vw-twin-cities',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '222',
      name: 'St. Louis LMC',
      lmaVanityName: 'Volkswagen Dealers of St. Louis',
      lmaVanityUrlName: 'vw-st-louis',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '223',
      name: 'LMC',
      lmaVanityName: 'Omaha',
      lmaVanityUrlName: 'vw-omaha',
      mastheadImage: '/assets/feature-app/static/urban_blue.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '253',
      name: 'East Iowa LMC',
      lmaVanityName: 'Volkswagen Dealers of East Iowa',
      lmaVanityUrlName: 'vw-east-iowa',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '256',
      name: 'Madison LMC',
      lmaVanityName: 'Volkswagen Dealers of Madison',
      lmaVanityUrlName: 'vw-madison',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '257',
      name: 'Northeast WI LMC',
      lmaVanityName: 'Volkswagen Dealers of Northeast WI',
      lmaVanityUrlName: 'vw-northeast-wi',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '258',
      name: 'Oklahoma City LMC',
      lmaVanityName: 'Volkswagen Dealers of Oklahoma City',
      lmaVanityUrlName: 'vw-oklahoma-city',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '616',
      name: 'Dallas Ft. Worth LMC [DFWVW]',
      lmaVanityName: 'Volkswagen Dealers of Dallas Ft Worth',
      lmaVanityUrlName: 'vw-dallas-ft-worth',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '618',
      name: 'Houston LMC',
      lmaVanityName: 'Volkswagen Dealers of Houston',
      lmaVanityUrlName: 'vw-houston',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '651',
      name: 'Austin LMC',
      lmaVanityName: 'Volkswagen Dealers of Austin',
      lmaVanityUrlName: 'vw-austin',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '653',
      name: 'Rio Grande Valley',
      lmaVanityName: 'Volkswagen Dealers of Rio Grande Valley',
      lmaVanityUrlName: 'vw-rio-grande-valley',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '661',
      name: 'Little Rock LMC',
      lmaVanityName: 'Volkswagen Dealers of Little Rock',
      lmaVanityUrlName: 'vw-little-rock',
      mastheadImage:
        '/assets/feature-app/static/open_field_and_road.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '662',
      name: 'Memphis LMC',
      lmaVanityName: 'Volkswagen Dealers of Memphis',
      lmaVanityUrlName: 'vw-memphis',
      mastheadImage: '/assets/feature-app/static/overpass.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '664',
      name: 'New Orleans LMC',
      lmaVanityName: 'Volkswagen Dealers of New Orleans',
      lmaVanityUrlName: 'vw-new-orleans',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '668',
      name: 'San Antonio LMC',
      lmaVanityName: 'Volkswagen Dealers of San Antonio',
      lmaVanityUrlName: 'vw-san-antonio',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '681',
      name: 'LMC',
      lmaVanityName: 'El Paso - Las Cruces',
      lmaVanityUrlName: 'vw-el-paso',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '693',
      name: 'LMC',
      lmaVanityName: 'Longview Tyler',
      lmaVanityUrlName: 'vw-longview-tyler',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '694',
      name: 'LMC',
      lmaVanityName: 'Central Texas',
      lmaVanityUrlName: 'vw-central-texas',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '815',
      name: 'Rocky Mtn LMC',
      lmaVanityName: 'Volkswagen Dealers of Rocky Mountain',
      lmaVanityUrlName: 'vw-rocky-mountain',
      mastheadImage: '/assets/feature-app/static/lake.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '851',
      name: 'Albuquerque LMC',
      lmaVanityName: 'Volkswagen Dealers of Albuquerque',
      lmaVanityUrlName: 'vw-albuquerque',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '854',
      name: 'S Colorado VW LMC',
      lmaVanityName: 'Volkswagen Dealers of Southern Colorado',
      lmaVanityUrlName: 'vw-so-colorado',
      mastheadImage:
        '/assets/feature-app/static/meteor_shower.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '191',
      name: 'LMC',
      lmaVanityName: '',
      lmaVanityUrlName: '',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: []
    }
  },
  {
    lmaData: {
      lmaId: '212',
      name: 'Cincinnati/Northern Kentucky LMC',
      lmaVanityName: 'Volkswagen Dealers of Cincinnati/Northern Kentucky',
      lmaVanityUrlName: 'vw-cincinnati',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '213',
      name: 'Cleveland-Akron-Canton',
      lmaVanityName: 'Volkswagen Dealers of Cleveland-Akron-Canton',
      lmaVanityUrlName: 'vw-cleveland-akron-canton',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '214',
      name: 'Columbus LMC',
      lmaVanityName: 'Volkswagen Dealers of Columbus',
      lmaVanityUrlName: 'vw-columbus',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '215',
      name: 'Detroit LMC',
      lmaVanityName: 'Volkswagen Dealers of Detroit',
      lmaVanityUrlName: 'vw-detroit',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '221',
      name: 'Pittsburgh LMC',
      lmaVanityName: 'Volkswagen Dealers of Pittsburgh',
      lmaVanityUrlName: 'vw-pittsburgh',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '252',
      name: 'Dayton LMC',
      lmaVanityName: 'Volkswagen Dealers of Dayton',
      lmaVanityUrlName: 'vw-dayton',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '411',
      name: 'Buffalo LMC',
      lmaVanityName: 'Volkswagen Dealers of Buffalo',
      lmaVanityUrlName: 'vw-buffalo',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '412',
      name: 'Central NY LMC',
      lmaVanityName: 'Volkswagen Dealers of Central New York',
      lmaVanityUrlName: 'vw-central-new-york',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '413',
      name: 'Connecticut LMC',
      lmaVanityName: 'Volkswagen Dealers of Connecticut',
      lmaVanityUrlName: 'vw-connecticut',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '414',
      name: 'Maine LMC',
      lmaVanityName: 'Volkswagen Dealers of Maine',
      lmaVanityUrlName: 'vw-maine',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '415',
      name: 'New England LMC',
      lmaVanityName: 'Volkswagen Dealers of New England',
      lmaVanityUrlName: 'vw-new-england',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '416',
      name: 'North Central PA LMC',
      lmaVanityName: 'Volkswagen Dealers of North Central Pennsylvania',
      lmaVanityUrlName: 'vw-north-central-pennsylvania',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '417',
      name: 'PhiladelphiaSouth JerseyDelawareLehigh Valley',
      lmaVanityName:
        'Volkswagen Dealers of Philadelphia/South Jersey/Delaware/Lehigh Valley',
      lmaVanityUrlName: 'vw-philadelphia-south-jersey-delaware',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '418',
      name: 'Rochester LMC',
      lmaVanityName: 'Volkswagen Dealers of Rochester',
      lmaVanityUrlName: 'vw-rochester',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '419',
      name: 'SE Mass/RI LMC',
      lmaVanityName: 'Southeastern Massachusetts and Rhode Island',
      lmaVanityUrlName: 'vw-southeastern-massachusetts-and-rhode-island',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '420',
      name: 'South Central PA LMC',
      lmaVanityName: 'Volkswagen Dealers of South Central Pennsylvania',
      lmaVanityUrlName: 'vw-south-central-pennsylvania',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '421',
      name: 'Tri-State LMC',
      lmaVanityName: 'Volkswagen Dealers of Tri-State',
      lmaVanityUrlName: 'vw-tri-state',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '422',
      name: 'Upstate NY LMC',
      lmaVanityName: 'Volkswagen Dealers of Upstate New York',
      lmaVanityUrlName: 'vw-upstate-new-york',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '451',
      name: 'Burlington VT LMC',
      lmaVanityName: 'Vermont',
      lmaVanityUrlName: 'vw-vermont',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '452',
      name: 'Western Mass. LMC',
      lmaVanityName: 'Volkswagen Dealers of Western Massachusetts',
      lmaVanityUrlName: 'vw-western-massachusetts',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'NER',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '195',
      name: 'LMC',
      lmaVanityName: '',
      lmaVanityUrlName: '',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'PAR',
      modelOrder: []
    }
  },
  {
    lmaData: {
      lmaId: '811',
      name: 'Las Vegas LMC',
      lmaVanityName: 'Volkswagen Dealers of Las Vegas',
      lmaVanityUrlName: 'vw-las-vegas',
      mastheadImage: '/assets/feature-app/static/rocky_road.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '812',
      name: 'Phoenix LMC',
      lmaVanityName: 'Volkswagen Dealers of Phoenix',
      lmaVanityUrlName: 'vw-phoenix',
      mastheadImage: '/assets/feature-app/static/rocky_road.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '813',
      name: 'Portland LMC',
      lmaVanityName: 'Volkswagen Dealers of Portland',
      lmaVanityUrlName: 'vw-portland',
      mastheadImage:
        '/assets/feature-app/static/winding_forest.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '814',
      name: 'Western Washington LMC',
      lmaVanityName: 'Volkswagen Dealers of Western Washington',
      lmaVanityUrlName: 'vw-western-washington',
      mastheadImage: '/assets/feature-app/static/seattle.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '816',
      name: 'Southern California LMC',
      lmaVanityName: 'Volkswagen Dealers of Southern California',
      lmaVanityUrlName: 'vw-southern-california',
      mastheadImage: '/assets/feature-app/static/beach.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '817',
      name: 'Sacramento LMC',
      lmaVanityName: 'Volkswagen Dealers of Sacramento',
      lmaVanityUrlName: 'vw-sacramento',
      mastheadImage:
        '/assets/feature-app/static/open_field_and_road.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '818',
      name: 'San Diego VW LMC',
      lmaVanityName: 'Volkswagen Dealers of San Diego',
      lmaVanityUrlName: 'vw-san-diego',
      mastheadImage: '/assets/feature-app/static/beach.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '819',
      name: 'Bay Area LMC',
      lmaVanityName: 'Volkswagen Dealers of Bay Area',
      lmaVanityUrlName: 'vw-bay-area',
      mastheadImage:
        '/assets/feature-app/static/winding_forest.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '820',
      name: 'Utah LMC',
      lmaVanityName: 'Volkswagen Dealers of Utah',
      lmaVanityUrlName: 'vw-utah',
      mastheadImage: '/assets/feature-app/static/lake.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '852',
      name: 'Fresno LMC',
      lmaVanityName: 'Volkswagen Dealers of Fresno',
      lmaVanityUrlName: 'vw-fresno',
      mastheadImage:
        '/assets/feature-app/static/open_field_and_road.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '853',
      name: 'Hawaii LMC',
      lmaVanityName: 'Volkswagen Dealers of Hawaii',
      lmaVanityUrlName: 'vw-hawaii',
      mastheadImage: '/assets/feature-app/static/beach.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '857',
      name: 'LMC',
      lmaVanityName: 'Palm Springs',
      lmaVanityUrlName: 'vw-palm-springs',
      mastheadImage: '/assets/feature-app/static/rocky_road.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '858',
      name: 'LMC',
      lmaVanityName: 'Tucson',
      lmaVanityUrlName: 'vw-tucson',
      mastheadImage: '/assets/feature-app/static/rocky_road.jpg',
      region: 'PAR',
      modelOrder: [
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '192',
      name: 'LMC',
      lmaVanityName: '',
      lmaVanityUrlName: '',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: []
    }
  },
  {
    lmaData: {
      lmaId: '216',
      name: 'Indianapolis LMC',
      lmaVanityName: 'Volkswagen Dealers of Indianapolis',
      lmaVanityUrlName: 'vw-indianapolis',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '255',
      name: 'Louisville LMC',
      lmaVanityName: 'Volkswagen Dealers of Louisville',
      lmaVanityUrlName: 'vw-louisville',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '609',
      name: 'LMC',
      lmaVanityName: 'Charlotte',
      lmaVanityUrlName: 'vw-charlotte',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '610',
      name: 'Miami',
      lmaVanityName: 'South Florida',
      lmaVanityUrlName: 'vw-miami-westpalmbeach',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '611',
      name: 'Atlanta LMC',
      lmaVanityName: 'Volkswagen Dealers of Atlanta',
      lmaVanityUrlName: 'vw-atlanta',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '612',
      name: 'Baltimore LMC',
      lmaVanityName: 'Volkswagen Dealers of Baltimore',
      lmaVanityUrlName: 'vw-baltimore',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '613',
      name: 'Central FL LMC',
      lmaVanityName: 'Volkswagen Dealers of Central Florida',
      lmaVanityUrlName: 'vw-central-florida',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '617',
      name: 'Jacksonville LMC',
      lmaVanityName: 'Volkswagen Dealers of Jacksonville',
      lmaVanityUrlName: 'vw-jacksonville',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '619',
      name: 'Richmond LMC',
      lmaVanityName: 'Volkswagen Dealers of Richmond',
      lmaVanityUrlName: 'vw-richmond',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '621',
      name: 'Southwest FL LMC',
      lmaVanityName: 'Volkswagen Dealers of Southwest FLA',
      lmaVanityUrlName: 'vw-southwest-fla',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '622',
      name: 'Tampa Bay LMC',
      lmaVanityName: 'Volkswagen Dealers of Tampa Bay',
      lmaVanityUrlName: 'vw-tampa-bay',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '623',
      name: 'Washington DC LMC [DC Area]',
      lmaVanityName: 'Volkswagen Dealers of Washington DC',
      lmaVanityUrlName: 'vw-washington-dc',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '648',
      name: 'New Arkansas',
      lmaVanityName: 'New Arkansas',
      lmaVanityUrlName: 'vw-new-arkansas',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'CER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '649',
      name: 'LMC',
      lmaVanityName: 'Birmingham',
      lmaVanityUrlName: 'vw-birmingham',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '654',
      name: 'Central Gulf Coast LMC',
      lmaVanityName: 'Volkswagen Dealers of Central Gulf Coast',
      lmaVanityUrlName: 'vw-central-gulf-coast',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '656',
      name: 'Charleston LMC',
      lmaVanityName: 'Volkswagen Dealers of Charleston',
      lmaVanityUrlName: 'vw-charleston',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '657',
      name: 'Chattanooga LMC',
      lmaVanityName: 'Volkswagen Dealers of Chattanooga',
      lmaVanityUrlName: 'vw-chattanooga',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '658',
      name: 'Columbia LMC',
      lmaVanityName: 'Volkswagen Dealers of Columbia',
      lmaVanityUrlName: 'vw-columbia',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '660',
      name: 'Greenville-Spartanburg-Asheville LMC',
      lmaVanityName: 'Volkswagen of the Carolinas',
      lmaVanityUrlName: 'vw-carolinas',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '663',
      name: 'Nashville LMC',
      lmaVanityName: 'Volkswagen Dealers of Nashville',
      lmaVanityUrlName: 'vw-nashville',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '665',
      name: 'North Central FL LMC',
      lmaVanityName: 'Volkswagen Dealers of North Central Florida',
      lmaVanityUrlName: 'vw-north-central-florida',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '667',
      name: 'Raleigh Durham LMC',
      lmaVanityName: 'Volkswagen Dealers of Raleigh Durham',
      lmaVanityUrlName: 'vw-raleigh-durham',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '670',
      name: 'Hampton Roads LMC',
      lmaVanityName: 'Volkswagen Dealers of Hampton Roads',
      lmaVanityUrlName: 'vw-hampton-roads',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '691',
      name: 'LMC',
      lmaVanityName: 'Triad Cities',
      lmaVanityUrlName: 'vw-triad-cities',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '695',
      name: 'LMC',
      lmaVanityName: 'Savannah Hilton Head',
      lmaVanityUrlName: 'vw-savannah-hilton-head',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  },
  {
    lmaData: {
      lmaId: '922',
      name: 'San Juan',
      lmaVanityName: 'Puerto Rico',
      lmaVanityUrlName: 'vw-puerto-rico',
      mastheadImage: '/assets/feature-app/static/metal.jpg',
      region: 'SER',
      modelOrder: [
        {modelKey: 'tiguan', modelName: 'Tiguan'},
        {modelKey: 'atlas', modelName: 'Atlas'},
        {modelKey: 'acs', modelName: 'Atlas Cross Sport'},
        {modelKey: 'taos', modelName: 'Taos'},
        {modelKey: 'id4', modelName: 'ID.4'},
        {modelKey: 'jetta', modelName: 'Jetta'},
        {modelKey: 'jettagli', modelName: 'Jetta GLI'},
        {modelKey: 'golfgti', modelName: 'Golf GTI'},
        {modelKey: 'arteon', modelName: 'Arteon'}
      ]
    }
  }
];
