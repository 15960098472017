import {
  LayerWrapper,
  ModalLayer,
  ShimLayer,
  withFocusLock,
} from '@vw-marketing/us-components';
import React from 'react';
import { StyledContainer } from './styled';

interface ModalZipContainer {
  readonly active: boolean;
  readonly children: React.ReactNode;
}

export const ModalZipContainer: React.FC<ModalZipContainer> = props => {
  const { active, children } = props;
  const ModalLayerWithFocusLock: any = withFocusLock(ModalLayer);

  return (
    <LayerWrapper>
      {active
        ? [
            <ShimLayer key="shim" />,
            <ModalLayerWithFocusLock
              key="Modal-layer"
              active={active}
              ariaLabelledBy="Input_zip_modal"
              maxWidth="1400px"
              hideCloseIcon
            >
              <StyledContainer>{children}</StyledContainer>
            </ModalLayerWithFocusLock>,
          ]
        : undefined}
    </LayerWrapper>
  );
};
