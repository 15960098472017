export interface MappedTrimNameModel {
  [key: string]: Trims;
}

interface Trims {
  readonly trim: string;
  readonly trimName: string;
}

export const localMappedTrimName: MappedTrimNameModel = {
  '2019-allnewjetta-sat': {
    trim: '2019-allnewjetta-sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-jetta-seat': {
    trim: '2019-jetta-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-jetta-selat': {
    trim: '2019-jetta-selat',
    trimName: 'SEL - Automatic Transmission'
  },
  '2019-jetta-selpat': {
    trim: '2019-jetta-selpat',
    trimName: 'SEL Premium - Automatic Transmission'
  },
  '2019-jetta-rlat': {
    trim: '2019-jetta-rlat',
    trimName: 'R-Line - Automatic Transmission'
  },
  '2019-allnewjetta-smt': {
    trim: '2019-allnewjetta-smt',
    trimName: 'S - Manual Transmission'
  },
  '2019-allnewjetta-seat': {
    trim: '2019-allnewjetta-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-allnewjetta-rlat': {
    trim: '2019-allnewjetta-rlat',
    trimName: 'R-Line - Automatic Transmission'
  },
  '2019-allnewjetta-selat': {
    trim: '2019-allnewjetta-selat',
    trimName: 'SEL - Automatic Transmission'
  },
  '2019-allnewjetta-selpat': {
    trim: '2019-allnewjetta-selpat',
    trimName: 'SEL Premium - Automatic Transmission'
  },
  '2019-tiguan-sat': {
    trim: '2019-tiguan-sat',
    trimName: '2.0T S - Automatic Transmission'
  },
  '2019-tiguan-seat': {
    trim: '2019-tiguan-seat',
    trimName: '2.0T SE - Automatic Transmission'
  },
  '2019-tiguan-s4mat': {
    trim: '2019-tiguan-s4mat',
    trimName: '2.0T S with 4MOTION® - Automatic Transmission'
  },
  '2019-tiguan-se4mat': {
    trim: '2019-tiguan-se4mat',
    trimName: '2.0T SE with 4MOTION® - Automatic Transmission'
  },
  '2019-beetleconvertible-sat': {
    trim: '2019-beetleconvertible-sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-beetleconvertible-seat': {
    trim: '2019-beetleconvertible-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-beetleconvertible-finaleditionseat': {
    trim: '2019-beetleconvertible-finaleditionseat',
    trimName: 'Final Edition SE - Automatic Transmission'
  },
  '2019-beetleconvertible-finaleditionselat': {
    trim: '2019-beetleconvertible-finaleditionselat',
    trimName: 'Final Edition SEL - Automatic Transmission'
  },
  '2019-jetta-smt': {
    trim: '2019-jetta-smt',
    trimName: 'S - Manual Transmission'
  },
  '2019-jetta-sat': {
    trim: '2019-jetta-sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-atlas-20sat': {
    trim: '2019-atlas-20sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-atlas-v6s4mat': {
    trim: '2019-atlas-v6s4mat',
    trimName: 'V6 S with 4MOTION® - Automatic Transmission'
  },
  '2019-atlas-v6se4mat': {
    trim: '2019-atlas-v6se4mat',
    trimName: 'V6 SE with 4MOTION® - Automatic Transmission'
  },
  '2019-atlas-v6seat': {
    trim: '2019-atlas-v6seat',
    trimName: 'V6 SE - Automatic Transmission'
  },
  '2019-atlas-v6sel4mat': {
    trim: '2019-atlas-v6sel4mat',
    trimName: 'V6 SEL with 4MOTION® - Automatic Transmission'
  },
  '2019-atlas-v6selat': {
    trim: '2019-atlas-v6selat',
    trimName: 'V6 SEL - Automatic Transmission'
  },
  '2019-atlas-v6selp4mat': {
    trim: '2019-atlas-v6selp4mat',
    trimName: 'V6 SEL Premium with 4MOTION® - Automatic Transmission'
  },
  '2019-atlas-v6setech4mat': {
    trim: '2019-atlas-v6setech4mat',
    trimName: 'V6 SE Tech with 4MOTION® - Automatic Transmission'
  },
  '2019-atlas-v6setechat': {
    trim: '2019-atlas-v6setechat',
    trimName: 'V6 SE Tech - Automatic Transmission'
  },
  '2019-atlas-v6setechrlat': {
    trim: '2019-atlas-v6setechrlat',
    trimName: 'V6 SE Tech R-Line - Automatic Transmission'
  },
  '2019-passat-wolfsburgat': {
    trim: '2019-passat-wolfsburgat',
    trimName: '2.0T Wolfsburg - Automatic Transmission'
  },
  '2019-passat-serlineat': {
    trim: '2019-passat-serlineat',
    trimName: '2.0T SE R-Line - Automatic Transmission'
  },
  '2019-tiguan-selat': {
    trim: '2019-tiguan-selat',
    trimName: '2.0T SEL - Automatic Transmission'
  },
  '2019-tiguan-selp4mat': {
    trim: '2019-tiguan-selp4mat',
    trimName: '2.0T SEL Premium with 4MOTION® - Automatic Transmission'
  },
  '2019-gti-smt': {
    trim: '2019-gti-smt',
    trimName: 'S - Manual Transmission'
  },
  '2019-gti-sat': {
    trim: '2019-gti-sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-gti-semt': {
    trim: '2019-gti-semt',
    trimName: 'SE - Manual Transmission'
  },
  '2019-gti-seat': {
    trim: '2019-gti-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-gti-autobahnmt': {
    trim: '2019-gti-autobahnmt',
    trimName: 'Autobahn - Manual Transmission'
  },
  '2019-gti-autobahnat': {
    trim: '2019-gti-autobahnat',
    trimName: 'Autobahn - Automatic Transmission'
  },
  '2019-gti-rabbitat': {
    trim: '2019-gti-rabbitat',
    trimName: 'Rabbit Edition - Automatic Transmission'
  },
  '2019-gti-rabbitmt': {
    trim: '2019-gti-rabbitmt',
    trimName: 'Rabbit Edition - Manual Transmission'
  },
  '2019-gsw-14sat': {
    trim: '2019-gsw-14sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-gsw-14smt': {
    trim: '2019-gsw-14smt',
    trimName: 'S - Manual Transmission'
  },
  '2019-gsw-18s4mat': {
    trim: '2019-gsw-18s4mat',
    trimName: 'S with 4MOTION® - Automatic Transmission'
  },
  '2019-gsw-18s4mmt': {
    trim: '2019-gsw-18s4mmt',
    trimName: 'S with 4MOTION® - Manual Transmission'
  },
  '2019-gsw-14seat': {
    trim: '2019-gsw-14seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-egolf-seat': {
    trim: '2019-egolf-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-golfr-dccnavat': {
    trim: '2019-golfr-dccnavat',
    trimName: 'with DCC® and Navigation - Automatic Transmission'
  },
  '2019-golfr-dccnavmt': {
    trim: '2019-golfr-dccnavmt',
    trimName: 'with DCC® and Navigation - Manual Transmission'
  },
  '2019-beetle-sat': {
    trim: '2019-beetle-sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-beetle-seat': {
    trim: '2019-beetle-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-beetle-finaleditionseat': {
    trim: '2019-beetle-finaleditionseat',
    trimName: 'Final Edition SE - Automatic Transmission'
  },
  '2019-beetle-finaleditionselat': {
    trim: '2019-beetle-finaleditionselat',
    trimName: 'Final Edition SEL - Automatic Transmission'
  },
  '2019-alltrack-18sat': {
    trim: '2019-alltrack-18sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-alltrack-18smt': {
    trim: '2019-alltrack-18smt',
    trimName: 'S - Manual Transmission'
  },
  '2019-alltrack-18seat': {
    trim: '2019-alltrack-18seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-alltrack-18semt': {
    trim: '2019-alltrack-18semt',
    trimName: 'SE - Manual Transmission'
  },
  '2019-alltrack-18selmt': {
    trim: '2019-alltrack-18selmt',
    trimName: 'SEL - Manual Transmission'
  },
  '2019-alltrack-18selat': {
    trim: '2019-alltrack-18selat',
    trimName: 'SEL - Automatic Transmission'
  },
  '2019-golf-14sat': {
    trim: '2019-golf-14sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-golf-14smt': {
    trim: '2019-golf-14smt',
    trimName: 'S - Manual Transmission'
  },
  '2019-golf-14seat': {
    trim: '2019-golf-14seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-golf-14semt': {
    trim: '2019-golf-14semt',
    trimName: 'SE - Manual Transmission'
  },
  '2019-golfgti-smt': {
    trim: '2019-golfgti-smt',
    trimName: 'S - Manual Transmission'
  },
  '2019-golfgti-sat': {
    trim: '2019-golfgti-sat',
    trimName: 'S - Automatic Transmission'
  },
  '2019-golfgti-semt': {
    trim: '2019-golfgti-semt',
    trimName: 'SE - Manual Transmission'
  },
  '2019-golfgti-seat': {
    trim: '2019-golfgti-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2019-golfgti-autobahnmt': {
    trim: '2019-golfgti-autobahnmt',
    trimName: 'Autobahn - Manual Transmission'
  },
  '2019-golfgti-autobahnat': {
    trim: '2019-golfgti-autobahnat',
    trimName: 'Autobahn - Automatic Transmission'
  },
  '2019-golfgti-rabbitat': {
    trim: '2019-golfgti-rabbitat',
    trimName: 'Rabbit - Automatic Transmission'
  },
  '2019-golfgti-rabbitmt': {
    trim: '2019-golfgti-rabbitmt',
    trimName: 'Rabbit - Manual Transmission'
  },
  '2019-atlas-v6setechrl4mat': {
    trim: '2019-atlas-v6setechrl4mat',
    trimName: 'V6 SE Tech R-Line with 4MOTION® - Automatic Transmission'
  },
  '2019-jettagli-sat': {
    trim: '2019-jettagli-sat',
    trimName: '2.0T S - Automatic Transmission'
  },
  '2019-arteon-se4mat': {
    trim: '2019-arteon-se4mat',
    trimName: '2.0T SE with 4MOTION® - Automatic Transmission'
  },
  '2019-tiguan-selrlbl4mat': {
    trim: '2019-tiguan-selrlbl4mat',
    trimName: '2.0T SEL Black R-Line with 4MOTION® - Automatic Transmission'
  },
  '2019-tiguan-selrl4mat': {
    trim: '2019-tiguan-selrl4mat',
    trimName: '2.0T SEL R-Line with 4MOTION® - Automatic Transmission'
  },
  '2019-tiguan-selprl4mat': {
    trim: '2019-tiguan-selprl4mat',
    trimName: '2.0T SEL Premium R-Line with 4MOTION® - Automatic Transmission'
  },
  '2019-atlas-v6selrl4mat': {
    trim: '2019-atlas-v6selrl4mat',
    trimName: 'V6 SEL R-Line with 4MOTION® - Automatic Transmission'
  },
  '2019-atlas-20seat': {
    trim: '2019-atlas-20seat',
    trimName: '2.0 SE - Automatic Transmission'
  },
  '2020-tiguan-sat': {
    trim: '2020-tiguan-sat',
    trimName: 'S - Automatic Transmission'
  },
  '2020-tiguan-s4mat': {
    trim: '2020-tiguan-s4mat',
    trimName: 'S with 4MOTION - Automatic Transmission'
  },
  '2020-tiguan-seat': {
    trim: '2020-tiguan-seat',
    trimName: 'SE - Automatic Transmission'
  },
  '2020-tiguan-se4mat': {
    trim: '2020-tiguan-se4mat',
    trimName: 'SE with 4MOTION - Automatic Transmission'
  },
  '2020-tiguan-selat': {
    trim: '2020-tiguan-selat',
    trimName: 'SEL - Automatic Transmission'
  },
  '2020-tiguan-sel4mat': {
    trim: '2020-tiguan-sel4mat',
    trimName: 'SEL with 4MOTION - Automatic Transmission'
  },
  '2020-tiguan-ser-lb4mat': {
    trim: '2020-tiguan-ser-lb4mat',
    trimName: 'SE R-Line Black Package with 4MOTION - Automatic Transmission'
  },
  '2019-jettagli-35theditionat': {
    trim: '2019-jettagli-35theditionat',
    trimName: '2.0T 35th Anniversary Edition - Automatic Transmission'
  },
  '2019-arteon-seat': {
    trim: '2019-arteon-seat',
    trimName: '2.0T SE - Automatic Transmission'
  },
  '2020-passat-seat': {
    trim: '2020-passat-seat',
    trimName: '2.0T SE'
  },
  '2020-passat-sat': {
    trim: '2020-passat-sat',
    trimName: '2.0T SEL'
  },
  '2019-egolf-selpat': {
    trim: '2019-egolf-selpat',
    trimName: 'SEL Premium'
  },
  '2020-jetta-smt': {
    trim: '2020-jetta-smt',
    trimName: '1.4T S'
  },
  '2020-jetta-all': {
    trim: '2020-jetta-all',
    trimName: 'ALL'
  },
  '2020-jetta-sat': {
    trim: '2020-jetta-sat',
    trimName: '1.4T S'
  },
  '2020-jetta-seulevat': {
    trim: '2020-jetta-seulevat',
    trimName: '1.4T SE ULEV'
  },
  '2020-jetta-seat': {
    trim: '2020-jetta-seat',
    trimName: '1.4T SE'
  },
  '2020-jetta-selat': {
    trim: '2020-jetta-selat',
    trimName: '1.4T SEL'
  },
  '2020-jetta-selulevat': {
    trim: '2020-jetta-selulevat',
    trimName: '1.4T SEL ULEV'
  },
  '2020-jetta-selpulevat': {
    trim: '2020-jetta-selpulevat',
    trimName: '1.4T SEL Premium ULEV'
  },
  '2020-jetta-selpat': {
    trim: '2020-jetta-selpat',
    trimName: '1.4T SEL Premium'
  },
  '2020-jetta-rlmt': {
    trim: '2020-jetta-rlmt',
    trimName: '1.4T R-Line'
  },
  '2020-jetta-rlat': {
    trim: '2020-jetta-rlat',
    trimName: '1.4T R-Line'
  },
  '2020-jetta-rlulevat': {
    trim: '2020-jetta-rlulevat',
    trimName: '1.4T R-Line ULEV'
  },
  '2020-jetta-sulevat': {
    trim: '2020-jetta-sulevat',
    trimName: '1.4T S ULEV'
  },
  '2020-acs-all': {
    trim: '2020-acs-all',
    trimName: 'ALL'
  },
  '2020-acs-sat': {
    trim: '2020-acs-sat',
    trimName: 'S'
  },
  '2020-acs-s4mat': {
    trim: '2020-acs-s4mat',
    trimName: 'S with 4MOTION'
  },
  '2020-acs-seat': {
    trim: '2020-acs-seat',
    trimName: 'SE'
  },
  '2020-acs-se4mat': {
    trim: '2020-acs-se4mat',
    trimName: 'SE with 4MOTION'
  },
  '2020-acs-sewtat': {
    trim: '2020-acs-sewtat',
    trimName: 'SE Tech'
  },
  '2020-acs-sewt4mat': {
    trim: '2020-acs-sewt4mat',
    trimName: 'SE Tech with 4MOTION'
  },
  '2020-acs-sel4mat': {
    trim: '2020-acs-sel4mat',
    trimName: 'SEL with 4MOTION'
  },
  '2020-acs-selprl4m': {
    trim: '2020-acs-selprl4m',
    trimName: 'SEL Premium R-Line with 4MOTION'
  },
  '2020-acs-v6sewt4mat': {
    trim: '2020-acs-v6sewt4mat',
    trimName: 'V6 SE Tech with 4Motion'
  },
  '2020-acs-v6sel4mat': {
    trim: '2020-acs-v6sel4mat',
    trimName: 'V6 SEL with 4MOTION'
  },
  '2020-acs-v6selrl4mat': {
    trim: '2020-acs-v6selrl4mat',
    trimName: 'V6 SEL R-Line with 4Motion'
  },
  '2020-acs-v6selprl4m': {
    trim: '2020-acs-v6selprl4m',
    trimName: 'V6 SEL Premium R-Line with 4Motion'
  },
  '2020-atlas-sat': {
    trim: '2020-atlas-sat',
    trimName: 'S'
  },
  '2020-atlas-v6s4mat': {
    trim: '2020-atlas-v6s4mat',
    trimName: 'V6 S with 4MOTION'
  },
  '2020-golf-tsimt': {
    trim: '2020-golf-tsimt',
    trimName: 'TSI'
  },
  '2020-golfgti-smt': {
    trim: '2020-golfgti-smt',
    trimName: 'S'
  },
  '2020-jettagli-smt': {
    trim: '2020-jettagli-smt',
    trimName: '2.0T S'
  },
  '2019-jettagli-smt': {
    trim: '2019-jettagli-smt',
    trimName: '2.0T S'
  },
  '2021-id4-1steditionat': {
    trim: '1steditionat',
    trimName: '1st EDITION'
  },
  '2021-id4-proat': {
    trim: 'proat',
    trimName: 'PRO'
  },
  '2021-id4-awdproat': {
    trim: 'awdproat',
    trimName: 'AWD PRO'
  },
  '2021-id4-sproat': {
    trim: 'sproat',
    trimName: 'PRO S'
  },
  '2021-id4-sawdproat': {
    trim: 'sawdproat',
    trimName: 'AWD PRO S'
  },
  '2022-taos-sat': {
    trim: 'sat',
    trimName: 'S'
  },
  '2022-taos-s4mat': {
    trim: 's4mat',
    trimName: 'S with 4MOTION'
  },
  '2022-taos-seat': {
    trim: 'seat',
    trimName: 'SE'
  },
  '2022-taos-se4mat': {
    trim: 'se4mat',
    trimName: 'SE with 4MOTION'
  },
  '2022-taos-selat': {
    trim: 'selat',
    trimName: 'SEL'
  },
  '2022-taos-sel4mat': {
    trim: 'sel4mat',
    trimName: 'SEL with 4MOTION'
  }
};
