import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledModalFormWrapper: any = styled.div`
  padding: 20px;
  padding-top: 45px;
  @media (min-width: ${Breakpoints.b960}px) {
    padding: 80px 70px;
  }
  z-index: 999;
`;

export const ModalFormWrapper: any = styled.div`
  position: fixed;
  z-index: 999;
`;
