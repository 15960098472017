import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledDealerWrapper: any = styled.div`
  margin-top: ${(props: any) => props.theme.size.dynamic050};
  @media (min-width: ${Breakpoints.b960}px) {
    margin-top: ${(props: any) => props.theme.size.dynamic0050};
  }
`;

export const StyledDealerInformationWrapper: any = styled.div`
  width: 100%;
  margin-bottom: 50px;

  @media (min-width: ${Breakpoints.b960}px) {
    width: 60%;
  }
`;

export const StyledDealerNameContainer: any = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

export const StyledLinkWrapper: any = styled.div`
  margin-top: 0;
`;
