import React from 'react';

import {useFeatureServices} from './use-feature-services';

import {StoreModel} from '../hooks-store/typings/store';

import {useStore} from '../hooks-store/store';

import _ from 'lodash';
import {AppConstants} from '../utils/app-constants';

export interface FeatureAppTrackingManager {
  trackInventoryGlobalEvent(
    action: string,
    state?: StoreModel,
    trackEvent?: FeatureAppTrackingEvent
  ): void;

  trackInventorySearchZip(zip: string);

  trackInternalNavigation(url: string, targetText: string): void;
}

export interface TrackingEvent {
  readonly [key: string]: object;
}

type Brand = 'passenger' | 'commercial';

export interface FeatureAppTrackingEvent {
  eventInfo?: {
    contentId?: string;
    pageCategory?: string;
    sectionName?: string;
    templateType?: string;
    brand?: Brand;
    contentLabels?: [];
    pageName?: string;
  };

  environment?: {
    featureAppIdAppVersion?: string;
    region?: string;
  };

  user?: {
    loginStatus?: boolean;
    locationZip?: string;
    locationLatitude?: string;
    locationLongitude?: string;
    loginId?: string;
  };
  content?: {
    Asset?: [];
    Type?: string;
    Name?: string;
    ItemCount?: number;
    ItemPosition?: string;
  };
  personalization?: {
    status?: boolean;
    moduleId?: string;
    personalizable?: boolean;
    placeholderId?: string;
    contentId?: string;
    activityId?: string;
    activityName?: string;
    experienceId?: string;
    experienceName?: string;
    offerId?: string;
    offerName?: string;
  };

  configuration?: {
    BodyTypeName?: string;
    CarlineId?: string | string[];
    CarlineName?: string | string[];
    CarlineIsRecommendation?: string;
    ModelName?: string | string[];
    SalesgroupId?: string | string[];
    SalesgroupName?: string | string[];
    SalesgroupIsRecommendation?: string;
    EquipmentlineName?: string;
    EquipmentlineIsRecommendation?: string;
    ModelId?: string | string[];
    ModelTrimFeatures?: string;
    ModelYear?: string;
    WheelsAdditionalExternalRollingNoise?: string;
    PriceType?: string;
    PriceTypeParameter?: [];
    PriceRateCurrency?: string;
    PriceRateType?: string;
    PriceRateBasic?: string;
    PriceRateAdditional?: string;
    PriceRateTotal?: string;
    PriceTotalCurrency?: string;
    PriceTotalBasic?: string;
    PriceTotalAdditional?: string;
    PriceTotalTotal?: string;
    PriceCampaignID?: string;
    PriceCampaignDescription?: string;
    PriceCampaignValue?: number[];
    vehicleidentificationnumber?: string;
  };

  partner?: {
    informationBnr?: string;
    informationName?: string;
    informationDepartment?: string;
    informationZIP?: string;
    informationDistance?: number;
    SearchNumberOfResults?: number;
    onlinePaymentOption?: boolean;
    appointmentSystemName?: string;
    appointmentSystemUtilization?: boolean;
  };

  form?: {
    url?: string;
    name?: string;
    FormType?: string;
    FormTypeMultiple?: [];
    FormName?: string;
    NumberOfAppointments?: number;
    Appointments?: [];
    FormFieldName?: string;
    TextFieldValue?: [];
    PrefContactChannels?: string;
    FormFields?: [];
    ErrorFormFields?: object;
    ErrorAdressValidationFields?: [];
    FormVersion?: string;
    MarketingConsent?: [];
    OpportunityUID?: string;
    orderId?: string;
    LeadID?: string;
    NewsletterType?: [];
    FormStart?: boolean;
    CycleID?: string;
    Questionnaire?: [];
  };

  process?: {
    Name?: string;
    StepName?: string;
    StepNumber?: string;
    Components?: [];
  };

  link?: {
    url?: string;
    name?: string;
    newTab?: boolean;
  };

  error?: {
    code?: string;
    message?: string;
    referringUrl?: string;
  };

  video?: {
    playerName?: string;
    title?: string;
    id?: string;
    platform?: string;
    url?: string;
    milestone?: number;
    timeplayed?: number;
    length?: number;
  };

  filter?: {
    results?: [];
    numberOfResults?: number;
    filterlist?: any[];
    sortOrder?: any[];
    type?: string;
    name?: string;
    values?: any[];
    lowerbound?: string;
    upperbound?: string;
  };
  product?: {
    List?: any;
  };
  ownership?: {
    vehicle?: any;
  };
}

const defaultTrackingEvent: FeatureAppTrackingEvent = {
  eventInfo: {
    brand: 'passenger'
  },

  environment: {
    featureAppIdAppVersion: process.env.APPVERSION
  },

  user: undefined,
  content: undefined,
  personalization: undefined,
  configuration: undefined,
  partner: undefined,
  form: undefined,
  process: undefined,
  link: undefined,
  error: undefined,
  video: undefined,
  filter: undefined,
  product: undefined,
  ownership: undefined
};

export function getDefaultTrackingEvent() {
  return _.cloneDeep(defaultTrackingEvent);
}

function buildTrackingEvent(
  model: StoreModel,
  cusTrackEvent?: FeatureAppTrackingEvent
): FeatureAppTrackingEvent {
  let trackingEvent: FeatureAppTrackingEvent;

  if (cusTrackEvent) {
    trackingEvent = _.cloneDeep(cusTrackEvent);
  } else {
    trackingEvent = _.cloneDeep(defaultTrackingEvent);
  }

  if (trackingEvent.eventInfo && !cusTrackEvent?.eventInfo?.sectionName) {
    trackingEvent.eventInfo.sectionName = model.tier2
      ? 'Featured Offers'
      : model.pageName === AppConstants.Tier1SimplifiedVersion
      ? 'Offers'
      : undefined;
  }

  // if (trackingEvent.eventInfo && !cusTrackEvent?.eventInfo?.pageName) {
  //   trackingEvent.eventInfo.pageName = model.tier2
  //     ? 'Dealers'
  //     : model.pageName === AppConstants.Tier1SimplifiedVersion
  //     ? undefined
  //     : 'Offers';
  // }
  if (trackingEvent.partner) {
    trackingEvent.partner.SearchNumberOfResults = undefined;
  }

  // if(model.zip && trackingEvent.user){
  //   trackingEvent.user.locationZip = model.zip
  // }

  // let numberOfResults: number = 0;
  // if (model.filteredOffers) {
  //   numberOfResults = model.filteredOffers.reduce(
  //     (total, offers) => total + offers.offers.length,
  //     0
  //   );
  // }
  // if (model.filteredOffers && model.filterAttributes && trackingEvent.filter) {
  //   trackingEvent.filter.numberOfResults = numberOfResults;
  //   trackingEvent.filter.filterlist = model.filterAttributes;
  // }

  // if(trackingEvent.configuration && !trackingEvent.configuration.CarlineId && model.carConfiguration && model.carConfiguration.carlineId){
  //   trackingEvent.configuration.CarlineId = model.carConfiguration.carlineId
  // }
  // if(trackingEvent.configuration  && !trackingEvent.configuration.SalesgroupName && model.carConfiguration && model.carConfiguration.trimName){
  //   trackingEvent.configuration.SalesgroupName = model.carConfiguration.trimName
  // }

  // if(trackingEvent.configuration && model.inventoryDataFilterModel.length > 0){
  //   trackingEvent.configuration.ModelName = model.inventoryDataFilterModel[0].model
  //   trackingEvent.configuration.PriceTotalTotal = model.inventoryDataFilterModel[0].msrp
  //   trackingEvent.configuration.vehicleidentificationnumber = model.inventoryDataFilterModel[0].vin
  // }

  // if(trackingEvent.partner && model.dealer){
  //     const name = cusTrackEvent?.partner?.informationName || model.dealer.dealername || undefined;
  //     const distance = cusTrackEvent?.partner?.informationDistance || model.dealer.distance || undefined;
  //     const bnr = cusTrackEvent?.partner?.informationBnr || model.dealer.dealerid || undefined;
  //     const postalCode = cusTrackEvent?.partner?.informationZIP || model.dealer.postalcode || undefined;

  //     trackingEvent.partner.informationBnr = bnr;
  //     trackingEvent.partner.informationName = name;
  //     trackingEvent.partner.informationDistance = distance;
  //     trackingEvent.partner.informationZIP = postalCode;
  //     trackingEvent.partner.SearchNumberOfResults = numberOfResults || undefined;
  // }

  return trackingEvent;
}

export function getTrackEventClone(): FeatureAppTrackingEvent {
  return _.cloneDeep(defaultTrackingEvent);
}

export function useTrackingManager(): FeatureAppTrackingManager {
  const {tracking: trackingManager} = useFeatureServices();
  //const {currentLevel} = React.useContext(TrackingContext);
  //const {mode} = useConfig();
  const [store] = useStore();

  return React.useMemo((): FeatureAppTrackingManager => {
    function track(action: string, event: FeatureAppTrackingEvent): void {
      if (trackingManager) {
        trackingManager.track(action, (event as unknown) as TrackingEvent);
      }
    }

    function trackNavigation(
      action: string,
      url: string,
      targetText: string
    ): void {
      track(action, {
        ...defaultTrackingEvent,
        link: {url, name: targetText}
      });
    }

    return {
      trackInventoryGlobalEvent(
        event: string,
        state?: StoreModel,
        trackEvent?: FeatureAppTrackingEvent
      ): void {
        let curState = store;
        if (state) {
          curState = state;
        }
        track(event, buildTrackingEvent(curState, trackEvent));
      },

      trackInternalNavigation(url: string, targetText: string): void {
        trackNavigation(
          'VWBasic_InventoryInternalNavigation_Click',
          url,
          targetText
        );
      },

      trackInventorySearchZip(zip: string): void {
        track('VWBasic_FilterSearchButton_Click', {
          ...defaultTrackingEvent,
          partner: {
            informationZIP: zip
          }
        });
      }
    };
  }, [trackingManager]);
}
