import {
  Breakpoints,
  TextAlignment,
  TextAppearance,
  TextCustomProps,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';
import Brochure from '../icons/brochure';
import Calculator from '../icons/calculator';
import CarDocument from '../icons/car-document';
import Chat from '../icons/chat';
import ContactDealer from '../icons/contact-dealer';

export const stepIconMap = {
  'contact-dealer': ContactDealer,
  'chat': Chat,
  'car-document': CarDocument,
  'calculator': Calculator,
  'brochure': Brochure,
};

export const titleTextStyle = {
  [Breakpoints.default]: {
    fontWeight: TextWeight.bold,
    textAlign: TextAlignment.left,
    fontSize: TextSize.TextSize24,
    lineHeight: TextLineHeight.TextLineHeight36,
  },
  [Breakpoints.b560]: {
    fontSize: TextSize.TextSize28,
  },
  [Breakpoints.b1280]: {
    fontSize: TextSize.TextSize32,
    lineHeight: TextLineHeight.TextLineHeight36,
  },
};

export const cardSubtitleTextStyle: TextCustomProps = {
  appearance: TextAppearance.headline100,
  tag: TextTag.h3,
  style: {
    [Breakpoints.default]: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize24,
      lineHeight: TextLineHeight.TextLineHeight36,
    },
    [Breakpoints.b960]: {
      lineHeight: TextLineHeight.TextLineHeight28,
    },
  },
};

export const dealerCTATextStyle = {
  appearance: TextAppearance.copy100,
  tag: TextTag.span,
  style: {
    fontSize: TextSize.TextSize16,
    lineHeight: TextLineHeight.TextLineHeight20,
  },
};

export const cancelButtonStyle = {
  appearance: TextAppearance.copy200,
  tag: TextTag.span,
  style: {
    fontSize: TextSize.TextSize16,
    lineHeight: TextLineHeight.TextLineHeight24,
  },
};
