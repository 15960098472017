import {ServerRequestV1} from '@feature-hub/server-request';
import {
  ServiceConfigProviderV1,
  ServiceConfigurationV1
} from '@volkswagen-onehub/service-config-provider';



function getServiceOrigin(
  serviceConfig: ServiceConfigurationV1,
  serverRequest: ServerRequestV1 | undefined
): string {
  if (serviceConfig.urlOrigin) {
    return serviceConfig.urlOrigin;
  }

  if (serverRequest) {
    return new URL(serverRequest.url).origin;
  }

  return window.location.origin;
}

export function getStringifiedServiceConfigsServiceConfig(
  serviceConfigProvider: ServiceConfigProviderV1,
  serverRequest?: ServerRequestV1
): string | undefined {
  const serviceConfigsServiceConfig =
    serviceConfigProvider.configs['modelsConfig'];

  if (!serviceConfigsServiceConfig) {
    return undefined;
  }

  const urlOrigin = getServiceOrigin(
    serviceConfigsServiceConfig,
    serverRequest
  );

  const {credentials, urlPath} = serviceConfigsServiceConfig;

  return JSON.stringify({credentials, urlPath, urlOrigin});
}
