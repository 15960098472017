import {filterOrderList} from '..';

export const localFilterOrder: filterOrderList = [
  'All Models',
  'Taos',
  'ID.4',
  'Atlas',
  'Atlas Cross Sport',
  'Tiguan',
  'Jetta',
  'Jetta GLI',
  'Passat',
  'Arteon',
  'Golf',
  'Golf GTI',
  'e-Golf'
];
