import React from 'react';

import { AppConstants } from '../../../utils/app-constants';
import { ModelOfferSection } from '../model-offer-section';
import { OfferInformation } from '../../../hooks-store/typings/incentive-store';
import { useStore } from '../../../hooks-store/store';

export function OffersContainer(): JSX.Element | null {
  const [store] = useStore();

  return (
    <>
      {store.filteredOffers &&
        store.filteredOffers.map((offerInformation: OfferInformation) => (
          <ModelOfferSection
            key={offerInformation.slug}
            offerInformation={offerInformation}
            dealer={store.dealer}
            isTier1={
              !store.tier2 &&
              store.pageName !== AppConstants.Tier1SimplifiedVersion
            }
          />
        ))}
    </>
  );
}
