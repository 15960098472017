import { Breakpoints, styled } from '@vw-marketing/us-components';

interface ModalHeaderProps {
  isMobile?: boolean;
}

export const StyledContainer = styled.div<ModalHeaderProps>`
  display: flex;
  width: 100%;
  ${props =>
    props.isMobile ? 'flex-direction: column;' : 'flex-direction: row;'}
`;

export const StyledHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
  width: 100%;
  justify-content: space-between;
`;

export const StyledHeaderTitleCTA = styled.div`
  display: flex;
  align-items: center;
  gap: 60px;

  @media (min-width: ${Breakpoints.b560}px) and (max-width: 800px) {
    gap: 10px;
  }
`;

export const StyledInputZipWrapper = styled.div<ModalHeaderProps>`
  ${({ isMobile }: ModalHeaderProps) =>
    `
    display: flex;
    height: 56px;
    padding: 0px 12px;
    justify-content: flex-end;
    align-items: center;
    width: ${isMobile ? '100%' : '300px'};

    @media (min-width: ${Breakpoints.b1280}px) {
      margin-right: 70px;
    }

    ${
      isMobile
        ? `
      > div {
       width: 100%;
      }
    `
        : ''
    }

    & .input-text-field {
      font-size: 20px;
    }
  `}
`;

export const StyledMobileHeader = styled.div`
  display: flex;
  padding: 16px 20px;
  flex-direction: column;
  align-items: flex-end;
  gap: 12px;
  align-self: stretch;
  justify-content: flex-end;
`;
