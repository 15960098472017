import React from 'react';

import { useStore } from '../../../hooks-store/store';
import { OfferInformation } from '../../../hooks-store/typings/incentive-store';
import { useModelsConfig } from '../../../services/use-models-config';
import { AppConstants } from '../../../utils/app-constants';
import { NewModelOfferSection } from '../model-offer-section/offer-card/NewModelOfferSection';

export const NewOffersContainer = (): JSX.Element | null => {
  const [store] = useStore();
  const modelOverview = useModelsConfig();

  const getMSRPValue = (offerInformation: OfferInformation) => {
    for (const carlineId in modelOverview) {
      const carData = modelOverview[carlineId];
      let modelName = carData.name;
      modelName = modelName?.replace(/Golf R/i, 'Golf');

      if (offerInformation.modelDisplayName === modelName) {
        return carData.price ?? '';
      }
    }
    return '';
  };

  return (
    <>
      {store.filteredOffers &&
        store.filteredOffers.map((offerInformation: OfferInformation) => (
          <NewModelOfferSection
            key={offerInformation.slug}
            offerInformation={offerInformation}
            modelMsrp={getMSRPValue(offerInformation)}
            dealer={store.dealer}
            isTier1={
              !store.tier2 &&
              store.pageName !== AppConstants.Tier1SimplifiedVersion
            }
          />
        ))}
    </>
  );
};
