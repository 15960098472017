import React from "react";
import { IconProps } from "./typing";

const ContactDealer = ({ iconSize }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSize}
    height={iconSize}
    viewBox={`0 0 38 45`}
    xmlSpace="preserve"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M34.39 1.556H17.702c-1.268 0-2.296 1.217-2.296 2.719v6.581c-3.71-.025-6.81 3.34-7.111 7.719-.3 4.38 2.299 8.293 5.964 8.978 3.665.686 7.156-2.09 8.01-6.365.852-4.276-1.221-8.608-4.757-9.938V4.275c0-.058.02-.114.056-.153.036-.04.085-.059.134-.053H34.39c.096 0 .174.092.174.206v29.306c0 .118-.075.215-.174.225H28.5l-.317-1.875c-.326-2.03-1.528-3.71-3.166-4.425l-2.85-1.256-.745 2.344 2.977 1.312c.954.425 1.655 1.404 1.853 2.588l1.203 7.256-3.705.075-.49-4.369-2.09.338.458 4.125c-4.164.112-8.344.112-12.508 0l.38-4.125-2.058-.338-.475 4.331c-1.188 0-2.36 0-3.531-.15l1.203-7.218c.204-1.181.902-2.157 1.853-2.588L9.5 28.52l-.776-2.269-2.977 1.331c-1.63.727-2.828 2.4-3.166 4.425L1.36 39.15c-.12.717.03 1.461.41 2.037.38.576.953.928 1.57.963 4.006.225 8.043.337 12.065.337 4.021 0 8.059-.112 12.065-.337.617-.035 1.19-.387 1.57-.963.38-.576.53-1.32.409-2.037l-.475-2.85h5.415c1.268 0 2.296-1.217 2.296-2.719V4.275c0-1.502-1.028-2.719-2.296-2.719zm-14.14 17.55c-.008 3.167-2.18 5.726-4.855 5.719-2.673-.007-4.837-2.577-4.834-5.744.003-3.166 2.171-5.731 4.845-5.731 2.678.01 4.845 2.584 4.845 5.756z"
                transform="translate(-1112.000000, -364.000000) translate(1090.000000, 344.000000) translate(22.000000, 20.000000) translate(0.000000, 0.937500)"
              />
              <path
                d="M24.542 8.194L31.635 8.194 31.635 10.688 24.542 10.688zM24.542 13.894L31.635 13.894 31.635 16.387 24.542 16.387z"
                transform="translate(-1112.000000, -364.000000) translate(1090.000000, 344.000000) translate(22.000000, 20.000000) translate(0.000000, 0.937500)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ContactDealer;
