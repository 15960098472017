import { styled } from '@vw-marketing/us-components';

export const StyledFilterAreaWrapper: any = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledTextWrapper: any = styled.div`
  cursor: pointer;
`;

export const StyledFilterList: any = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
