import {
  Breakpoints,
  BreakpointWrapper,
  ChevronDown,
  Divider,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';
import React, { useState } from 'react';
import { AttributeModel } from '../../../../hooks-store/typings/incentive-store';
import { useStickyHeader } from '../../../../services/use-sticky-header';
import { useTranslations } from '../../../../services/use-translations';
import { FilterAttribute } from '../filter-attribute';
import {
  StyledColorCheckboxes,
  StyledContainer,
  StyledFilterDropdownButton,
  StyledFilterLabelContainer,
  StyledIcon,
  StyledModelsContainer,
  StyledVehicleCategories,
} from './styles';

interface CategorizedModels {
  suvs: AttributeModel[] | undefined;
  sedans: AttributeModel[] | undefined;
  compacts: AttributeModel[] | undefined;
  evs: AttributeModel[] | undefined;
}

interface FilterModelsProps {
  categorizedModels: CategorizedModels;
  appliedFilters?: AttributeModel[];
  modalActive: boolean;
}

const FilterModels: React.FC<FilterModelsProps> = props => {
  const { categorizedModels, appliedFilters, modalActive } = props;
  const [hideOptions, setHideOptions] = useState(false);
  const { isSticky } = useStickyHeader();
  const translations = useTranslations().filtersSection.filtersLabels;
  const isMobile = useIsMobile(Breakpoints.b960);

  return (
    <StyledModelsContainer>
      <StyledContainer>
        <StyledFilterLabelContainer isMobile={isMobile}>
          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.h3}
            style={{
              [Breakpoints.default]: {
                fontWeight: TextWeight.bold,
                fontSize: TextSize.TextSize20,
                lineHeight: TextLineHeight.TextLineHeight24,
              },
            }}
          >
            {translations.model}
          </TextCustom>
          <StyledFilterDropdownButton
            onClick={() => setHideOptions(!hideOptions)}
            className="filter-models-button"
            isSticky={isSticky}
            modalActive={modalActive}
            isActive={hideOptions}
          >
            <BreakpointWrapper max={Breakpoints.b960}>
              <StyledIcon
                className={`filter-models-icon ${hideOptions && 'active'}`}
                hideOptions={hideOptions}
              >
                <div className={`animation ${hideOptions && 'active'}`}>
                  <ChevronDown />
                </div>
              </StyledIcon>
            </BreakpointWrapper>
          </StyledFilterDropdownButton>
        </StyledFilterLabelContainer>
        <BreakpointWrapper min={Breakpoints.b960}>
          <Divider />
        </BreakpointWrapper>
        {!hideOptions && (
          <StyledVehicleCategories>
            {categorizedModels.suvs && categorizedModels.suvs?.length > 0 && (
              <StyledColorCheckboxes isSticky={isSticky}>
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.h3}
                  style={{
                    [Breakpoints.default]: {
                      fontWeight: TextWeight.bold,
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.secondary,
                    },
                  }}
                >
                  {translations.categories.suvs}
                </TextCustom>
                {categorizedModels.suvs?.map(model => (
                  <FilterAttribute
                    key={model.value}
                    attribute={model}
                    appliedFilters={appliedFilters}
                    category={'model'}
                  />
                ))}
              </StyledColorCheckboxes>
            )}
            {categorizedModels.sedans && categorizedModels.sedans?.length > 0 && (
              <StyledColorCheckboxes isSticky={isSticky}>
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.h3}
                  style={{
                    [Breakpoints.default]: {
                      fontWeight: TextWeight.bold,
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.secondary,
                    },
                  }}
                >
                  {translations.categories.sedans}
                </TextCustom>
                {categorizedModels.sedans?.map(model => (
                  <FilterAttribute
                    key={model.value}
                    attribute={model}
                    appliedFilters={appliedFilters}
                    category={'model'}
                  />
                ))}
              </StyledColorCheckboxes>
            )}
            {categorizedModels.compacts &&
              categorizedModels.compacts?.length > 0 && (
                <StyledColorCheckboxes isSticky={isSticky}>
                  <TextCustom
                    appearance={TextAppearance.headline200}
                    tag={TextTag.h3}
                    style={{
                      [Breakpoints.default]: {
                        fontWeight: TextWeight.bold,
                        fontSize: TextSize.TextSize14,
                        lineHeight: TextLineHeight.TextLineHeight18,
                        color: TextColor.secondary,
                      },
                    }}
                  >
                    {translations.categories.compacts}
                  </TextCustom>
                  {categorizedModels.compacts?.map(model => (
                    <FilterAttribute
                      key={model.value}
                      attribute={model}
                      appliedFilters={appliedFilters}
                      category={'model'}
                    />
                  ))}
                </StyledColorCheckboxes>
              )}
            {categorizedModels.evs && categorizedModels.evs?.length > 0 && (
              <StyledColorCheckboxes isSticky={isSticky}>
                <TextCustom
                  appearance={TextAppearance.headline200}
                  tag={TextTag.h3}
                  style={{
                    [Breakpoints.default]: {
                      fontWeight: TextWeight.bold,
                      fontSize: TextSize.TextSize14,
                      lineHeight: TextLineHeight.TextLineHeight18,
                      color: TextColor.secondary,
                    },
                  }}
                >
                  {translations.categories.evs}
                </TextCustom>
                {categorizedModels.evs?.map(model => (
                  <FilterAttribute
                    key={model.value}
                    attribute={model}
                    appliedFilters={appliedFilters}
                    category={'model'}
                  />
                ))}
              </StyledColorCheckboxes>
            )}
          </StyledVehicleCategories>
        )}
      </StyledContainer>
    </StyledModelsContainer>
  );
};

export default FilterModels;
