import {styled} from '@vw-marketing/us-components';

export const DealerDetailWrapper: any = styled.div`
  display: flex;
`;
export const DealerDetailIcon: any = styled.div`
  align-self: baseline;
  margin-right: 11px;
  margin-top: 1px;
`;

export const DealerDetailText: any = styled.div``;

export const StyledIcon: any = styled.div`
  color: ${(props: any) => props.theme.colors.content.invertedOnSurface};
  display: flex;
  width: 100%;
  height: 91%;
  justify-content: flex-start;
  align-items: center;

  & > svg {
    fill: #001e50;
  }
`;
