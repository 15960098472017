import React from 'react';
import { NoDealers } from '../../../components/no-dealers';
import {useStore} from '../../../hooks-store/store';
import {FilterToolbar} from '../filter-toolbar';
import {OffersContainer} from '../offers-container';
import { useIsNationalOffers } from '../../../services/use-is-national-offer';
export function RightContainerSection(): JSX.Element | null {
  const [store] = useStore();
  const isNationalOffer =  useIsNationalOffers();
  return (
    <>
      {!store.dealers.length && !isNationalOffer ? (
        <NoDealers/>
      ) : (
        <>
          <FilterToolbar />
          <OffersContainer />
        </>
      )}
    </>
  );
}
