import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyleCarouselContainer: any = styled.div`
  margin-bottom: 50px;

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 60px;
  }
`;

export const NewStyleCarouselContainer: any = styled.div`
  margin-bottom: 50px;
  width: 100%;
  display: flex;
  flex-flow: row wrap;

  .slick-pagination-bar {
    order: 4;
    margin-top: 10px;
  }

  .slick-carousel {
    order: 3;
    width: 100%;
  }

  .slick-slide {
    & > :first-child {
      width: 100%;
    }
  }

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 60px;
  }
`;
