import {
  ButtonAppearance,
  ButtonCustomLink,
  CCustomerCenter,
  Link,
  ModalContainer,
  PinPlace,
  TextAppearance,
  TextColor,
  TextCustom,
  TextTag,
  TextWeight,
  buttonPaddingGenerator
} from '@vw-marketing/us-components';
import React from 'react';
import DealerInformationDetail from '../../../../../components/dealer-information-detail';
import {DealerModel} from '../../../../../hooks-store/typings/incentive-store';
import {
  DealerInfoWrapper,
  MobileCardContainer,
  MobileModalContainer,
  NameWrapper
} from './styled';
import {useTranslations} from '../../../../../services/use-translations';

interface ModalDealerCardProps {
  dealer?: DealerModel;
  onCloseModal: () => void;
  active: boolean;
  onHandleDealerTracking: (dealer?: DealerModel, name?: string, type?: string) => void;
}

const ModalDealerCard = ({
  dealer,
  onCloseModal,
  active,
  onHandleDealerTracking
}: ModalDealerCardProps): JSX.Element => {
  const translations = useTranslations().tier2.landing.findDealer
    .modelDealerCard;

  return (
    <MobileModalContainer>
      <ModalContainer active={active} onCloseHandler={onCloseModal}>
        <MobileCardContainer>
          <NameWrapper>
            <TextCustom
              appearance={TextAppearance.headline300}
              style={{color: TextColor.inherit, fontWeight: TextWeight.bold}}
              tag={TextTag.h3}
            >
              {translations.dealerDetails}
              <TextCustom
                style={{fontWeight: TextWeight.light}}
                tag={TextTag.h3}
              >
                {dealer?.name}
              </TextCustom>
            </TextCustom>
          </NameWrapper>
          <DealerInfoWrapper>
            <DealerInformationDetail
              Icon={PinPlace}
              label1={dealer?.address1}
              label2={`${dealer?.city}, ${dealer?.state} ${dealer?.postalcode}`}
            />
            <DealerInformationDetail
              Icon={CCustomerCenter}
              label1={
                <Link href={dealer?.mobileTel} isInline>
                  {' '}
                  <TextCustom
                    appearance={TextAppearance.label150}
                    style={{fontWeight: TextWeight.bold}}
                  >
                    {dealer?.prettyTel}{' '}
                  </TextCustom>
                </Link>
              }
            />
          </DealerInfoWrapper>
          <ButtonCustomLink
            appearance={ButtonAppearance.Primary}
            href={dealer?.url}
            padding={buttonPaddingGenerator(0, 28, 28, 28)}
            target="_blank"
            onClick={onHandleDealerTracking.bind(
              null,
              dealer,
              translations.dealerWebsite,
              'button click'
            )}
          >
            <TextCustom appearance={TextAppearance.label150}>
              {translations.dealerWebsite}
            </TextCustom>
          </ButtonCustomLink>
        </MobileCardContainer>
      </ModalContainer>
    </MobileModalContainer>
  );
};

export default ModalDealerCard;
