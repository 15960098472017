import {Breakpoints, styled} from '@vw-marketing/us-components';

interface StyledOfferTypeLabelProps {
  readonly backgroundColor: string;
  readonly textColor: string;
}

interface StyledOpacityWrapperProps {
  isOpacity?: boolean;
}

export const StyledOfferCardWrapper: any = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  height: calc(100% - 15px);
  border: 1px solid #dfe4e8;
  border-top: 1px solid transparent;
  margin-top: 15px;
  position: relative;
`;

export const StyledInformationOfferContainer: any = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export const StyledOfferMicroText: any = styled.div`
  width: 100%;
  margin-bottom: 20px;
  color: #758189;
  & > :not(:last-child) {
    margin-bottom: 20px;
  }
`;

interface StyledButtonWrapperProps {
  oneElement?: boolean;
}

export const StyledButtonWrapper: any = styled.div<StyledButtonWrapperProps>`
  width: 100%;
  padding: 20px 10px;
  display: flex;
  justify-content: ${(props: StyledButtonWrapperProps) =>
    props.oneElement ? 'center' : 'space-between'};
  border-top: 1px solid #dfe4e8;
  & > :first-child {
    margin-right: 10px;
  }
  & > :last-child {
    margin-left: 10px;
  }

  @media (min-width: ${Breakpoints.b1600}px) {
    justify-content: space-around;
    padding: 20px 20px;
  }
  @media (min-width: ${Breakpoints.b1920}px) {
    justify-content: space-around;
    padding: 20px 30px;
  }
`;

export const StyledButtonSection: any = styled.div`
  width: auto;
`;

export const StyledSectionButton: any = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const StyledOfferTitle: any = styled.div`
  min-height: 85px;
  margin-bottom: 15px;
`;

export const StyledOfferSubtitle: any = styled.div`
  margin-bottom: 20px;
`;

export const StyledShowOfferDetails: any = styled.div`
  margin-bottom: 30px;
`;

export const StyledOfferTypeLabelWrapper: any = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
`;

export const StyledOfferTypeLabel: any = styled.div<StyledOfferTypeLabelProps>`
  background-color: ${props => props.backgroundColor};
  color: ${props => props.textColor};
  display: inline-block;
  padding: 2px 10px;
  border-radius: 5px;
`;

export const StyledOpacityWrapper: any = styled.div<StyledOpacityWrapperProps>`
  ${(props: StyledOpacityWrapperProps) =>
    props.isOpacity &&
    ` position: absolute;
    width: calc(100% + 2px);
    height: 100%;
    top: -1px;
    right: -1px;
    background-color: white;
    opacity: 0.5;
    z-index: 1;
  `}
`;

interface StyledBackgroundProps {
  readonly backgroundColor: string;
}

export const StyledBackground: any = styled.div<StyledBackgroundProps>`
  background: ${props => props.backgroundColor || '#ccab0d'};
  position: absolute;
  padding-top: 50%;
  top: 0px;
  left: -1px;
  right: -1px;
`;

interface StyledTextWrapperProps {
  readonly headTextColor: string;
}

export const StyledTextWrapper: any = styled.div<StyledTextWrapperProps>`
  color: ${props => props.headTextColor || '#000'};
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  padding: 10px 10px 0;
`;

export const StyledImagePlaceholder: any = styled.div`
  margin-top: 25%;
`;

export const StyledImageWrapper: any = styled.div`
  position: relative;
  pointer-events: none;

  > img:nth-child(2) {
    transform: scale(1.1);
    margin-top: -32px;
    padding-bottom: 6px;
  }
`;

export const StyledInformationWrapper: any = styled.div`
  width: 100%;
`;

export const StyledHeaderImageWrapper: any = styled.div`
  width: 100%;
`;
