import React from "react";
import { IconProps } from "./typing";

const Brochure = ({ iconSize }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={iconSize}
    height={iconSize}
    viewBox={`0 0 45 45`}
    xmlSpace="preserve"
  >
    <g fill="none" fill-rule="evenodd">
      <g fill="currentColor" fill-rule="nonzero">
        <g>
          <g>
            <path
              d="M15 1.875h26.25v33.75H30v7.5H3.75V9.375H15v-7.5zm15 7.5V33.75h9.375v-30H19.443L30 9.375zM16.876 9.23v.145h9.137l-9.137-4.869V9.23zM5.625 11.25v30h22.5v-30h-22.5zm3.75 17.813v-1.875h15.02v1.875H9.375zm0 3.75v-1.876h15.02v1.875H9.375zm0 3.75v-1.876h11.25v1.876H9.375zm1.378-14.66l4.199-7.867 3.16 5.264 2.812-1.876 2.771 4.62c.168.281.476.456.804.456h.813v1.875H24.5c-.981 0-1.906-.522-2.412-1.365l-1.76-2.935-2.813 1.875-2.466-4.111-2.674 5.002c-.17.278-.34.497-.524.691-.508.536-1.24.843-2.011.843h-.464V22.5h.464c.259 0 .495-.093.65-.256.102-.109.194-.229.264-.341zm12.216-5.028c-.777 0-1.407-.63-1.407-1.406 0-.777.63-1.406 1.407-1.406.776 0 1.406.629 1.406 1.406 0 .777-.63 1.406-1.406 1.406z"
              transform="translate(-835.000000, -364.000000) translate(815.000000, 344.000000) translate(20.000000, 20.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Brochure;
