import {
  Breakpoints,
  ContentLayerV2,
  Disclaimer,
  DisclaimerModal,
  useIsMobile,
} from '@vw-marketing/us-components';
import React from 'react';
import ModalDisclaimerWrapper from '../../components/modal-disclaimer-wrapper';
import { useDisclaimerConfiguration } from '../../hooks/use-disclaimer-configuration';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';
import { DisclaimerKeys, getDisclaimersByPage } from '../../utils/disclaimers';
import { FilterTabs } from '../offers-section/filter/filter-tabs';
import { NewOffersContainer } from '../offers-section/offers-container/NewOffersContainer';
import { ModalHeader } from './modal-header';
import { StyledContentWrapper, StyledModalWrapper } from './styled';
import { useStore } from '../../hooks-store/store';
import { useIsNationalOffers } from '../../services/use-is-national-offer';
import { NoDealers } from '../../components/no-dealers';

interface OffersModalProps {
  modelName: string;
}

export const OffersModal: React.FC<OffersModalProps> = ({ modelName }) => {
  const [store] = useStore();
  const isNationalOffer = useIsNationalOffers();
  const { disclaimers } = useDisclaimerConfiguration();
  const landingDisclaimers = getDisclaimersByPage(
    DisclaimerKeys.LandingKey,
    disclaimers,
  );
  const isMobile = useIsMobile(Breakpoints.b560);

  return (
    <>
     <StyledModalWrapper className="modal-wrapper" isMobile={isMobile}>
        <ModalHeader modelKey={modelName} />
        <FilterTabs />
        <StyledContentWrapper>
          <div>
          {!store.dealers.length && !isNationalOffer ? (
            <NoDealers />
          ) : (
            <NewOffersContainer />
          )}
          </div>
        </StyledContentWrapper>
      </StyledModalWrapper>
      <ModalDisclaimerWrapper>
        <DisclaimerModal disclaimers={landingDisclaimers as Disclaimer[]} />
      </ModalDisclaimerWrapper>
    </>
  );
};
