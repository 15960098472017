import {
  Button,
  ButtonAppearance,
  ClickableIcon,
  Close,
  DisableFocusWrapper,
  Text,
  TextAppearance,
  TextColor
} from '@vw-marketing/us-components';
import React from 'react';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import {useStore} from '../../../../hooks-store/store';
import {useHistory} from '../../../../services/use-history';
import {disabledScroll, scrollToTop} from '../../../../utils/general';
import {FilterSection} from '../filter-section';
import {
  StyledButtonSection,
  StyledButtonWrapper,
  StyledCloseIconWrapper,
  StyledFilterHeader,
  StyledFilterHeaderWrapper,
  StyledFilterSectionWrapper,
  StyledFilterWrapper,
  StyledSectionButton,
  StyledSectionFilter
} from './styled';
import {useTranslations} from '../../../../services/use-translations';
import {
  useTrackingManager,
} from '../../../../context/use-tracking-manager';
import { onHandleBasicAccordionAction } from '../../../../utils/tagging-helpers';

export const FilterSidebar: React.FC = (): JSX.Element => {
  const history = useHistory();
  const translations = useTranslations().offersSection.filter.filterSidebar;
  const [
    store,
    {
      [IncentiveConstants.ClearFilters]: clearFilters,
      [IncentiveConstants.ActivateMobileFilterModal]: activateMobileFilterModal,
      [IncentiveConstants.SetPendingProcessState]: setPendingProcessState
    }
  ] = useStore();

  const trackingManager = useTrackingManager();

  const clearFiltersSelected = () => {
    clearFilters(history, true);
    setTimeout(() => {
      setPendingProcessState(false);
      scrollToTop(500);
    }, 1000);
  };

  /**
   * Trigger tags related to close and open filters accordions
   * @param status 
   * @param filterType 
   */
  const triggerAccordionTag = (status: boolean, filterType: string) => {
    onHandleBasicAccordionAction(status, filterType, trackingManager, store)
  };

  return (
    <StyledFilterWrapper
      filterModalActivated={store.mobileModalFilterActivated}
    >
      <StyledCloseIconWrapper
        filterModalActivated={store.mobileModalFilterActivated}
      >
        <ClickableIcon
          onClick={() => {
            disabledScroll(false);
            activateMobileFilterModal(false);
          }}
        >
          <Close />
        </ClickableIcon>
      </StyledCloseIconWrapper>
      <StyledSectionFilter>
        <StyledFilterHeaderWrapper>
          <StyledFilterHeader>
            <Text appearance={TextAppearance.headline400} bold>
              {translations.filter}
            </Text>
          </StyledFilterHeader>
        </StyledFilterHeaderWrapper>
        <StyledFilterSectionWrapper>
          {store.filterAttributes &&
            store.filterAttributes.length > 0 &&
            store.filterAttributes.map((section: any, index: number) => (
              <FilterSection
                key={section.filterKey}
                {...section}
                appliedFilters={store.appliedFilters}
                shouldStartOpen={index === 0}
                triggerTrackingEvent={triggerAccordionTag}
              />
            ))}
        </StyledFilterSectionWrapper>
      </StyledSectionFilter>
      <StyledSectionButton>
        <StyledButtonWrapper>
          <StyledButtonSection>
            <DisableFocusWrapper>
              <Button
                appearance={ButtonAppearance.Secondary}
                stretchContent
                onClick={() => clearFiltersSelected()}
              >
                <Text
                  color={TextColor.inherit}
                  appearance={TextAppearance.label150}
                >
                  {translations.clearAll}
                </Text>
              </Button>
            </DisableFocusWrapper>
          </StyledButtonSection>

          <StyledButtonSection>
            <DisableFocusWrapper>
              <Button
                appearance={ButtonAppearance.Primary}
                stretchContent
                onClick={() => {
                  disabledScroll(false);
                  activateMobileFilterModal(false);
                }}
              >
                <Text
                  color={TextColor.inherit}
                  appearance={TextAppearance.label150}
                >
                  {translations.showAll}
                </Text>
              </Button>
            </DisableFocusWrapper>
          </StyledButtonSection>
        </StyledButtonWrapper>
      </StyledSectionButton>
    </StyledFilterWrapper>
  );
};
