import {Menu, PinPlace} from '@vw-marketing/us-components';
import React from 'react';
import {ErrorComponent} from '../../../components/error-component';
import InputZip from '../../../components/input-zip';
import {NoDealers} from '../../../components/no-dealers';
import ErrorCodes from '../../../error-handling/error-codes';
import {validateError} from '../../../error-handling/error-utils';
import {useStore} from '../../../hooks-store/store';
import {useFeatureAppConfig} from '../../../services/use-feature-app-config';
import {useTranslations} from '../../../services/use-translations';
import {Masthead} from '../../masthead';
import {SectionWithHeader} from '../../section-with-header';
import {FeaturedOffers} from './featured-offers';
import FindDealer from './find-dealer';
import {MastheadInformation} from './masthead-information';
import {
  LayoutWrapper,
  StyledInputZipContainer,
  StyledInputZipWrapper,
  StyledLandingWrapper
} from './styled';
// Removed the Service Offers
// import {useFeatureServices} from '../../../services/use-feature-services';
// import {navigateTier1} from '../../../utils/general';
// import {NoResults} from '../../no-results';
// import {ServiceOffers} from './service-offers';

export type DealerView = 'map' | 'list';

interface DealerViewState {
  view: DealerView;
  label: {name: string; icon: JSX.Element};
}
const Landing = () => {
  const {faHostServerUrl = ''} = useFeatureAppConfig();

  const store = useStore()[0];
  const translations = useTranslations().tier2.landing;

  // Dealer views labels
  const mapLabel = {name: 'Map view', icon: <PinPlace iconSize={'12'} />};
  const listLabel = {name: 'List view', icon: <Menu variant={'small'} />};

  // Change dealer view false=shows map, true=show Dealer list;
  const [dealerView, setDealerView] = React.useState<DealerViewState>({
    view: 'map',
    label: listLabel
  });

  const [zipCodeError, setZipCodeError] = React.useState(false);

  React.useEffect(() => {
    if (store.zipNoDealers) {
      setZipCodeError(true);
    } else {
      setZipCodeError(false);
    }
  }, [store.zipNoDealers]);

  return (
    <StyledLandingWrapper>
      <Masthead
        customText={
          <MastheadInformation text={store.authoredLmaDetail.vanityName} />
        }
        desktopImg={faHostServerUrl + store.authoredLmaDetail.mastheadImage}
        imageAlt={store.authoredLmaDetail.name}
      />

      <LayoutWrapper>
        <StyledInputZipWrapper>
          <StyledInputZipContainer>
            <InputZip
              placeholder={translations.inputZipPlaceholder}
              label={translations.inputZipLabel}
              errorText={translations.inputZipError}
              altErrortext={translations.noDealersError}
              maxLength={5}
            />
          </StyledInputZipContainer>
        </StyledInputZipWrapper>
        {zipCodeError ? (
          <NoDealers />
        ) : (
          <>
            {/* Featured Offers */}
            <FeaturedOffers
              offersData={store.offersData}
              vanityUrlName={store.authoredLmaDetail.vanityUrlName}
              dealer={store.dealer}
              errorHandling={store.errorHandling}
              modelsConfig={store.modelsConfig}
            />

            {/* Find a Dealer section */}
            {store.errorHandling.length &&
            validateError(
              store.errorHandling,
              ErrorCodes.FetchLocationError
            ) ? (
              <SectionWithHeader title="Find a Dealer">
                <ErrorComponent />
              </SectionWithHeader>
            ) : (
              <SectionWithHeader
                btnLabel={dealerView.label.name}
                iconLabel={dealerView.label.icon}
                buttonType="button"
                title="Find a Dealer"
                btnAction={() => {
                  if (dealerView.view === 'map') {
                    setDealerView({view: 'list', label: mapLabel});
                  } else {
                    setDealerView({view: 'map', label: listLabel});
                  }
                }}
              >
                <FindDealer activeView={dealerView.view} />
              </SectionWithHeader>
            )}

            {/* Removed the Service Offers */}
            {/* Service Offers section */}
            {/* {store.errorHandling.length &&
            validateError(
              store.errorHandling,
              ErrorCodes.FetchServiceOffersError
            ) ? (
              <SectionWithHeader title="Service Offers">
                <ErrorComponent />
              </SectionWithHeader>
            ) : (
              <SectionWithHeader title="Service Offers">
                {store.serviceOffersData.length ? (
                  <ServiceOffers
                    offers={store.serviceOffersData}
                    dealer={store.dealer}
                  />
                ) : (
                  <NoResults
                    header={translations.noResultsHeader}
                    subHeader={translations.noResultsSubheader}
                    firstBtnAction={() => navigateTier1(navigationService)}
                    firstBtnLabel={translations.noResultsBtnLabel1}
                    firstBtnPadding={[12, 20, 30, 30]}
                    secondBtnAction={() =>
                      window.open(store.dealer?.url, '_blank')
                    }
                    secondBtnLabel={translations.noResultsBtnLabel2}
                    secondBtnPadding={[12, 26, 36, 36]}
                  />
                )}
              </SectionWithHeader>
            )} */}
          </>
        )}
      </LayoutWrapper>
    </StyledLandingWrapper>
  );
};

export default Landing;
