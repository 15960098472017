import isBrowser from 'is-browser';
import {fetchModelConfig} from '../fetch-data/fetch-model-config';
import {getStringifiedServiceConfigsServiceConfig} from '../services/get-stringified-service-configs-service-config';
import {logBffLoggerCalls} from '../services/log-bff-debug-logs';
import {FeatureAppModelConfig} from '../typings/general';
import {useFeatureAppConfig} from './use-feature-app-config';
// import {useFeatureAppEnvironment} from './use-feature-app-environment';
import {useFeatureServices} from './use-feature-services';
import {useLogger} from './use-logger';
import {useUniversalEffect} from './use-universal-effect';
import {useUniversalState} from './use-universal-state';

export function useFeatureAppModel(): FeatureAppModelConfig | undefined {
  const [{featureAppModelResult}, dispatch] = useUniversalState();
  //const {baseUrl} = useFeatureAppEnvironment();
  const {mockIds, mocksBaseUrl, faServicesUrl} = useFeatureAppConfig();
  const logger = useLogger();

  const {
    's2:async-ssr-manager': asyncSsrManager,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest
  } = useFeatureServices();

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest
  );

  useUniversalEffect(() => {
    if (featureAppModelResult) {
      if (!isBrowser) {
        return;
      }

      if (
        featureAppModelResult.status === 'failed' &&
        featureAppModelResult.ssr
      ) {
        logger.warn('SOFA SSR error detected, trying to fetch data again.');
      } else {
        return;
      }
    }

    dispatch({type: 'fetchingFeatureAppModel'});

    const fetchModelOverviewPromise = fetchModelConfig({
      serviceConfigsServiceConfig,
      mockIds,
      mocksBaseUrl,
      faServicesUrl
    })
      .then(fetchedModelOverview => {
        dispatch({type: 'fetchedFeatureAppModel', fetchedModelOverview});
      })
      .catch(error => {
        if (error.bffLoggerCalls) {
          logBffLoggerCalls(error.bffLoggerCalls, logger);
        }

        logger.error(error);
        dispatch({type: 'failedToFetchFeatureAppModel', error});
      });

    if (asyncSsrManager) {
      asyncSsrManager.scheduleRerender(fetchModelOverviewPromise);
    }
  }, []);

  return featureAppModelResult?.status === 'loaded'
    ? featureAppModelResult.featureAppModel
    : undefined;
}
