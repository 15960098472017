import {
  BreakpointWrapper,
  Breakpoints,
  CCustomerCenter,
  CInternet,
  DisableFocusWrapper,
  Link,
  PinPlace,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';
import React from 'react';
import { DealerModel } from '../../../../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../../../../utils/app-constants';
import { stringToPhoneFormat } from '../../../../../utils/general';
import DealerInformationDetail from '../../../../dealer-information-detail';
import {
  StyledDealerInfoContainer,
  StyledDisclaimerInformationWrapper,
  StyledDynamicFormHeader,
  StyledLinkWrapper,
} from './styled';

interface CustomFormHeaderProps {
  dealer?: DealerModel;
  formType: string;
  formName: string;
  onHandleDealerUrlTracking: (name: string) => void;
}

const CustomFormHeader: React.FC<CustomFormHeaderProps> = (
  props,
): JSX.Element => {
  const { dealer, formType, formName, onHandleDealerUrlTracking } = props;
  const isOfferDetails = formType === AppConstants.OfferDetailsFormType;
  const isScheduleServiceDetails =
    formType === AppConstants.ScheduleServiceDetailFormType;

  return (
    <StyledDynamicFormHeader>
      <TextCustom
        appearance={TextAppearance.headline200}
        tag={TextTag.h2}
        style={{
          [Breakpoints.default]: {
            fontWeight: TextWeight.bold,
            fontSize: TextSize.TextSize32,
            lineHeight: TextLineHeight.TextLineHeight36,
          },
        }}
      >
        {formName}
      </TextCustom>
      {dealer && (isOfferDetails || isScheduleServiceDetails) && (
        <>
          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.h2}
            style={{
              [Breakpoints.default]: {
                fontSize: TextSize.TextSize32,
                lineHeight: TextLineHeight.TextLineHeight36,
                fontWeight: TextWeight.light,
              },
            }}
          >
            {isOfferDetails ? 'from' : 'at'} {dealer.name}
          </TextCustom>
          <StyledDealerInfoContainer>
            <DealerInformationDetail
              Icon={PinPlace}
              label1={dealer?.address1}
              label2={`${dealer?.city}, ${dealer?.state} ${dealer?.postalcode}`}
            />
            <BreakpointWrapper max={Breakpoints.b560}>
              <DisableFocusWrapper>
                <Link href={dealer ? `tel: ${dealer.phone}` : ''}>
                  <DealerInformationDetail
                    Icon={CCustomerCenter}
                    label1={stringToPhoneFormat(dealer ? dealer.phone : '')}
                  />
                </Link>
              </DisableFocusWrapper>
            </BreakpointWrapper>
            <BreakpointWrapper min={Breakpoints.b560}>
              <DealerInformationDetail
                Icon={CCustomerCenter}
                label1={stringToPhoneFormat(dealer ? dealer.phone : '')}
              />
            </BreakpointWrapper>
            {dealer.url && dealer.url !== '' && (
              <DisableFocusWrapper>
                <DealerInformationDetail
                  Icon={CInternet}
                  textCustom={
                    <StyledLinkWrapper>
                      <TextCustom
                        appearance={TextAppearance.copy100}
                        style={{
                          [Breakpoints.default]: {
                            fontWeight: TextWeight.bold,
                            fontSize: TextSize.TextSize14,
                            lineHeight: TextLineHeight.TextLineHeight22,
                          },
                        }}
                      >
                        <Link
                          href={dealer.url}
                          target="_blank"
                          isInline
                          onClick={onHandleDealerUrlTracking.bind(
                            null,
                            'Visit Dealer Site',
                          )}
                        >
                          Visit Dealer Site
                        </Link>
                      </TextCustom>
                    </StyledLinkWrapper>
                  }
                />
              </DisableFocusWrapper>
            )}
          </StyledDealerInfoContainer>
        </>
      )}
      <StyledDisclaimerInformationWrapper>
        <TextCustom
          appearance={TextAppearance.copy100}
          style={{
            fontSize: TextSize.TextSize16,
            lineHeight: TextLineHeight.TextLineHeight24,
            fontWeight: TextWeight.regular,
          }}
          tag={TextTag.span}
        >
          Be sure to confirm with this dealer which advertised national, state
          or local offers, if any, they are participating in for this vehicle.
        </TextCustom>
      </StyledDisclaimerInformationWrapper>
    </StyledDynamicFormHeader>
  );
};

export default CustomFormHeader;
