import { Breakpoints, styled } from '@vw-marketing/us-components';

interface OffersModalProps {
  isMobile: boolean;
}

export const StyledModalWrapper = styled.div<OffersModalProps>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
  padding: 0px 0px 0px 70px;

  @media (max-width: ${Breakpoints.b560}px) {
    padding: 0;
  }

  ${props =>
    props.isMobile
      ? `
      .details-tab-container {
        margin-left: 22px;
      }
    `
      : ``}
`;

export const StyledContentWrapper = styled.div`
  width: 100%;
  @media (min-width:  ${Breakpoints.b960}px) and (max-width:  ${Breakpoints.b1600}px) {
    width: calc(100% - 330px);
  }

  @media (min-width:  ${Breakpoints.b1600}px) {
    width: calc(100% - 460px);
  }

  @media (min-width: ${Breakpoints.b1920}px) {
    width: calc(100% - 470px);
  }
`;
