import { ModelStructure } from '../typings/general';
import React from 'react';


export const ModelStructureContext = React.createContext<ModelStructure | undefined>(
  undefined
);

export function useModelStructure(): ModelStructure {
    const modelStructure = React.useContext(ModelStructureContext);
  if (!modelStructure) {
    throw new Error('No defined model structure');
  }
  return modelStructure;
}
