import { Breakpoints, SlickSlider } from '@vw-marketing/us-components';
import React from 'react';
import {
  ImageMapModel,
  OfferImagesMapModel,
  localOfferImagesMap,
} from '../../../config/offer-images-map';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../hooks-store/store';
import {
  DealerModel,
  OfferInformation,
  OffersModel,
  TypeMap,
} from '../../../hooks-store/typings/incentive-store';
import { useFeatureServices } from '../../../services/use-feature-services';
import { AppConstants } from '../../../utils/app-constants';
import {
  navigateTier1,
  getOfferImageMapFromModelsConfig,
  navigateTier1Ref,
} from '../../../utils/general';
import { NoResults } from '../../no-results';
import { SectionWithHeader } from '../../section-with-header';
import { OfferCard } from './offer-card';
import { useTranslations } from '../../../services/use-translations';
import { useTrackingManager } from '../../../context/use-tracking-manager';
import { ModelConfig } from '../../../typings/model-config';
import {
  onSlideActionTracking,
  onHandleCarouselLoaded,
  onHandlerBasicVehicleLink,
} from '../../../utils/tagging-helpers';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import { NewOfferCard } from './new-offer-card/NewOfferCard';
import { StyledSliderWrapper } from './styled';

interface LocalOffersProps {
  readonly offersData: TypeMap<OfferInformation>;
  readonly dealer?: DealerModel;
  readonly modelKey: string;
  readonly modelName: string;
  readonly carlineId?: string;
  readonly modelsConfig?: ModelConfig;
}

const slickSettings = {
  responsive: [
    {
      breakpoint: Breakpoints.b1600,
      settings: {
        slidesToShow: 3.5,
        slidesToScroll: Math.floor(3.5),
      },
    },
    {
      breakpoint: Breakpoints.b960,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: Math.floor(2.5),
      },
    },
    {
      breakpoint: Breakpoints.b560,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
  ],
};

export const LocalOffers: React.FC<LocalOffersProps> = React.memo(
  (props): JSX.Element | null => {
    const {
      offersData,
      dealer,
      modelKey,
      modelName,
      modelsConfig,
      carlineId,
    } = props;
    const {
      specialOffersRedesignEnable,
      sofaRedirectPageName,
      sofaRedirectFAName,
    } = useFeatureAppConfig();
    const { 'navigation-service': navigationService } = useFeatureServices();
    const translations = useTranslations().tier1simplifiedVersion.localOffers;
    const trackingManager = useTrackingManager();
    const {
      [IncentiveConstants.SetModalFormData]: setModalFormData,
    } = useStore(false)[1];

    /**
     * Setup store with information to display dealer modal form
     */
    const setContactDealerFormData = () => {
      setModalFormData(
        null,
        dealer,
        AppConstants.ContactDealerFormType,
        AppConstants.ContactDealerFormName,
      );
    };

    /**
     * Get offers
     */
    const getLocalOffersByModelKey = (): any[] => {
      const model: any = offersData.hasOwnProperty(modelKey)
        ? offersData[modelKey]
        : [];

      return model.offers && model.offers.length > 0
        ? model.offers.map((offer: OffersModel) => ({
            ...offer,
            modelKey: model.modelKey,
            modelDisplayName: model.modelDisplayName,
            modelTag: model.modelTag ?? null,
          }))
        : [];
    };

    // CMS Configuration
    const offerImagesMap = getOfferImageMapFromModelsConfig(modelsConfig);
    const offerImagesMapConfig: OfferImagesMapModel = offerImagesMap
      ? offerImagesMap
      : localOfferImagesMap;

    /**
     * Using the special ofer modelKey the offer image map is gotten
     * @param offer A special offer
     */
    const getOfferImageMap = (offer: OffersModel): ImageMapModel => {
      const modelYear = offer.dealCarYear[0];
      if (
        offer &&
        offer.modelKey &&
        offerImagesMapConfig.hasOwnProperty(offer.modelKey) &&
        modelYear &&
        offerImagesMapConfig[offer.modelKey].hasOwnProperty(modelYear)
      ) {
        return offerImagesMapConfig[offer.modelKey][modelYear];
      }

      //Uses default image in model
      if (
        carlineId &&
        modelsConfig &&
        modelsConfig[carlineId] &&
        modelsConfig[carlineId].carImage
      ) {
        return {
          modelKey: offer.modelKey || '',
          modelYear,
          background:
            modelsConfig[carlineId].background ||
            offerImagesMapConfig.default.default.background,
          modelImage: modelsConfig[carlineId].carImage || '',
        };
      }
      return offerImagesMapConfig.default.default;
    };

    const localOffers = getLocalOffersByModelKey();

    const goToInventory = () => {
      const urlParams = new URLSearchParams();
      urlParams.set('model', modelName.replace(/-/g, ' '));

      const ref = navigateTier1Ref({
        navigationService,
        urlParams: urlParams.toString(),
        pageName: sofaRedirectPageName,
        FAName: sofaRedirectFAName,
      });

      onHandlerBasicVehicleLink(
        ref || undefined,
        translations.offersBtnLabel,
        (localOffers[0] && localOffers[0].modelDisplayName) || undefined,
        trackingManager,
        undefined,
        dealer,
      );

      navigateTier1({
        navigationService,
        urlParams: urlParams.toString(),
        pageName: sofaRedirectPageName,
        FAName: sofaRedirectFAName,
      });
    };

    /**
     * Tracking function for slider actions
     * @param type
     * @param direction
     */
    const onSlideActionTrackingEvent = (
      type: 'click' | 'swipe',
      direction: string,
    ) => {
      onSlideActionTracking(
        type,
        direction,
        undefined,
        trackingManager,
        undefined,
        dealer,
      );
      onHandleCarouselLoaded(
        {
          modelDisplayName:
            (localOffers[0] && localOffers[0].modelName) || undefined,
        },
        trackingManager,
        undefined,
        'Offers',
        dealer,
      );
    };

    return (
      <SectionWithHeader
        showButton={localOffers.length > 0}
        btnLabel={translations.offersBtnLabel}
        buttonType="link"
        title={translations.offersBtnTitle}
        btnAction={goToInventory}
      >
        {localOffers.length ? (
          specialOffersRedesignEnable ? (
            <StyledSliderWrapper>
              <SlickSlider
                visibleCards={4.5}
                visibleCardOnTablet={2.5}
                showArrows
                onActionTrackingTrigger={onSlideActionTrackingEvent}
                slickSettings={slickSettings}
              >
                {localOffers.map((offer: OffersModel, index: number) => (
                  <NewOfferCard
                    key={offer.dealId}
                    offer={offer}
                    imageMap={getOfferImageMap(offer)}
                    index={index}
                  />
                ))}
              </SlickSlider>
            </StyledSliderWrapper>
          ) : (
            <SlickSlider
              visibleCards={4.5}
              visibleCardOnTablet={2.5}
              showArrows
              onActionTrackingTrigger={onSlideActionTrackingEvent}
            >
              {localOffers.map((offer: OffersModel) => (
                <OfferCard
                  key={offer.dealId}
                  offer={offer}
                  imageMap={getOfferImageMap(offer)}
                />
              ))}
            </SlickSlider>
          )
        ) : (
          <NoResults
            header={translations.noResultsHeader}
            subHeader={translations.noResultsSubHeader}
            firstBtnAction={() => window.open(dealer?.url, '_blank')}
            firstBtnLabel={translations.noResultsBtnLabel1}
            firstBtnPadding={[12, 38, 48, 48]}
            secondBtnAction={setContactDealerFormData}
            secondBtnLabel={translations.noResultsBtnLabel2}
            secondBtnPadding={[12, 20, 30, 30]}
          />
        )}
      </SectionWithHeader>
    );
  },
);
