import { fetchGraphQL } from "./fetch-graphql";
import { createQueryString } from "./helpers/create-query";
import { handleServerResponse } from "./helpers/handle-server-response";
import { validateGraphQLServer } from "./helpers/validations";
import { DEALERS_LMA_BY_ZIPCODE } from "./queries/dealers-lma-by-zip";
import {  FetchDealersLmaByZipCode, ServiceConfig } from "./typing";


export const fetchDealersLmaByZipCode = async ({zipCode, graphQLServer}: FetchDealersLmaByZipCode) => {
    const requestBody = createQueryString(DEALERS_LMA_BY_ZIPCODE, {zipCode});
    if (!zipCode) return []
    try {
      validateGraphQLServer(graphQLServer);
        const response = await fetchGraphQL(graphQLServer as ServiceConfig, requestBody);
        if (response.data && response.data.dealers){
          return handleServerResponse(response.data, false);
        } 
        return handleServerResponse(response, true);
      } catch (error) {
        return handleServerResponse(error, true);
      }
}