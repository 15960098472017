import { styled } from '@vw-marketing/us-components';

export const StyledListItem: any = styled.button`
  display: flex;
  padding: 5px 12px;
  justify-content: center;
  align-items: center;
  margin: 0 10px 0 0;
  background-color: #dfe4e8;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  color: #3c484d;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.09px;
  height: 30px;

  &:focus {
    outline: none;
  }
`;

export const StyledIcon: any = styled.div`
  margin-left: 7px;
  margin-bottom: 2px;

  svg {
    fill: #3c484d;
  }
`;
