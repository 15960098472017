import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledDealerCardImageContainer = styled.div`
width: 80%;
display: flex;
justify-content: center;
  @media (min-width: ${Breakpoints.b560}px) {
  }
`;

export const StyledVehicleVINLabel = styled.div`
    grid-area: vehicle-details;
    transform: translate(0, -100%);
`

export const StyledDealerCardContainer = styled.div`
${({theme}) => `
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 100%;
  background-color: rgb(223, 228, 232, 0.2);
  border-radius: 5px;
  justify-items: center;
  padding: calc(${theme.size.grid001 }/2) ${theme.size.grid001 } ;
  grid-template-areas:  'vehicle' 'vehicle-details' 'label' 'dealer';
  @media (min-width: ${Breakpoints.b560}px) {
    grid-template-areas: 'vehicle' 'vehicle-details' 'label' 'dealer';
  }

  
  @media (min-width: ${Breakpoints.b1280}px) {
    grid-template-columns: 100%;
    grid-template-areas: 'vehicle' 'vehicle-details' 'label' 'dealer';
  }
  `}`;

export const StyledDealerCardInformation = styled.div`
  grid-area: dealer;
  justify-self: left;
  padding-top: 10px;
  @media (min-width: ${Breakpoints.b560}px) {
    padding: 0;
  }
`;

export const StyledVehicleTextCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-flow: column;
  @media (min-width: ${Breakpoints.b560}px) {
    flex-flow: row;
    flex-wrap: wrap;
    width: 100%;
    & > :first-child {
      margin-right: 5px;
    }
    & > :last-child {
      width: 100%;
    }
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    flex-flow: column;
  }
`;

export const StyledDealerWrapper: any = styled.div`
  margin-top: ${(props: any) => props.theme.size.dynamic050};
  @media (min-width: ${Breakpoints.b1280}px) {
    margin-top: ${(props: any) => props.theme.size.dynamic0050};
  }
`;

export const StyledDealerInformationWrapper: any = styled.div`
  width: 100%;
  & > :first-child {
    margin-bottom: 5px;
  }
  @media (min-width: ${Breakpoints.b560}px) {
    & > :first-child {
      margin-bottom: 0px;
    }
  }
`;

export const StyledVehicleDescriptionContainer: any = styled.div`
  display: flex;
  grid-area: vehicle;

  @media (min-width: ${Breakpoints.b560}px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  @media (min-width: ${Breakpoints.b1280}px) {
    width: 100%;
  }
`;

export const StyledDealerCardLabel: any = styled.div`
  padding: 14px 0 ;
  grid-area: label;
  justify-self: left;
  strong {
    font-weight: bold;
  }
`;
