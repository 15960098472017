import { Breakpoints, styled } from '@vw-marketing/us-components';

export const StyledBannerContainer = styled.div`
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .desktop-banner {
    display: none;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    img {
      height: auto;
    }
  }

  @media (min-width: ${Breakpoints.b560}px) {
    .desktop-banner {
      display: block;
    }

    .mobile-banner {
      display: none;
    }
  }
`;
