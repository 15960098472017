import {
  Breakpoints,
  ButtonAppearance,
  CustomImage,
  DisableFocusWrapper,
  Text,
  TextAppearance,
  TextColor,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  buttonPaddingGenerator,
  ButtonCustomLink,
} from '@vw-marketing/us-components';
import React from 'react';
import { showroomMapPages } from '../../../../../../config/showroom-map-pages';
import {
  ModelOrder,
  localModelOrder,
} from '../../../../../../config/tier-1/model-order';
import { useStore } from '../../../../../../hooks-store/store';
import { OffersModel } from '../../../../../../hooks-store/typings/incentive-store';
import { useFeatureAppConfig } from '../../../../../../services/use-feature-app-config';
import { useFeatureServices } from '../../../../../../services/use-feature-services';
import { AppConstants } from '../../../../../../utils/app-constants';
import {
  OfferImageWrapper,
  OfferNameWrapper,
  StyledButtonSection,
  StyledButtonWrapper,
  StyledOfferWrapper,
} from './styled';
import { useTrackingManager } from '../../../../../../context/use-tracking-manager';
import { onHandleFormButtonTracking } from '../../../../../../utils/tagging-helpers';

interface OfferDisplayHeadProps {
  readonly offer: OffersModel;
  readonly isDetailForm: boolean;
  readonly isNotStandardModel: boolean;
  handleClose: () => void;
}

export const OfferDisplayHead: React.FC<OfferDisplayHeadProps> = (
  props,
): JSX.Element | null => {
  const { offer, isDetailForm, isNotStandardModel } = props;
  const { 'navigation-service': navigationService } = useFeatureServices();
  const { modelOrder: modelOrderConfig } = useFeatureAppConfig();
  const {
    hideViewModelDetailsCTA,
    hideSearchInventoryCTA,
    faHostServerUrl = '',
  } = useFeatureAppConfig();
  const [store] = useStore();
  const useModelOrder = modelOrderConfig ? modelOrderConfig : localModelOrder;
  const trackingManager = useTrackingManager();
  const {
    InventoryRedirectPageName,
    InventoryRedirectFAName,
  } = useFeatureAppConfig();
  /**
   * Use the model name to create a slug
   */
  const getSlug = () => {
    const modelOrderEle: ModelOrder = useModelOrder.filter(
      (ele: ModelOrder) => ele.modelKey === offer.modelKey,
    )[0];

    return modelOrderEle?.modelName?.toLowerCase().replace(/ /g, '-');
  };

  // Create showroom and inventory href;
  const navigateToModelShowroom = navigationService.navigateTo(
    showroomMapPages[offer.modelKey || 'default']?.pageName,
    {},
  );

  const navigateToInventory = navigationService.navigateTo(
    InventoryRedirectPageName
      ? InventoryRedirectPageName
      : AppConstants.InvRedirectPagename,
    {
      [InventoryRedirectFAName
        ? InventoryRedirectFAName
        : AppConstants.InvRedirectFAName]: `/inventory/results/${getSlug()}`,
    },
  );

  const navigateToInventoryHref = navigateToInventory?.createHref();
  const navigateToModelShowroomHref = navigateToModelShowroom?.createHref();

  const hideBtn1 = store.pageName === AppConstants.Tier1SimplifiedVersion;

  /**
   * Handle button event
   * @param name
   * @param linkRef
   */
  const onHandleButtonEvent = (name?: string, linkRef?: string) => {
    // Tracking section
    onHandleFormButtonTracking(offer, name, trackingManager, store, linkRef);
  };

  return (
    <StyledOfferWrapper>
      <OfferNameWrapper>
        <TextCustom
          appearance={TextAppearance.headline200}
          tag={TextTag.h2}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.bold,
              fontSize: TextSize.TextSize32,
              lineHeight: TextLineHeight.TextLineHeight36,
            },
          }}
        >
          {(offer.modelKey &&
            offer.modelKey === AppConstants.AllOfferModelKey) ||
          !offer.dealCarModelName
            ? offer.modelDisplayName
            : `${offer.dealCarYear[0] || ''} ${offer.modelDisplayName || ''}`}
        </TextCustom>
        {offer.dealCarTrimName && offer.dealCarTrimName[0] && (
          <TextCustom
            appearance={TextAppearance.headline200}
            tag={TextTag.h2}
            style={{
              [Breakpoints.default]: {
                fontSize: TextSize.TextSize32,
                lineHeight: TextLineHeight.TextLineHeight36,
                fontWeight: TextWeight.light,
              },
            }}
          >
            {offer.dealCarTrimName[0]}
          </TextCustom>
        )}
      </OfferNameWrapper>
      <OfferImageWrapper>
        <CustomImage
          src={
            offer.mediaImageUrl
              ? offer.mediaImageUrl
              : AppConstants.PlaceHolder960x432
          }
          alt={offer.modelDisplayName || ''}
          srcPlaceholder={AppConstants.PlaceHolder960x432 as string}
          srcErrorImage={faHostServerUrl + AppConstants.Ngw6Logo960x432}
        />
      </OfferImageWrapper>
      <StyledButtonWrapper isDetailForm={isDetailForm}>
        {!hideBtn1 && (
          <StyledButtonSection>
            <DisableFocusWrapper>
              {!hideViewModelDetailsCTA && (
                <ButtonCustomLink
                  appearance={ButtonAppearance.Secondary}
                  href={navigateToModelShowroomHref}
                  padding={buttonPaddingGenerator(12, 10, 30, 30)}
                  stretchContent
                  target="_blank"
                  onClick={onHandleButtonEvent.bind(
                    null,
                    'View model details',
                    navigateToModelShowroomHref,
                  )}
                >
                  <Text
                    color={TextColor.inherit}
                    appearance={TextAppearance.label150}
                  >
                    View model details
                  </Text>
                </ButtonCustomLink>
              )}
            </DisableFocusWrapper>
          </StyledButtonSection>
        )}

        <StyledButtonSection>
          <DisableFocusWrapper>
            {isNotStandardModel ? (
              <ButtonCustomLink
                appearance={ButtonAppearance.Primary}
                href={offer.modelCustomCta?.url}
                target={offer.modelCustomCta?.openNewTab ? '_blank' : '_self'}
                padding={buttonPaddingGenerator(12, 10, 40, 40)}
                stretchContent
                onClick={onHandleButtonEvent.bind(
                  null,
                  offer.modelCustomCta?.ctaLabel,
                  offer.modelCustomCta?.url,
                )}
                className={'build-reserve'}
              >
                <Text
                  color={TextColor.inherit}
                  appearance={TextAppearance.label150}
                >
                  {offer.modelCustomCta?.ctaLabel}
                </Text>
              </ButtonCustomLink>
            ) : (
              !hideSearchInventoryCTA && (
                <ButtonCustomLink
                  appearance={ButtonAppearance.Primary}
                  href={navigateToInventoryHref}
                  target="_blank"
                  padding={buttonPaddingGenerator(12, 10, 36, 36)}
                  stretchContent
                  onClick={onHandleButtonEvent.bind(
                    null,
                    'Search Inventory',
                    navigateToInventoryHref,
                  )}
                >
                  <Text
                    color={TextColor.inherit}
                    appearance={TextAppearance.label150}
                  >
                    Search Inventory
                  </Text>
                </ButtonCustomLink>
              )
            )}
          </DisableFocusWrapper>
        </StyledButtonSection>
      </StyledButtonWrapper>
    </StyledOfferWrapper>
  );
};
