import React from 'react';


export const IsNationalOfferContext = React.createContext<boolean>(
  false
);

export function useIsNationalOffers(): boolean {
    const isNationalOffer = React.useContext(IsNationalOfferContext);
  return isNationalOffer;
}
