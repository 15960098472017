import React from 'react';
import {FeatureAppDependencies} from '..';

export const FeatureServicesContext = React.createContext<
  FeatureAppDependencies | undefined
>(undefined);

export function useFeatureServices(): FeatureAppDependencies {
  const featureServices = React.useContext(FeatureServicesContext);

  if (!featureServices) {
    throw new Error('No featureServices context value was provided.');
  }

  return featureServices;
}
