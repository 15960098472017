import React from 'react';

import {
  Close,
  Text,
  TextAppearance,
  TextColor,
  TextTag,
} from '@vw-marketing/us-components';

import { StyledIcon, StyledListItem } from './styled';
import { useFeatureAppConfig } from '../../../../../services/use-feature-app-config';

export const ListItem: React.FC<any> = (props: any): JSX.Element => {
  const {
    filter,
    updateAppliedFilter,
    setPendingProcessState,
    history,
  } = props;
  const { specialOffersRedesignEnable } = useFeatureAppConfig();

  const updateFilter = () => {
    updateAppliedFilter(filter, history, true, specialOffersRedesignEnable);
    setTimeout(() => {
      specialOffersRedesignEnable
        ? setPendingProcessState(true)
        : setPendingProcessState(false);
    }, 1000);
  };

  return (
    <StyledListItem onClick={updateFilter}>
      <Text
        appearance={TextAppearance.label100}
        tag={TextTag.span}
        color={TextColor.inherit}
      >
        {filter.text}
      </Text>
      <StyledIcon>
        <Close variant="small" />
      </StyledIcon>
    </StyledListItem>
  );
};
