import {styled, Breakpoints} from '@vw-marketing/us-components';

interface ContainerDisplayState{
  activeView:boolean;
}

export const FindDealerContainer :any = styled.div`
  display: flex;
`;

export const MapContainer :any = styled.div<ContainerDisplayState>`
  ${(props:any) => !props.activeView && "display: none;"}
  width: 100%;
  min-height: 430px;
  @media (min-width: ${Breakpoints.b960}px) {
    display: block;
    width: 70%;
  }
`;

export const DealerListContainer :any = styled.div<ContainerDisplayState>`
  ${(props: any) => !props.activeView && "display: none;"}
  width: 100%
  max-height: 430px;
  @media (min-width: ${Breakpoints.b960}px) {
    display: block;
    width: 30%;
    padding-left: 51px;
    max-height: 523px;
  }
`;