import React, {useEffect, useState} from 'react';
import {DealerModel} from '../../../../../hooks-store/typings/incentive-store';
import DealerCard from './dealer-card';
import {DealerListContainer} from './styled';

interface DealerListProps {
  dealers?: DealerModel[];
  activeDealerId?: string;
  setActiveDealer: (dealer?: DealerModel, showModal?: boolean, isTracking?: boolean) => void;
  onHandleDealerTracking: (dealer?: DealerModel) => void; 
}

const DealerList = ({
  dealers,
  setActiveDealer,
  activeDealerId,
  onHandleDealerTracking
}: DealerListProps): JSX.Element => {
  const [activeScroll, setActiveScroll] = useState(true);
  const refs =
    dealers &&
    dealers.reduce(
      (acc: {[key: string]: React.RefObject<any>}, dealer: DealerModel) => {
        acc[dealer.dealerid] = React.createRef();
        return acc;
      },
      {}
    );

  /**
   * Scroll into dealer card, move element to top of container
   * @param dealerId
   */
  const scrollIntoDealerInformation = (dealerId?: string) => {
    if (dealerId && refs && refs[dealerId].current) {
      const target = refs[dealerId].current;
      target.parentNode.scrollTop =
        target.offsetTop - target.parentNode.offsetTop;
    }
  };

  /**
   * Avoiding Dealer list scroll when dealer is selected. 
   * @param dealer 
   * @param showModal 
   */
  const setActiveDealerCard = (dealer?: DealerModel, showModal?: boolean) => {
    setActiveDealer(dealer, showModal, false);
    setActiveScroll(false);
  };

  // Change scroll when user is selected
  useEffect(() => {
    activeScroll && scrollIntoDealerInformation(activeDealerId);
    !activeScroll && setActiveScroll(true);
  }, [activeDealerId]);

  return (
    <DealerListContainer>
      {dealers &&
        dealers.map((dealer: DealerModel) => (
          <div ref={refs && refs[dealer.dealerid]}>
            <DealerCard
              dealer={dealer}
              setActiveDealer={setActiveDealerCard}
              key={dealer.dealerid}
              onHandleDealerTracking={onHandleDealerTracking}
            />
          </div>
        ))}
    </DealerListContainer>
  );
};

export default DealerList;
