import React from 'react';
import {
  DealerModel,
  OffersModel
} from '../../../../../hooks-store/typings/incentive-store';
import {AppConstants} from '../../../../../utils/app-constants';
import {DealerInformation} from './dealer-information';
import {LocalDealerInformation} from './local-dealer-information';
import {OfferDetailsCard} from './offer-details-card';
import {OfferDisplayHead} from './offer-display-head';
import {ServiceOfferDetailsCard} from './service-offer-details-card';
import {StyledOfferCardWrapper} from './styled';

export interface OfferInformationProps {
  tier2: boolean;
  offer?: OffersModel;
  dealer?: DealerModel;
  formType: string;
  handleClose: () => void;
  onHandleDealerUrlTracking: (name: string) => void;
}

const OfferInformation: React.FC<OfferInformationProps> = props => {
  const {
    tier2,
    offer,
    dealer,
    formType,
    handleClose,
    onHandleDealerUrlTracking
  } = props;
  const isNotStandard = formType === AppConstants.NotStandardModelFormType;
  const isNationalOffersOnly = AppConstants.NationalOffersOnlyFormType === formType;

  return (
    <>
      {formType === AppConstants.OfferDetailsFormType ||
      formType === AppConstants.ContactDealerFormType ||
        formType === AppConstants.GetAQuoteFormType || 
        isNotStandard || isNationalOffersOnly ? (
        <StyledOfferCardWrapper>
          {offer && offer.modelKey !== AppConstants.AllOfferModelKey && (
            <OfferDisplayHead
              offer={offer}
              handleClose={handleClose}
              isDetailForm={formType === AppConstants.OfferDetailsFormType || isNotStandard}
              isNotStandardModel={isNotStandard}
            />
          )}

            {dealer && formType !== AppConstants.OfferDetailsFormType && !isNotStandard && !isNationalOffersOnly ? (
            <>
              {tier2 ? (
                <LocalDealerInformation
                  dealer={dealer}
                  onHandleDealerUrlTracking={onHandleDealerUrlTracking}
                />
              ) : (
                <DealerInformation
                  dealer={dealer}
                  onHandleDealerUrlTracking={onHandleDealerUrlTracking}
                />
              )}
            </>
          ) : (
            offer && <OfferDetailsCard offer={offer} />
          )}
        </StyledOfferCardWrapper>
      ) : formType === AppConstants.ScheduleServiceFormType ||
        formType === AppConstants.ScheduleServiceDetailFormType ? (
        <StyledOfferCardWrapper>
          {dealer && formType !== AppConstants.ScheduleServiceDetailFormType ? (
            <>
              {tier2 ? (
                <LocalDealerInformation
                  dealer={dealer}
                  onHandleDealerUrlTracking={onHandleDealerUrlTracking}
                />
              ) : (
                <DealerInformation
                  dealer={dealer}
                  onHandleDealerUrlTracking={onHandleDealerUrlTracking}
                />
              )}
            </>
          ) : (
            offer && <ServiceOfferDetailsCard offer={offer} />
          )}
        </StyledOfferCardWrapper>
      ) : null}
    </>
  );
};

export default OfferInformation;
