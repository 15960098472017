import {FeatureAppEnvironment} from '@feature-hub/core';
import React from 'react';
import {
  FeatureAppConfig,
  FeatureAppDependencies
} from '../.';

export type CurrentFeatureAppEnvironment = FeatureAppEnvironment<
  FeatureAppDependencies,
  FeatureAppConfig
>;

export const FeatureAppEnvironmentContext = React.createContext<
CurrentFeatureAppEnvironment | undefined
>(undefined);

export function useFeatureAppEnvironment(): CurrentFeatureAppEnvironment {
  const featureAppEnvironment = React.useContext(FeatureAppEnvironmentContext);

  if (!featureAppEnvironment) {
    throw new Error('No featureAppEnvironment context value was provided.');
  }

  return featureAppEnvironment;
}
