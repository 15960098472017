
export const PRODUCT_DEALERS_BY_VANITY_URL = {query: `query GetLmaDealersByVanityUrl($vanityUrl: String!, $zipCode: String!) {
  dealers: getLmaDealersByVanityUrl(vanityUrl: $vanityUrl, zipCode: $zipCode) {
    generatedDate
    dealerid
    name
    dealername
    seolookupkey
    address1
    address2
    city
    state
    postalcode
    country
    url
    phone
    latlong
    staticMapsUrl
    distance
    inventoryCount
    aor
    isSatellite
    isAssessing
    lmaId
  }
}`}
