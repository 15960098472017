import {
  DisableFocusWrapper,
  Link,
  Text,
  TextAppearance,
} from '@vw-marketing/us-components';
import React from 'react';
import { useTrackingManager } from '../../../../context/use-tracking-manager';
import IncentiveConstants from '../../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../../hooks-store/store';
import { useHistory } from '../../../../services/use-history';
import { useTranslations } from '../../../../services/use-translations';
import { scrollToTop } from '../../../../utils/general';
import { onHandlerFilterSeachLoad } from '../../../../utils/tagging-helpers';
import { ListItem } from './list-item';
import {
  StyledFilterAreaWrapper,
  StyledFilterList,
  StyledTextWrapper,
} from './styled';

export const FilterArea: React.FC = (): JSX.Element => {
  const history = useHistory();
  const [
    store,
    {
      [IncentiveConstants.ClearFilters]: clearFilters,
      [IncentiveConstants.UpdateAppliedFilter]: updateAppliedFilter,
      [IncentiveConstants.SetPendingProcessState]: setPendingProcessState,
    },
  ] = useStore();
  const translations = useTranslations().offersSection.filter.filterArea;
  const trackingManager = useTrackingManager();

  const clearFiltersSelected = () => {
    const updatedStore = clearFilters(history, true);
    setTimeout(() => {
      setPendingProcessState(false);
      scrollToTop(500);
    }, 1000);
    onHandlerFilterSeachLoad(trackingManager, updatedStore);
  };
  return (
    <>
      {store.appliedFilters && store.appliedFilters.length > 0 && (
        <StyledFilterAreaWrapper>
          <StyledFilterList>
            {store.appliedFilters.map((filter: any) => (
              <ListItem
                key={filter.value}
                filter={filter}
                updateAppliedFilter={updateAppliedFilter}
                setPendingProcessState={setPendingProcessState}
                history={history}
              />
            ))}
          </StyledFilterList>

          <StyledTextWrapper>
            <Text appearance={TextAppearance.copy100} bold>
              <DisableFocusWrapper>
                <Link onClick={() => clearFiltersSelected()} isInline>
                  {translations.clearAll}
                </Link>
              </DisableFocusWrapper>
            </Text>
          </StyledTextWrapper>
        </StyledFilterAreaWrapper>
      )}
    </>
  );
};
