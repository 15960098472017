import find from 'lodash/find';
import sortBy from 'lodash/sortBy';
import {
  MappedTrimNameModel,
  localMappedTrimName
} from '../../config/mapped-trim-name';
import {ModelOrder, localModelOrder} from '../../config/tier-1/model-order';
import {
  DealerAssociations,
  dealerAssociations
} from '../../config/tier-2/dealer-associations';
import {
  DealerModel,
  OffersModel
} from '../../hooks-store/typings/incentive-store';
import {AppConstants} from '../../utils/app-constants';
import {
  stringToPhoneFormat,
  getModelImageData,
  cleanUpCharacters,
  getModelWithCustomCta
} from '../../utils/general';
import {ModelConfig} from '../../typings/model-config';

/**
 * Get a node from delearAssociation by lmaId
 * @param lmaId lmaId
 */
export const getDealerAssociationsByLmaId = (
  lmaId: string
): DealerAssociations | undefined =>
  find(
    dealerAssociations,
    (elem: DealerAssociations) => elem.lmaData.lmaId === lmaId
  );

/**
 * get and obj with the authored lma details
 * @param lmaId LmaId
 */
export const getAuthoredLmaDetail = (lmaId: string) => {
  const data = getDealerAssociationsByLmaId(lmaId);

  if (data) {
    return {
      name: data.lmaData.name,
      vanityName: data.lmaData.lmaVanityName,
      vanityUrlName: data.lmaData.lmaVanityUrlName,
      mastheadImage: data.lmaData.mastheadImage
    };
  }

  return {
    name: '',
    vanityName: '',
    vanityUrlName: '',
    mastheadImage: ''
  };
};

const isLmaModel = (modelName: string, modelOrder: ModelOrder[]): boolean => {
  if (!modelName) return false;
  const findModel = (model: ModelOrder) => model.modelKey === modelName;
  const foundModel = modelOrder.find(findModel);
  return typeof foundModel !== 'undefined';
};

/**
 * Create a mapping using different criteria to be displayed on the app
 * @param offersData All offers from the feed
 */
export const mapOffersData = (
  offersData: any,
  lmaId: string,
  mappedTrimName?: MappedTrimNameModel,
  modelsConfig?: ModelConfig,
  generalModelsOrder: ModelOrder[] = localModelOrder
) => {
  let modelOrder: ModelOrder[];
  // CMS configuration
  const mappedTrimNameConfig = mappedTrimName
    ? mappedTrimName
    : localMappedTrimName;

  // Through the map, this process standardizes or add fields
  let offerAux = offersData.map((offer: any) => ({
    ...offer,
    dealOfferTypes: [getSashType(offer)],
    dealCarTrimName: addTrimNameMapping(offer, mappedTrimNameConfig)
  }));

  const data = getDealerAssociationsByLmaId(lmaId);
  modelOrder = data ? data.lmaData.modelOrder : [];

  // Create a hash obj using the slug as a key {tiguan: {}, atlas: {},...}
  offerAux = offerAux.reduce((next: any, offer: any) => {
    // If there isn't a car model name the 'all' category is created to add this information
    if (!offer.dealCarModel || !offer.dealCarModel.length) {
      return {
        ...next,
        all: next.all
          ? {
              ...next.all,
              offers: [...next.all.offers, offer],
              offerCount: next.all.offerCount + 1
            }
          : {
              offers: [offer],
              offerCount: 1,
              modelDisplayName: getModelDisplayName(
                AppConstants.AllOfferModelKey,
                modelOrder
              ),
              modelKey: AppConstants.AllOfferModelKey,
              slug: getModelDisplayName(
                AppConstants.AllOfferModelKey,
                modelOrder
              )
                .toLowerCase()
                .replace(/ /g, '-'),
              imageData: getModelImageData(
                AppConstants.AllOfferModelKey,
                generalModelsOrder,
                modelsConfig
              )
            }
      };
    }
    // Push the offer to the specific slug key, dynamically
    return {
      ...next,
      [offer.dealCarModel]: next[offer.dealCarModel]
        ? {
            ...next[offer.dealCarModel],
            offers: [...next[offer.dealCarModel].offers, offer],
            offerCount: next[offer.dealCarModel].offerCount + 1
          }
        : {
            offers: [offer],
            offerCount: 1,
            modelKey: offer.dealCarModel[0],
            modelDisplayName: getModelDisplayName(
              offer.dealCarModel[0],
              modelOrder
            ),
            slug: getModelDisplayName(offer.dealCarModel[0], modelOrder)
              .toLowerCase()
              .replace(/ /g, '-'),
            imageData: getModelImageData(
              offer.dealCarModel[0],
              generalModelsOrder,
              modelsConfig
            ),
            modelCustomCta: isLmaModel(offer.dealCarModel[0], modelOrder)
              ? getModelWithCustomCta(
                  offer.dealCarModel[0],
                  generalModelsOrder,
                  modelsConfig
                )
              : undefined
          }
    };
  }, {});

  // A config obj to determine the order and which elements will be displayed (to order and filter)
  offerAux = getOrdering(
    offerAux,
    modelOrder,
    modelsConfig,
    generalModelsOrder
  );

  return cleanUpCharacters(offerAux);
};

/**
 * Create a display name
 * @param dealCarModel car model name form offer feed
 * @param modelOrder model ordern from dealerAssociations
 */
const getModelDisplayName = (
  dealCarModel: string,
  modelOrder: ModelOrder[]
) => {
  const modelObj = modelOrder.filter(
    (ele: ModelOrder) => ele.modelKey === dealCarModel
  )[0];

  return modelObj ? modelObj.modelName : '';
};

/**
 * Check the dealOfferTypes value if is undefined change it for 'OTHER'
 * @param offer An offer data obj
 */
const getSashType = (offer: any): string => {
  if (offer.dealOfferTypes.length !== 1) {
    return 'OTHER';
  }

  return offer.dealOfferTypes[0];
};

/**
 * Use a config file obj to get the trim name, using the 'year-model-trim' pattern
 * @param offer An offer from the feed
 */
const addTrimNameMapping = (
  offer: any,
  mappedTrimNameConfig: MappedTrimNameModel
) => {
  const trimNameList: any = [];

  if (
    !offer.dealCarTrim.length ||
    !offer.dealCarYear.length ||
    !offer.dealCarModel.length
  ) {
    return trimNameList;
  }

  for (
    let i = 0;
    i < offer.dealCarYear.length &&
    i < offer.dealCarModel.length &&
    i < offer.dealCarTrim.length;
    i++
  ) {
    const year = offer.dealCarYear[i];
    const model = offer.dealCarModel[i];
    const trim = offer.dealCarTrim[i];

    if (!trim || trim === AppConstants.AllOfferModelKey) {
      trimNameList.push('');
    } else {
      const key = `${year}-${model}-${trim}`;
      if (mappedTrimNameConfig[key] && mappedTrimNameConfig[key].trimName) {
        trimNameList.push(mappedTrimNameConfig[key].trimName);
      }
    }
  }

  return trimNameList;
};

/**
 * A config obj to determine the order and which elements will be displayed (to order and filter)
 * @param offers all the offer from the feed
 */
const getOrdering = (
  offers: any,
  modelOrderConfig: ModelOrder[],
  modelsConfig?: ModelConfig,
  generalModelsOrder?: ModelOrder[]
) =>
  modelOrderConfig.reduce((next: any, {modelKey, modelName}: any) => {
    if (offers[modelKey]) {
      return {
        ...next,
        [modelKey]: offers[modelKey]
      };
    }

    return {
      ...next,
      [modelKey]: {
        offers: [],
        offerCount: 0,
        modelKey,
        modelDisplayName: modelName,
        slug: modelName.toLowerCase().replace(/ /g, '-'),
        imageData: getModelImageData(modelKey, modelOrderConfig, modelsConfig),
        modelCustomCta: isLmaModel(modelKey, modelOrderConfig)
          ? getModelWithCustomCta(
              modelKey,
              generalModelsOrder || modelOrderConfig,
              modelsConfig
            )
          : undefined
      }
    };
  }, {});

/**
 * Get the aor dealer or the closest dealer by distance
 * @param dealers Dealers list from zipcode
 */
export const getAorDealer = (dealers: DealerModel[]): DealerModel => {
  let dealerFiltered = dealers.filter(
    (dealer: DealerModel) => dealer.aor === 'true'
  );

  if (dealerFiltered.length === 0) {
    dealerFiltered = dealers.sort(
      (a: DealerModel, b: DealerModel) => a.distance - b.distance
    );
  }

  return dealerFiltered[0];
};

/**
 * To add some important fields to the dealer obj
 * @param dealers lma dealers
 */
export const transformData = (dealers: DealerModel[]) => {
  const googleMapBaseUrl =
    'https://www.google.com/maps/dir/?api=1&destination=';

  return dealers.map((dealer: DealerModel, i) => ({
    ...dealer,
    lat: dealer.latlong.split(',')[0],
    lng: dealer.latlong.split(',')[1],
    isActive: i === 0,
    mobileTel: `tel: ${dealer.phone}`,
    prettyTel: stringToPhoneFormat(dealer.phone),
    googleMapAddress: `${googleMapBaseUrl}${encodeURIComponent(
      `${dealer.address1} ${dealer.address2} ${dealer.city} ${dealer.state} ${dealer.postalcode}`
    )}`
  }));
};

/**
 * Sort the offers by dealPriority
 * @param offers Service offers
 */
export const getDealPriority = (offers: OffersModel[]) =>
  sortBy(offers, 'dealPriority');
