import {
  Breakpoints,
  BreakpointWrapper,
  ButtonNext,
  DisableFocusWrapper,
  Text,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';
import React from 'react';
import InputZip from '../../../components/input-zip';
import { useTrackingManager } from '../../../context/use-tracking-manager';
import { useTranslations } from '../../../services/use-translations';
import { AppConstants } from '../../../utils/app-constants';
import { onHandlerBasicVehicleLink } from '../../../utils/tagging-helpers';
import {
  StyledContainer,
  StyledHeaderTitleCTA,
  StyledHeaderWrapper,
  StyledInputZipWrapper,
  StyledMobileHeader,
} from './styled';

interface ModalHeaderProps {
  modelKey?: string;
}

export const ModalHeader: React.FC<ModalHeaderProps> = ({ modelKey }) => {
  const translations = useTranslations();
  const isMobile = useIsMobile(Breakpoints.b560);
  const isTablet = useIsMobile(Breakpoints.b960);
  const trackingManager = useTrackingManager();
  const offersUrl: string = AppConstants.OffersUrl;

  const goToOffers = () => {
    if (modelKey) {
      let newUrl = offersUrl;

      const formattedSlug = modelKey.replace(/\./g, '-');
      newUrl = newUrl.replace('{slug}', formattedSlug);

      onHandlerBasicVehicleLink(
        newUrl || undefined,
        translations.tier1simplifiedVersion.localOffers.offersBtnLabel,
        modelKey,
        trackingManager,
        undefined,
        undefined,
      );

      window.open(newUrl, '_self');
    }
  };

  return (
    <StyledContainer className="modal-header-container" isMobile={isMobile}>
      <StyledHeaderWrapper>
        <BreakpointWrapper min={Breakpoints.b560}>
        <StyledHeaderTitleCTA>
          <TextCustom
            appearance={TextAppearance.headline200}
            tag={isMobile ? TextTag.p : TextTag.h2}
            style={isTablet
              ? {
                  fontSize: TextSize.TextSize24,
                  lineHeight: TextLineHeight.TextLineHeight20,
                  fontWeight: TextWeight.bold,
                }
              : {
                  fontSize: TextSize.TextSize44,
                  lineHeight: TextLineHeight.TextLineHeight52,
                  fontWeight: TextWeight.bold,
                }
            }
          >
            {translations.tier1simplifiedVersion.localOffers.offersBtnTitle}
          </TextCustom>
        </StyledHeaderTitleCTA>
        </BreakpointWrapper>
        <BreakpointWrapper min={Breakpoints.b560}>
          <StyledInputZipWrapper>
            <DisableFocusWrapper>
              <InputZip
                errorText={translations.filtersSection.inputZipError}
                altErrortext={translations.filtersSection.noDealersError}
                maxLength={5}
                placeholder={translations.filtersSection.zipcodePlaceholder}
                modelKey={modelKey}
              />
            </DisableFocusWrapper>
          </StyledInputZipWrapper>
        </BreakpointWrapper>
      </StyledHeaderWrapper>
      <BreakpointWrapper max={Breakpoints.b560}>
        <StyledMobileHeader>
          <Text appearance={TextAppearance.copy100} bold>
            <DisableFocusWrapper>
              <ButtonNext
                onClick={goToOffers}
                emphasis="tertiary"
                tag="button"
                size="small"
              >
                {translations.tier1simplifiedVersion.localOffers.offersBtnLabel}
              </ButtonNext>
            </DisableFocusWrapper>
          </Text>
          <StyledInputZipWrapper isMobile>
            <DisableFocusWrapper>
              <InputZip
                errorText={translations.filtersSection.inputZipError}
                altErrortext={translations.filtersSection.noDealersError}
                maxLength={5}
                placeholder={translations.filtersSection.zipcodePlaceholder}
                modelKey={modelKey}
              />
            </DisableFocusWrapper>
          </StyledInputZipWrapper>
        </StyledMobileHeader>
      </BreakpointWrapper>
    </StyledContainer>
  );
};
