import { History } from 'history';
import isBrowser from 'is-browser';
import React, { useEffect } from 'react';
import { updateStoreData } from '../../../fetch-data/tier-1';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../hooks-store/store';
import { StoreModel } from '../../../hooks-store/typings/store';
import { getStringifiedServiceConfigsServiceConfig } from '../../../services/get-stringified-service-configs-service-config';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import { useFeatureServices } from '../../../services/use-feature-services';
import { useHistory } from '../../../services/use-history';

import { SpecialEventConfig } from '../../..';
import { localModelOrder } from '../../../config/tier-1/model-order';
import { useTrackingManager } from '../../../context/use-tracking-manager';
import { IncentiveStoreModel } from '../../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../../utils/app-constants';
import { onHandlerFilterSeachLoad } from '../../../utils/tagging-helpers';
import { ModalForm } from '../../forms/modal-form';
import { LoaderSpinnerModal } from '../../loader-spinner';
import { ModalZip } from '../../modal-zip';
import ModalBuildDealInterstitial from '../../modal-build-deal-interstitial';

interface PageLoadProps {
  readonly children: React.ReactNode;
  readonly applicationData: StoreModel;
  readonly page: string;
  readonly baseUrl?: string;
  readonly isNationalOffers?: boolean;
  urlZip?: string;
  offerId?: number;
  readonly modelKey?: string;
  readonly specialEvent?: SpecialEventConfig;
}

export const PageLoad: React.FC<PageLoadProps> = (
  props,
): JSX.Element | null => {
  const {
    children,
    applicationData,
    page,
    urlZip,
    offerId,
    modelKey,
    isNationalOffers,
    specialEvent,
  } = props;
  let zipManagerSuscription: any = null;

  // Call different services used to apply fetches and update the store data
  const {
    faServicesUrl,
    mockIds,
    mocksBaseUrl,
    modelOrder: modelsOrder,
    mappedTrimName,
    specialOffersRedesignEnable,
    vehicleImage,
    vehicle,
    informationVendor,
    obLink,
    isLinkModified,
    symbol
  } = useFeatureAppConfig();
  const history = useHistory();

  const {
    'locale-service': localeService,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager,
  } = useFeatureServices();
  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );
  const {
    configs: { 'graphql-server': graphQLServer },
  } = serviceConfigProvider;

  // const urlZip = getUrlZip(history);

  const modelOrder = modelsOrder || localModelOrder;

  const [
    store,
    {
      [IncentiveConstants.CloneStoreData]: cloneStoreData,
      [IncentiveConstants.CloseContactDealerForm]: closeContactDealerForm,
      [IncentiveConstants.SetPendingProcessState]: setPendingProcessState,
      [IncentiveConstants.UpdateStoreByZip]: updateStoreByZip,
      [IncentiveConstants.SetDRModalData]: setDRModalData,
    },
  ] = useStore();
  /**
   * Update the page information using the zipcode if neccessary
   * @param zipCode Zip code value
   */
  const updateStoreHandle = (
    zipCode: string,
    successResultCallback?: (store: IncentiveStoreModel) => void,
    offerId?: number,
    isCSR?: boolean,
  ) => {
    if (zipCode !== store.zip) {
      setPendingProcessState(true);
      updateStoreData({
        zip: (!isNationalOffers && zipCode) || undefined,
        localeService,
        serviceConfigsServiceConfig: serviceConfigsServiceConfig as string,
        updateStoreByZip,
        zipManager,
        history: history as History,
        faServicesUrl,
        mockIds,
        mocksBaseUrl,
        modelOrder,
        mappedTrimName,
        successResultCallback,
        offerId,
        specialEvent,
        graphQLServer,
        specialOffersRedesignEnable,
      });
    } else {
      isCSR && successResultCallback && successResultCallback(store);
    }
  };

  const trackingManager = useTrackingManager();

  const successResultCallbackSuscribe = (updatedStore: IncentiveStoreModel) => {
    onHandlerFilterSeachLoad(
      trackingManager,
      updatedStore,
      (updatedStore.pageName === AppConstants.Tier1SimplifiedVersion &&
        modelKey) ||
        undefined,
    );
  };

  const successResultCallbackCSR = (store: IncentiveStoreModel) => {
    isBrowser &&
      onHandlerFilterSeachLoad(
        trackingManager,
        store,
        (store.pageName === AppConstants.Tier1SimplifiedVersion && modelKey) ||
          undefined,
      );
  };

  /**
   * Before clone data some data is added to start the app in the CSR
   */
  const cloneStoreDataHandler = (zipCode: string, zipCookieExist: boolean) => {
    let activeZipModal = isNationalOffers || false;
    if (!zipCookieExist && !zipManager.getZipModalActive()) {
      zipManager.setZipModalActive(true);
      activeZipModal = true;
    }
    const currentOfferId =
      (!activeZipModal && offerId) ||
      (isNationalOffers && offerId) ||
      undefined;
    const initialData = {
      ...applicationData,
      zip: zipCode,
      offerId: currentOfferId,
      modalZipState: {
        activeZipModal,
      },
    };

    cloneStoreData(
      initialData,
      history,
      specialEvent,
      specialOffersRedesignEnable,
      modelKey,
    );
    updateStoreHandle(zipCode, successResultCallbackCSR, currentOfferId, true);

    if (page === AppConstants.Tier1SimplifiedVersion) {
      zipManagerSuscription = zipManager.subscribe(() => {
        updateStoreHandle(zipManager.getZip(), successResultCallbackSuscribe);
      });
    }
  };

  useEffect(() => {
    if (!store.isStoreCloned) {
      if (
        !urlZip &&
        !store.zip &&
        isBrowser &&
        store.status === AppConstants.StoreStatusLoaded &&
        page == AppConstants.Tier1SimplifiedVersion
      ) {
        cloneStoreDataHandler('', false);
        console.log('Skip zipManager location fallback calls');
      } else if (isBrowser && store.status === AppConstants.StoreStatusLoaded) {
        try {
          zipManager
            .updateZipInformation(urlZip ? urlZip : '', store.ipZip)
            .catch(error => {
              console.log('Errors fetching detectBrowserLocation', error);
            })
            .finally(() => {
              cloneStoreDataHandler(
                zipManager.getZip() || store.zip,
                zipManager.getZipCookie() ? true : false,
              );
            });
        } catch (error) {
          cloneStoreDataHandler(store.zip ? store.zip : store.ipZip, false);
          console.log('zipManager location error', error);
        }
      }
    }
    return () => (zipManagerSuscription ? zipManagerSuscription() : null);
  }, []);

  return (
    <div id="offersFA">
      {children}
      {(!store.modalZipState.activeZipModal || isNationalOffers) && (
        <ModalForm
          tier2={store.tier2}
          offer={store.modalFormData.offer}
          dealer={store.modalFormData.dealer || store.dealer}
          formType={store.modalFormData.formType}
          formName={store.modalFormData.formName}
          active={store.modalFormData.active}
          onCloseHandler={closeContactDealerForm}
          zipCode={store.zip}
          trackingManager={trackingManager}
        />
      )}
      {store.modalDRData.active && (
        <ModalBuildDealInterstitial
          active={store.modalDRData.active}
          onCloseHandler={() => {
            setDRModalData(
              {
                active: false,
              }
            );
          }}
          handOffContent={"onlineBuying"}
          vehicle={vehicle}
          informationVendor={informationVendor}
          obLink={obLink}
          isLinkModified={isLinkModified}
          symbol={symbol}
          vehicleImage={vehicleImage}
        />
      )}
      {store.modalZipState.activeZipModal &&
        page != AppConstants.Tier1SimplifiedVersion &&
        !isNationalOffers && (
          <ModalZip
            active={store.modalZipState.activeZipModal}
            trackingManager={trackingManager}
            offerId={offerId}
          />
        )}
      {(!store.isStoreCloned || store.pendingProcessState) &&
        page !== AppConstants.Tier1SimplifiedVersion &&
        !specialOffersRedesignEnable && <LoaderSpinnerModal />}
    </div>
  );
};
