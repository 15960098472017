import 'isomorphic-fetch';
import {AppConstants} from '../../utils/app-constants';

const errorHandler = (obj: any, status: boolean) => {
  if (status) {
    return {
      errorStatus: true,
      data: obj
    };
  }

  return {
    errorStatus: false,
    data: obj
  };
};

export async function fetchLocation(zip: string): Promise<any> {
  const country = new RegExp(AppConstants.PUERTO_RIC0_REGEX).test(zip)
    ? AppConstants.PUERTO_RICO_CODE
    : AppConstants.UNITED_STATES_CODE;

  try {
    const response = await fetch(
      AppConstants.GoogleApiGeoCodeUrl.replace('{postalCode}', zip).replace(
        '{apiCountry}',
        country
      ),
      {
        method: 'GET',
        mode: 'cors'
      }
    );

    const locationResult = await response.json();

    if (response.status >= 500) {
      return errorHandler(response, true);
    }

    return errorHandler(locationResult, false);
  } catch (error) {
    return errorHandler(error, true);
  }
}
