import _, {isEmpty, isFunction} from 'lodash';
import {ModalFormState} from '..';
import {
  DealerModel,
  OffersModel
} from '../../../../hooks-store/typings/incentive-store';
import {submitLead} from '../../../../utils/submit-data/submit-contact-dealer';
import {FormResponseState} from '../../../../utils/submit-data/helpers/response-states';
import isBrowser from 'is-browser';
import {FeatureAppTrackingManager} from '../../../../context/use-tracking-manager';
import {
  onHandleFormSubmitTracking,
  onHandlerServiceFormLayerEvents
} from '../../../../utils/tagging-helpers';


type SubmitFunction = (values: object) => void;

// Tagging source from window
declare global {
  interface Window {
    bt_cookie: any;
  }
}

enum FormRequestTypes {
  TIER_1_CONTACT_DEALER = 'contactDealerTier1',
  TIER_1_GET_A_QUOTE = 'quoteTier1',
  TIER_2_CONTACT_DEALER = 'contactDealerTier2',
  TIER_2_GET_A_QUOTE = 'quoteTier2',
  TIER_2_SERVICE = 'quoteServiceTier2'
}

const formMetaDataMap = {
  contactDealerTier1: {
    formName: 'UnifiedForm - Contact Dealer',
    requestType: 'Contact a dealer',
    sourceType: 'http://www.vw.com',
  },
  quoteTier1: {
    formName: 'UnifiedForm - Get a Quote',
    requestType: 'quote'
  },
  contactDealerTier2: {
    formName: 'Contact Dealer',
    requestType: 'contactdealer',
    service: 'Contact Dealer'
  },
  quoteTier2: {
    formName: 'Get A Quote',
    requestType: 'quote',
    service: 'Get A Quote'
  }
};

const tier2FormObject = {
  // Tier 2 Metadata
  providerName: 'vw_tier2',
  leadSubType: '',
  service: '',
  sourceType: 'vw.dealer',
  subType: ''
};

const formObject = {
  // Meta data
  'brand': 'VW',
  'sourceType': 'vw',
  'subType': ' vw_offers',
  'tag-BrightTagID': isBrowser
    ? isFunction(window.bt_cookie)
      ? window.bt_cookie('BTid')
      : ''
    : '',
  'tag-CampaignID': isBrowser
    ? isFunction(window.bt_cookie)
      ? window.bt_cookie('s_campaign')
      : ''
    : '',
  'tag-OmnitureID': isBrowser
    ? isFunction(window.bt_cookie)
      ? window.bt_cookie('s_vi')
      : ''
    : '',
  'formType': 'lead',

  // User data
  'firstName': '',
  'lastName': '',
  'email': '',
  'phone': '',
  'preferredContactMethod': '',
  'comments': 'mmz:99950',

  // Configuration data
  'formName': '',
  'requestType': '',
  'dealerName': '',
  'dealerId': '',
  'modelName': '',
  'trim': '',
  'year': '',
  'zip': 0
};

export const getSubmitHandler = (
  dealer: DealerModel | undefined,
  offer: OffersModel | undefined,
  setFormState: (state: ModalFormState) => void,
  zipCode: string,
  faServicesUrl: string,
  formName: string,
  tier2: boolean,
  serviceConfigsServiceConfig?: string,
  mockIds?: string,
  mocksBaseUrl?: string,
  trackingManager?: FeatureAppTrackingManager,
  isScheduleService?: boolean,
  requestType?: string,
  testDriveComment?:any
): SubmitFunction => async (values: any) => {
  setFormState({
    state: 'submitting'
  });
  let submitForm = _.clone(formObject);
  let formRequestType: any = getFormType(formName, tier2);

  // Add configuration data to submit values
  submitForm = getConfigurationData(
    submitForm,
    tier2,
    zipCode,
    dealer,
    offer,
    formRequestType
  );

  // Add user data to submit values
  getUserData(submitForm, values, zipCode, tier2, formRequestType, dealer, testDriveComment);

  // Clean unnecesary data and format styled strings
  const sanitizedForm = cleanFormValues(submitForm, tier2);

  // tracking section
  !isScheduleService &&
    onHandleFormSubmitTracking(
      'load',
      requestType,
      formName,
      trackingManager,
      offer,
      dealer,
      sanitizedForm,
      testDriveComment
    );
  isScheduleService &&
    onHandlerServiceFormLayerEvents(
      'load',
      requestType,
      formName,
      trackingManager,
      dealer,
      sanitizedForm,
      undefined,
      offer
    );

  // Submit Form
  submitLead({
    faServicesUrl,
    serviceConfigsServiceConfig,
    mockIds,
    mocksBaseUrl,
    userParams: sanitizedForm
  })
    .then((result: FormResponseState) => {
      let state: 'success' | 'failed';
      if (!result.error && !result.invalid) {
        state = 'success';
        !isScheduleService &&
          onHandleFormSubmitTracking(
            'success',
            requestType,
            formName,
            trackingManager,
            offer,
            dealer,
            sanitizedForm,
            result.data,
            testDriveComment
          );
        isScheduleService &&
          onHandlerServiceFormLayerEvents(
            'success',
            requestType,
            formName,
            trackingManager,
            dealer,
            sanitizedForm,
            result.data,
            offer
          );
      } else {
        state = 'failed';
        // !isScheduleService &&
        //   onHandleFormSubmitTracking(
        //     'error',
        //     requestType,
        //     formName,
        //     trackingManager,
        //     offer,
        //     dealer,
        //     sanitizedForm,
        //     undefined,
        //     undefined,
        //     {message: result.data.message, code: result.data.code}
        //   );
      }
      setFormState({state});
    })
    .catch((error: Error) => {
      console.log(error);
      // !isScheduleService &&
      //   onHandleFormSubmitTracking(
      //     'error',
      //     requestType,
      //     formName,
      //     trackingManager,
      //     offer,
      //     dealer,
      //     sanitizedForm,
      //     undefined,
      //     undefined,
      //     {message: error.message, code: '500'}
      //   );

      setFormState({
        state: 'failed'
      });
    });
};
/**
 *  Add to request values all data related with configuration
 * @param form
 * @param isTier2
 * @param zipCode
 * @param dealer
 * @param offer
 * @param formRequestType
 */
const getConfigurationData = (
  form: any,
  isTier2: boolean,
  zipCode: string,
  dealer?: DealerModel,
  offer?: OffersModel,
  formRequestType?: any
) => {
  const configurationData = {
    dealerId: dealer?.dealerid,
    dealerName: dealer?.name,
    modelName: offer?.modelDisplayName || 'All',
    trim:
      offer?.dealCarTrimName &&
      offer?.dealCarTrimName[0] &&
      offer?.dealCarTrimName[0].length
        ? offer?.dealCarTrimName[0]
        : '',
    year:
      offer?.dealCarYear &&
      offer?.dealCarYear[0] &&
      offer?.dealCarYear[0].length
        ? offer?.dealCarYear[0]
        : '',
    zip: zipCode,
    ...formRequestType
  };

  if (isTier2) {
    const tier2FormCopy = _.clone(tier2FormObject);
    tier2FormCopy.leadSubType = dealer?.lmaId || '';
    tier2FormCopy.subType = dealer?.lmaId || '';
    tier2FormCopy.service = formRequestType.formName;
    Object.assign(form, tier2FormCopy);
  }
  return Object.assign(form, configurationData);
  };

/**
 *  Add data from user to request values, It added needed data to message
 * @param form
 * @param values
 * @param zipCode
 * @param isTier2
 * @param formRequestType
 * @param dealer
 */
const getUserData = (
  form: any,
  values: any,
  zipCode: string,
  isTier2: boolean,
  formRequestType: any,
  dealer?: DealerModel,
  testDriveComment?:any
) => {
  const submitValues = _.clone(values);

  submitValues.comments += getMessageText(
    zipCode,
    isTier2,
    formRequestType.formName,
    dealer?.lmaId
  );

  if(zipCode){
    submitValues.zip = ''+zipCode;
  }

  if(testDriveComment && !isEmpty(testDriveComment.current)){
    submitValues.testdrive=''+testDriveComment.current;
    form.requestType = "Schedule Test Drive";
    form.subType = "Special Offers";
    form.sourceType = "vw.com";
  }

  Object.assign(form, submitValues);
};

/**
 * Delete data if it is unnecesary for the type or request
 * @param form
 * @param isTier2
 */
const cleanFormValues = (form: any, isTier2: boolean) => {
  let cleanedForm = form;

  // Clean phone number or property
  if (!form.phone) {
    cleanedForm = _.omit(cleanedForm, 'phone');
  } else {
    cleanedForm.phone = cleanedForm.phone.replace(/[^\d]/g, '');
  }

  // Clean mail if doens't exist  property
  if (!form.mail) {
    cleanedForm = _.omit(cleanedForm, 'mail');
  }

  // Clean For Service request
  if (!form.year) cleanedForm = _.omit(cleanedForm, 'year');
  if (!form.trim) cleanedForm = _.omit(cleanedForm, 'trim');
  if (!form.modelName) cleanedForm = _.omit(cleanedForm, 'modelName');

  if (isTier2) {
    cleanedForm = _.omit(cleanedForm, 'dealerName');
  }

  return cleanedForm;
};

/**
 * According to form name this extracts request type and if this is on tier 2 or tier 1
 * @param formName
 * @param isTier2
 */
const getFormType = (formName: string, isTier2: boolean): object => {
  const quoteRegex = /quote|service/gi; // Schedule a Service use quote code
  const isQuote: boolean = quoteRegex.test(formName);
  let formType: FormRequestTypes | undefined = undefined;
  if (!isTier2) {
    formType = isQuote
      ? FormRequestTypes.TIER_1_GET_A_QUOTE
      : FormRequestTypes.TIER_1_CONTACT_DEALER;
  } else {
    formType = isQuote
      ? FormRequestTypes.TIER_2_GET_A_QUOTE
      : FormRequestTypes.TIER_2_CONTACT_DEALER;
  }
  return formMetaDataMap[formType];
};

/**
 * Added to current message extra needed data
 * @param zipCode
 * @param isTier2
 * @param formName
 * @param lma
 */
const getMessageText = (
  zipCode: string,
  isTier2: boolean,
  formName?: string,
  lma?: string
): string => {
  let message = ` mmz:${zipCode}`;
  if (isTier2) {
    message += ` vw_tier2. ${formName}. ${lma}`;
  }
  return message;
};
