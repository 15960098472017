import { Breakpoints, useIsMobile } from '@vw-marketing/us-components';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { VehicleCategories } from '../../../..';
import {
  AttributeModel,
  FilterAttributeModel,
} from '../../../../hooks-store/typings/incentive-store';
import { useElementBounding } from '../../../../services/use-element-bounding';
import { useFeatureAppConfig } from '../../../../services/use-feature-app-config';
import { useStickyHeader } from '../../../../services/use-sticky-header';
import { blockScrollAction, categorizeModels } from '../../../../utils/general';
import DropdownContainer from './dropdown-content';
import { labelStickyTextStyle, labelTextStyle } from './helpers';
import { StyledOpacityWrapper } from './styles';

export interface DefaultFilterElement {
  isChecked: boolean;
  isDisabled: boolean;
  key: string;
  value: string;
  total: number;
  type: string;
}

export interface DefaultFiltersModel {
  modelYear?: DefaultFilterElement[];
  models?: DefaultFilterElement[];
}

interface FilterDropdownProps {
  activateMobileFilterModal: (value: boolean) => void;
  appliedFilterAmount: string;
  appliedFilters?: AttributeModel[];
  disabledScroll: (value: boolean) => void;
  filters?: FilterAttributeModel[];
  matchingOffers: number;
  mobileModalFilterActivated: boolean;
  modalActive: boolean;
  onClearAllFilters: () => void;
  setModalState: (state: boolean) => void;
  showOffers: () => void;
}

const FilterButton: React.FC<FilterDropdownProps> = props => {
  const {
    setModalState,
    modalActive,
    filters,
    appliedFilters,
    matchingOffers,
    onClearAllFilters,
    showOffers,
    appliedFilterAmount,
    mobileModalFilterActivated,
    activateMobileFilterModal,
    disabledScroll,
  } = props;

  const firstUpdate = useRef(true);
  const { modelOrder } = useFeatureAppConfig();

  const modelYears = filters?.find(filter => filter.name === 'Model Year');

  const categorizedModels = modelOrder ? categorizeModels(modelOrder): {};

  const [toggle, setToggle] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [contentTopOffset, setContentTopOffset] = useState(0);
  const [elementBounding, dropdownContentRef] = useElementBounding();
  const [labelStyle, setLabelStyle] = React.useState(labelTextStyle);
  const { isSticky, isScrollDirectionUp } = useStickyHeader();
  const isMobile = useIsMobile(Breakpoints.b560);

  const handleMouseDown = (event: any) => {
    const isNotMobileViewport = window.matchMedia(
      `(min-width: ${Breakpoints.b560}px)`,
    ).matches;
    const otherDropdownsState = _.filter(
      dropdownRef?.current?.parentElement?.children,
      child =>
        child.contains(event.target) &&
        !child.classList.contains('filter-active-layer') &&
        !child.classList.contains('filter-close-button'),
    );
    const isClickedDropdown =
      !isNotMobileViewport && otherDropdownsState.length;
    const noContainerElement = isNotMobileViewport || isClickedDropdown;

    !dropdownRef?.current?.contains(event.target) &&
      noContainerElement &&
      setToggle(false);
  };

  const calculateContentOffset = () => {
    elementBounding && setContentTopOffset(elementBounding.bottom);
  };

  const onViewOffers = () => {
    showOffers();
    setToggle(false);
    activateMobileFilterModal(false);
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      document.addEventListener('mousedown', handleMouseDown, false);
      document.addEventListener('resize', calculateContentOffset, false);
    }
    return () => {
      if (typeof window !== 'undefined') {
        document.removeEventListener('mousedown', handleMouseDown, false);
        document.removeEventListener('resize', calculateContentOffset, false);
      }
    };
  }, []);

  useEffect(() => {
    calculateContentOffset();
  }, [elementBounding]);

  useEffect(() => {
    setLabelStyle(isSticky ? labelStickyTextStyle : labelTextStyle);
  }, [isSticky]);

  useEffect(() => {
    toggle && calculateContentOffset();
    blockScrollAction(toggle);
    isMobile && toggle && setModalState(true);

    // Create tagging
    if (firstUpdate.current) {
      firstUpdate.current = false;
    } else {
      // TODO: ADD TAGGING
    }
    return () => {
      blockScrollAction(false);
    };
  }, [toggle]);

  const onHandleCloseButton = () => {
    setToggle(false);
    setModalState(false);
    activateMobileFilterModal(false);
    disabledScroll(false);
  };

  const onHandleToggle = () => {
    setToggle(!toggle);
    isMobile && toggle && setModalState(false);
  };

  return (
    <div>
      {(toggle || mobileModalFilterActivated) && (
        <StyledOpacityWrapper
          className="filter-active-layer"
          isLongerBar={isScrollDirectionUp}
          isSticky={isSticky}
          topOffset={contentTopOffset}
          modalActive={true}
        />
      )}
      <DropdownContainer
        toggle={toggle}
        modalActive={modalActive}
        filters={filters}
        appliedFilters={appliedFilters}
        onHandleToggle={onHandleToggle}
        labelStyle={labelStyle}
        onViewOffers={onViewOffers}
        onClearAllFilters={onClearAllFilters}
        appliedFilterAmount={appliedFilterAmount}
        matchingOffers={matchingOffers}
        mobileModalFilterActivated={mobileModalFilterActivated}
        modelYears={modelYears}
        categorizedModels={categorizedModels}
        dropdownRef={dropdownRef}
        dropdownContentRef={dropdownContentRef}
        elementBounding={elementBounding}
        isSticky={isSticky}
        onHandleCloseButton={onHandleCloseButton}
      />
    </div>
  );
};

export default FilterButton;
