import React from 'react';
import { DisclaimerConfiguration } from '../typings/offer-disclaimer';

export const DisclaimerConfigurationContext = React.createContext<
  DisclaimerConfiguration | undefined
>(undefined);

export function useDisclaimerConfiguration(): DisclaimerConfiguration {
  const disclaimerConfiguration = React.useContext(
    DisclaimerConfigurationContext,
  );

  if (!disclaimerConfiguration) {
    throw new Error('No disclaimerConfiguration context value was provided.');
  }

  return disclaimerConfiguration;
}
