import React from 'react';
import { StyledTagContainer } from '../styled';
import {
  createTagOverride,
  Link,
  Richtext,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextTag,
  TextWeight,
} from '@vw-marketing/us-components';

interface ModelTagPros {
  background?: string;
  label: string;
  markdownText?: string;
}
// appearance={TextAppearance.headline200}
// tag={TextTag.span}
// style={{
//   [Breakpoints.default]: {
//     fontWeight: TextWeight.bold,
//     fontSize: TextSize.TextSize16,
//     lineHeight: TextLineHeight.TextLineHeight24,
//   },
// }}
const textStyle = {
  appearance: TextAppearance.headline200,
  tag: TextTag.span,
  style: {
    fontWeight: TextWeight.regular,
    fontSize: TextSize.TextSize16,
    lineHeight: TextLineHeight.TextLineHeight24,
  },
};
const textStyleStrong = {
  appearance: TextAppearance.headline200,
  tag: TextTag.strong,
  style: {
    fontWeight: TextWeight.bold,
    fontSize: TextSize.TextSize16,
    lineHeight: TextLineHeight.TextLineHeight24,
  },
};
const bigTextStyle = {
    appearance: TextAppearance.headline200,
    tag: TextTag.span,
    style: {
      fontWeight: TextWeight.bold,
      fontSize: TextSize.TextSize18,
      lineHeight: TextLineHeight.TextLineHeight24,
    },
  };
const textStyleOverrides = {
  a: createTagOverride(Link),
  div: createTagOverride(TextCustom, textStyle),
  span: createTagOverride(TextCustom, textStyle),
  strong: createTagOverride(TextCustom, textStyleStrong),
  p: createTagOverride(TextCustom, textStyle),
  h1: createTagOverride(TextCustom, bigTextStyle),
  h2: createTagOverride(TextCustom, bigTextStyle),
  h3: createTagOverride(TextCustom, bigTextStyle),
};
const ModelTag: React.FC<ModelTagPros> = ({
  background,
  label,
  markdownText,
}) => {
  if (!label) return <></>;
  return (
    <StyledTagContainer background={background}>
      {markdownText ? (
        <Richtext markdown={markdownText} overrides={textStyleOverrides} dangerouslyParseRawHTML/>
      ) : (
        <TextCustom {...textStyleStrong}>{label}</TextCustom>
      )}
    </StyledTagContainer>
  );
};

export default ModelTag;
