import {
  BaseFormInfoConfigModel,
  Breakpoints,
  DynamicForm,
  Layout,
  baseFormInfoConfig,
  defaultFields
} from '@vw-marketing/us-components';
import React from 'react';
import {
  DealerModel,
  OffersModel
} from '../../../../hooks-store/typings/incentive-store';
import {toCamelCase} from '../../../../utils/general';
import CustomFormHeader from './custom-form-header';
import OfferInformation from './offer-information';
import {
  StyledFormContainer, 
  StyledOfferInfoContainer,
  StyledTestCheckWrapper,
  StyledTestScheduleWrapper,
  StyledTestScheduleMainSection
} from './styled';
import {AppConstants} from '../../../../utils/app-constants';
import {useModelStructure} from '../../../../services/use-model-structure';
import {ModelStructure} from '../../../../typings/general';
import _ from 'lodash';
import {FeatureAppTrackingManager} from '../../../../context/use-tracking-manager';
import { onBasicLinkTrackingEvent } from '../../../../utils/tagging-helpers';
import { useTranslations } from '../../../../services/use-translations';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import {useFeatureAppConfig} from '../../../../services/use-feature-app-config';

import moment from 'moment';


interface ModalFormContainerProps {
  tier2: boolean;
  offer?: OffersModel;
  dealer?: DealerModel;
  formType: string;
  formName: string;
  handleClose: () => void;
  trackingManager: FeatureAppTrackingManager;
  updateTestDriveComment:any;
}

const ModalFormContainer: React.FC<ModalFormContainerProps> = (
  props
): JSX.Element => {
  const {
    tier2,
    offer,
    dealer,
    formType,
    formName,
    handleClose,
    trackingManager,
    updateTestDriveComment
  } = props;

  const { defaultLegalDisclaimer } = useTranslations();
  const getCustomFormInfoConfig = (): BaseFormInfoConfigModel => ({
    ...baseFormInfoConfig,
    legalDisclaimer: defaultLegalDisclaimer || baseFormInfoConfig.legalDisclaimer,
    formName: toCamelCase(formName),
    formLabel: formName
  });

  /**
   * Display dropdown inputs if it's in tier2 and service form
   */
  const showModelandTrim =
    formType === AppConstants.ScheduleServiceFormType ||
    formType === AppConstants.ScheduleServiceDetailFormType;
  let modelStructure: ModelStructure | undefined = undefined;
  if (tier2 && showModelandTrim) modelStructure = useModelStructure();
  const isNotStandard = AppConstants.NotStandardModelFormType === formType;
  const isNationalOffersOnly = AppConstants.NationalOffersOnlyFormType === formType;
  const hideForm = isNotStandard || isNationalOffersOnly;
  let desktopLayout = hideForm ? [
    { name: '.', columns: 6 },
    { name: 'a', columns: 12},
    { name: '.', columns: 6}
  ] :[
    {name: 'a', columns: 11},
    {name: '.', columns: 2},
    {name: 'b', columns: 11}
  ];

  if (showModelandTrim) {
    desktopLayout = [
      {name: 'a', columns: 10},
      {name: '.', columns: 2},
      {name: 'b', columns: 12}
    ];
  }
  const onHandleDealerUrlTracking = (name: string) => {
    onBasicLinkTrackingEvent(offer, name, dealer?.url, trackingManager, undefined, dealer, formType)
  }

  const {
    testDriveQuestion,
    testDriveScheduleLabel,
    testDriveTimeLabel,
    enableTestDrive
  } = useFeatureAppConfig();


  let testDriveQuestionText = "Do you want to schedule test drive?"
  if(testDriveQuestion){
    testDriveQuestionText = testDriveQuestion;
  }

  let testDriveScheduleLabelText = "Schedule a test drive"
  if(testDriveScheduleLabel){
    testDriveScheduleLabelText = testDriveScheduleLabel;
  }

  let testDriveTimeLabelText = "Time"
  if(testDriveTimeLabel){
    testDriveTimeLabelText = testDriveTimeLabel;
  }

  let enableTestDriveUI = false;
  if (enableTestDrive && formType === AppConstants.ContactDealerFormType) {
    enableTestDriveUI = enableTestDrive;
  }


  const [testDriveCheck, setTestDriveCheck] = React.useState(false);

  const [timeslot, setTimeslot] = React.useState('Morning');

  const [timeSchedule, setTimeSchedule] = React.useState(
    moment().add(2, 'days'),
  );
  
  const handleChangeTest = () => {
    setTestDriveCheck(!testDriveCheck);

    if (testDriveCheck) {
      updateTestDriveComment(false);
    } else {
      updateTestDriveComment(timeSchedule.format("YYYY-MM-DD") + ':' + timeslot.toUpperCase());
    }
  };

  const handleTimeScheduleChange = val => {
    setTimeSchedule(val);

    if (testDriveCheck) {
      updateTestDriveComment(val.format("YYYY-MM-DD") + ':' + timeslot.toUpperCase());
    } else {
      updateTestDriveComment(false);
    }

    // console.log(timeSchedule);
  };

  const handleTimeChange = (event: SelectChangeEvent) => {
    setTimeslot(event.target.value);

    if (testDriveCheck) {
      updateTestDriveComment(timeSchedule.format("YYYY-MM-DD") + ':' + event.target.value.toUpperCase());
    } else {
      updateTestDriveComment(false);
    }
  };


  const Checkbox = ({ label, value, onChange }) => {
    return (
      <label>
        <input type="checkbox" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };


  let fields: any = defaultFields;
  fields.comments.maxLenght= 950;


  return (
    <Layout
      appearance={{
        [Breakpoints.default]: [
          {name: 'a', columns: 24},
          {name: 'b', columns: 24}
        ],
        [Breakpoints.b960]: desktopLayout
      }}
      allowOverflowingContent
    >
      <StyledOfferInfoContainer>
        <OfferInformation
          tier2={tier2}
          dealer={dealer}
          offer={offer}
          formType={formType}
          handleClose={handleClose}
          onHandleDealerUrlTracking={onHandleDealerUrlTracking}
        />
      </StyledOfferInfoContainer>
      { !hideForm ? 
      <StyledFormContainer>
        <DynamicForm
          fields={fields}
          config={getCustomFormInfoConfig()}
          modelDropdownOptions={showModelandTrim && modelStructure}
        >
          <CustomFormHeader
            dealer={dealer}
            formType={formType}
            formName={formName}
            onHandleDealerUrlTracking={onHandleDealerUrlTracking}
          />

          <StyledTestScheduleMainSection className={
              enableTestDriveUI
                ? ''
                : 'notdisplay'
            }>

              <StyledTestCheckWrapper>
                <Checkbox
                  label={testDriveQuestionText}
                  value={testDriveCheck}
                  onChange={handleChangeTest}
                />
              </StyledTestCheckWrapper>

            
              <StyledTestScheduleWrapper
                className={
                  testDriveCheck
                    ? 'contact-dealer-test-picker'
                    : 'contact-dealer-test-picker notdisplay '
                }
              >
                <MobileDateTimePicker
                  label={testDriveScheduleLabelText}
                  disablePast={true}
                  defaultValue={timeSchedule}
                  disabled={!testDriveCheck}
                  ampmInClock={false}
                  ampm={false}
                  onChange={newValue => handleTimeScheduleChange(newValue)}
                  views={['year', 'month', 'day']}
                  maxDate={moment().add(6, 'months')}
                  minDate={moment().add(1, 'days')}
                  slotProps={{
                    tabs: {
                      hidden: true,
                    },
                    toolbar: { hidden: true },
                  }}
                  viewRenderers={{
                    hours: null,
                    minutes: null,
                    seconds: null,
                  }}
                />

                <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                  <InputLabel id="demo-select-small-label">{testDriveTimeLabelText}</InputLabel>
                  <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={timeslot}
                    label="Select a time"
                    onChange={handleTimeChange}
                  >
                    <MenuItem value={'Morning'}>Morning</MenuItem>
                    <MenuItem value={'Afternoon'}>Afternoon</MenuItem>
                    <MenuItem value={'Evening'}>Evening</MenuItem>
                  </Select>
                </FormControl>

              </StyledTestScheduleWrapper>


              
              
          </StyledTestScheduleMainSection>  



        
        </DynamicForm>
      </StyledFormContainer>
      : undefined
  }
    </Layout>
  );
};

export default ModalFormContainer;
