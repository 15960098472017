import {
  Breakpoints,
  Container,
  ContainerGutter,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight,
  useIsMobile,
} from '@vw-marketing/us-components';
import React from 'react';
import { useTranslations } from '../../../../services/use-translations';
import { StyledOffersAmount } from './styled';

interface MatchingOffersContainerProps {
  matchingOffers: number;
  isVisible?: boolean;
}

export const MatchingOffersContainer: React.FC<MatchingOffersContainerProps> = props => {
  const { matchingOffers, isVisible } = props;
  const translations = useTranslations().offersSection.filterToolbar;
  const isTablet = useIsMobile(Breakpoints.b960);

  return (
    <StyledOffersAmount
      isTablet={isTablet}
      isVisible={isVisible}
      className="matching-offers-container"
    >
      <Container wrap="auto" gutter={ContainerGutter.static150}>
        <TextCustom
          appearance={TextAppearance.copy100}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.regular,
              fontSize: TextSize.TextSize14,
              lineHeight: TextLineHeight.TextLineHeight24,
            },
            [Breakpoints.b960]: {
              fontWeight: TextWeight.regular,
              fontSize: TextSize.TextSize16,
              lineHeight: TextLineHeight.TextLineHeight24,
            },
            [Breakpoints.b1280]: {
              fontWeight: TextWeight.regular,
              fontSize: TextSize.TextSize20,
              lineHeight: TextLineHeight.TextLineHeight32,
            },
          }}
        >
          {translations.showing}
        </TextCustom>
        <TextCustom
          appearance={TextAppearance.copy100}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.bold,
              fontSize: TextSize.TextSize14,
              lineHeight: TextLineHeight.TextLineHeight24,
            },
            [Breakpoints.b960]: {
              fontWeight: TextWeight.bold,
              fontSize: TextSize.TextSize16,
              lineHeight: TextLineHeight.TextLineHeight24,
            },
            [Breakpoints.b1280]: {
              fontWeight: TextWeight.bold,
              fontSize: TextSize.TextSize20,
              lineHeight: TextLineHeight.TextLineHeight32,
            },
          }}
        >
          {` ${matchingOffers}`}
        </TextCustom>
        <TextCustom
          appearance={TextAppearance.copy100}
          style={{
            [Breakpoints.default]: {
              fontWeight: TextWeight.regular,
              fontSize: TextSize.TextSize14,
              lineHeight: TextLineHeight.TextLineHeight24,
            },
            [Breakpoints.b960]: {
              fontWeight: TextWeight.regular,
              fontSize: TextSize.TextSize16,
              lineHeight: TextLineHeight.TextLineHeight24,
            },
            [Breakpoints.b1280]: {
              fontWeight: TextWeight.regular,
              fontSize: TextSize.TextSize20,
              lineHeight: TextLineHeight.TextLineHeight32,
            },
          }}
        >
          {matchingOffers === 1 ? ` matching offer` : ` matching offers`}
        </TextCustom>
      </Container>
    </StyledOffersAmount>
  );
};
