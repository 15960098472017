import React from 'react';
import { StickyHeader } from '../../components/sticky-header';
import { useStore } from '../../hooks-store/store';
import { useFeatureAppConfig } from '../../services/use-feature-app-config';
import { FilterContainerSection } from './filter-container-section';
import { LeftContainerSection } from './left-container-section';

import { BottomContainerSection } from './bottom-container-section/BottomContainerSection';
import { RightContainerSection } from './right-container-section';
import {
  LayoutWrapper,
  OverflowWrapper,
  StyledBottomContainer,
  StyledLeftContainer,
  StyledOffersContainer,
  StyledOffersSectionWrapper,
  StyledRightContainer,
} from './styled';

export const OffersSection = () => {
  const store = useStore()[0];
  const { specialOffersRedesignEnable } = useFeatureAppConfig();

  return (
    <OverflowWrapper>
      <LayoutWrapper isTier2={store.tier2 ? true : false}>
        <StyledOffersSectionWrapper
          specialOffersRedesignEnable={specialOffersRedesignEnable}
        >
          {specialOffersRedesignEnable ? (
            <StyledOffersContainer>
              <StickyHeader noPadding>
                <FilterContainerSection />
              </StickyHeader>
              <StyledBottomContainer>
                <BottomContainerSection />
              </StyledBottomContainer>
            </StyledOffersContainer>
          ) : (
            <>
              <StyledLeftContainer>
                <LeftContainerSection />
              </StyledLeftContainer>
              <StyledRightContainer>
                <RightContainerSection />
              </StyledRightContainer>
            </>
          )}
        </StyledOffersSectionWrapper>
      </LayoutWrapper>
    </OverflowWrapper>
  );
};
