import {
  Breakpoints,
  TextAppearance,
  TextCustom,
  TextLineHeight,
  TextSize,
  TextWeight
} from '@vw-marketing/us-components';
import React from 'react';
import {useStore} from '../../../hooks-store/store';
import {StyledInfoBlockContainer} from './styled';
import {useTranslations} from '../../../services/use-translations';

export const InfoContent: React.FC = (): JSX.Element => {
  const [store] = useStore();
  const translations = useTranslations().offersSection.infoContent;

  return (
    <StyledInfoBlockContainer>
      <TextCustom
        appearance={TextAppearance.headline200}
        style={{
          [Breakpoints.default]: {
            fontSize: TextSize.TextSize20,
            lineHeight: TextLineHeight.TextLineHeight24,
            fontWeight: TextWeight.light
          }
        }}
      >
        {translations.showing}
      </TextCustom>

      <TextCustom
        appearance={TextAppearance.headline200}
        style={{
          [Breakpoints.default]: {
            fontSize: TextSize.TextSize32,
            lineHeight: TextLineHeight.TextLineHeight36,
            fontWeight: TextWeight.bold
          }
        }}
      >
        {store.dealer && store.dealer.name}
      </TextCustom>
    </StyledInfoBlockContainer>
  );
};
