import {
  CArrowLeft,
  CArrowRight,
  DisableFocusWrapper,
  LinkButton,
  Text,
  TextAppearance
} from '@vw-marketing/us-components';
import React from 'react';
import {StyledArrowIconWrapper} from './styled';

interface Props {
  readonly marginLeft?: string;
  readonly marginRight?: string;

  readonly arrowDirection?: string;
  readonly iconSize?: string;
  readonly label: string;
  readonly breadcrumbEvent: () => void;
}

export const Breadcrumb: React.FC<Props> = (props: Props): JSX.Element => {
  const {
    marginLeft,
    marginRight,
    arrowDirection,
    label,
    iconSize,
    breadcrumbEvent
  } = props;

  const getArrow = () =>
    arrowDirection && arrowDirection.toLowerCase() === 'right' ? (
      <>
        {label} <CArrowRight iconSize={iconSize} />
      </>
    ) : (
      <>
        <CArrowLeft iconSize={iconSize} /> {label}
      </>
    );

  return (
    <Text appearance={TextAppearance.headline200} bold>
      <DisableFocusWrapper>
        <LinkButton onClick={() => breadcrumbEvent()}>
          <StyledArrowIconWrapper
            marginLeft={marginLeft}
            marginRight={marginRight}
          >
            {getArrow()}
          </StyledArrowIconWrapper>
        </LinkButton>
      </DisableFocusWrapper>
    </Text>
  );
};

{
  /*
    HOW USE THIS COMPONENT
    <Breadcrumb
      marginLeft="10" // px, not default value, ins't required
      marginRight="10" // px, 10 is a default value, ins't required
      arrowDirection="right" // left is the default value, ins't required
      label="Change model" // the link name, is required
      iconSize="30" // 30 is a default value, isn't required
      breadcrumbEvent={() => alert("example")} // pass a function, is required
    />
  */
}
