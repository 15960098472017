import React from 'react';
import { MarkerContainer, PinContainer, PinMarkerText } from './styled';
//import Tooltip from 'react-simple-tooltip'; removed due to lack of support for react 18
import { DealerModel } from '../../../../../../hooks-store/typings/incentive-store';
import { AppConstants } from '../../../../../../utils/app-constants';
import {
  TextCustom,
  TextAppearance,
  TextSize,
  TextLineHeight,
  TextWeight,
} from '@vw-marketing/us-components';
interface MarkerProps {
  dealer: DealerModel;
  setNewDealer: (
    dealer: DealerModel,
    showModal: boolean,
    isTracking?: boolean,
  ) => void;
  active: boolean;
  lat: number;
  lng: number;
  faHostServerUrl: string;
}

const Marker = ({
  dealer,
  setNewDealer,
  active,
  faHostServerUrl,
}: MarkerProps) => {
  const imageStyle: React.CSSProperties = { position: 'relative', zIndex: -1 };

  return (
    <MarkerContainer
      className={active && 'active'}
      name={dealer.name}
      lat={parseFloat(dealer.lat || '0')}
      lng={parseFloat(dealer.lng || '0')}
    >
      {/* <Tooltip
        onClick={() => setNewDealer(dealer, true, true)}
        style={{
          position: 'absolute',
          whiteSpace: 'nowrap',
          transform: 'translate(-50%, -100%)',
        }}
        content={dealer.name}
        placement="right"
        zIndex={1}
        background="#FFFFFF"
        offset={-15}
        padding={5}
        color="black"
        radius={14.6}
      >
        {active ? (
          <PinContainer>
            <PinMarkerText>
              <TextCustom
                appearance={TextAppearance.headline200}
                style={{
                  fontSize: TextSize.TextSize10,
                  lineHeight: TextLineHeight.TextLineHeight24,
                  fontWeight: TextWeight.bold,
                }}
              >
                {dealer.name}
              </TextCustom>
            </PinMarkerText>
            <img
              style={imageStyle}
              src={faHostServerUrl + AppConstants.MapMarkerPinActive}
              alt={dealer.name}
            />
          </PinContainer>
        ) : (
          <img
            style={imageStyle}
            src={faHostServerUrl + AppConstants.MapMarkerPin}
            alt={dealer.name}
          />
        )}
      </Tooltip> */}
    </MarkerContainer>
  );
};

export default Marker;
