import {FATranslations} from '../typings/general';
import React from 'react';

export const TranslationsContext = React.createContext<
FATranslations | undefined
>(undefined);

export function useTranslations(): FATranslations {
  const translations = React.useContext(TranslationsContext);

  if (!translations) {
    throw new Error('No translations context value was provided.');
  }

  return translations;
}
