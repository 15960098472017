import { Breakpoints, styled } from '@vw-marketing/us-components';

interface StyledFilterDropdownButtonProps {
  isSticky?: boolean;
  modalActive?: boolean;
  isActive?: boolean;
  isMobile?: boolean;
}

interface ChevronIconProps {
  hideOptions: boolean;
}

export const StyledModelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
`;

export const StyledContainer = styled.div`
  margin-bottom: 15px;

  & > :first-child {
    margin-bottom: 5px;

    @media (min-width: ${Breakpoints.b560}px) {
      margin-bottom: 15px;
    }
  }

  @media (min-width: ${Breakpoints.b560}px) {
    margin-bottom: 32px;
  }

  @media (min-width: ${Breakpoints.b960}px) {
    margin-bottom: 35px;
  }
`;

export const StyledVehicleCategories = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: ${Breakpoints.b960}px) {
    flex-direction: column;
    gap: 24px;
  }
`;

export const StyledColorCheckboxes = styled.div<
  StyledFilterDropdownButtonProps
>`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 15px;
  width: 283px;

  @media (min-width: ${Breakpoints.b560}px) {
    justify-content: flex-start;

    & > div {
      margin-right: 20px;
    }

    & > :nth-child(5n) {
      margin-right: 0;
    }
  }

  @media (min-width: ${Breakpoints.b960}px) {
    & > :nth-child(5n) {
      margin-right: 20px;
    }

    & > :nth-child(3n) {
      margin-right: 0;
    }
  }

  @media (min-width: ${Breakpoints.b1280}px) {
    justify-content: flex-start;

    ${({ isSticky }) => `
      ${isSticky &&
        `
          & > :nth-child(3n) {
            margin-right: 20px;
          }

          & > :nth-child(5n) {
            margin-right: 0;
          }
        `}
    `}
  }

  @media (min-width: ${Breakpoints.b1920}px) {
    justify-content: flex-start;

    ${({ isSticky }) => `
      ${
        isSticky
          ? `
          & > :nth-child(5n) {
            margin-right: 20px;
          }

          & > :nth-child(6n) {
            margin-right: 0;
          }
        `
          : `
          & > :nth-child(3n) {
            margin-right: 20px;
          }

          & > :nth-child(4n) {
            margin-right: 0;
          }
        `
      }
    `}
  }
`;

export const StyledFilterDropdownButton: any = styled.button<
  StyledFilterDropdownButtonProps
>`
  background: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #001e50;
  justify-content: center;
  border: none;

  & > .filter-models-icon {
    display: none;
    cursor: pointer;
  }

  @media (min-width: ${Breakpoints.b560}px) {
    border: none;
    justify-content: space-between;

    & > .filter-models-icon {
      display: block;
    }

    &:focus {
      border: none;
    }
  }
`;

export const StyledFilterLabelContainer = styled.div<
  StyledFilterDropdownButtonProps
>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '16px' : '0')};
`;

export const StyledIcon = styled.span<ChevronIconProps>`
  display: flex;
  justify-content: flex-end;
  padding-bottom: 2px;
  pointer-events: none;
  .animation svg {
    transition: transform 0.5s ease;
    transform: ${({ hideOptions }) =>
      !hideOptions ? `rotate(180deg)` : `rotate(0deg)`};
  }
`;
