import React from 'react';

import {useTranslations} from '../../services/use-translations';
import {
  StyledCode,
  StyledContainer,
  StyledMessge,
  StyledWrapper
} from './styles';

interface ErrorComponentModel {
  readonly message1?: string;
  readonly message2?: string;
  readonly customHeight?: string;
}

export const ErrorComponent: React.FC<ErrorComponentModel> = ({
  message1,
  message2,
  customHeight
}) => {
  const translations = useTranslations().errors;

  return (
    <StyledWrapper customHeight={customHeight}>
      <StyledContainer>
        <StyledCode>{message1 ? message1 : 'Error'}</StyledCode>
        <StyledMessge>
          {message2 ? message2 : translations.defaultErrorMessage}
        </StyledMessge>
      </StyledContainer>
    </StyledWrapper>
  );
};
