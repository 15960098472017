import GoogleMapReact from 'google-map-react';
import React, {ReactNode} from 'react';
import {AppConstants} from '../../utils/app-constants';
import {MapWrapper} from './styled';

// const VW_GOOGLE_MAP_API_KEY = process.env.VW_GOOGLE_MAP_API_KEY;

interface GoogleMapProps {
  zoom: number;
  center: {
    lat: number;
    lng: number;
  };
  dealers: any[];
  children: ReactNode[];
}

const GoogleMap = ({zoom, center, dealers, children}: GoogleMapProps) => {
  // Fit map to its bounds after the api is loaded
  const apiIsLoaded = (map: any, maps: any, pDealers: any) => {
    // Get bounds by our dealers
    const bounds = getMapBounds(maps, pDealers);
    // Fit map to bounds
    map.fitBounds(bounds);
    map.setCenter(center);
    map.setZoom(zoom);
    // Bind the resize listener
    bindResizeListener(map, maps, bounds);
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map: any, maps: any, bounds: any) => {
    maps.event.addDomListenerOnce(map, 'idle', () => {
      maps.event.addDomListener(window, 'resize', () => {
        map.fitBounds(bounds);
        map.setZoom(zoom);
      });
    });
  };

  // Return map bounds based on list of places
  const getMapBounds = (maps: any, pDealers: any[]) => {
    const bounds = new maps.LatLngBounds();
    pDealers.forEach(dealer => {
      bounds.extend(new maps.LatLng(dealer.lat, dealer.lng));
    });

    return bounds;
  };

  return (
    <MapWrapper>
      <GoogleMapReact
        bootstrapURLKeys={{key: AppConstants.GoogleApiKey}}
        zoom={zoom}
        center={center}
        onGoogleApiLoaded={({map, maps}) => apiIsLoaded(map, maps, dealers)}
      >
        {children}
      </GoogleMapReact>
    </MapWrapper>
  );
};

export default GoogleMap;
