import {Breakpoints, styled} from '@vw-marketing/us-components';

export const StyledFilterIconSection: any = styled.div`
  @media (min-width: ${Breakpoints.b960}px) {
    display: none;
  }
`;

export const StyledIconWrapper: any = styled.div`
  display: flex;
  align-items: center;
  border-radius: 22px;
  border: solid 1px #001e50;
  padding: 7px 12px;
  margin: 3px 0 2px;

  svg {
    margin-right: 5px;
  }
`;
