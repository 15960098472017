import {ErrorHandlingModel} from '../hooks-store/typings/incentive-store';

export const validateError = (
  errorHandling: ErrorHandlingModel[],
  errorCode: string
) => {
  if (!errorHandling.length) {
    return false;
  }

  const codesArray = errorHandling.reduce(
    (next: any, ele: ErrorHandlingModel) => [...next, ele.errorCode],
    []
  );

  return codesArray.includes(errorCode);
};
