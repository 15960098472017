import React from 'react';
import { Breakpoints, Richtext, useIsMobile } from '@vw-marketing/us-components';
import { IconProps } from '../../icons/typing';
import { interstitialCardOverrides } from './helpers';
import {
  StyledInterstitialCard,
  StyledInterstitialContent,
  StyledInterstitialIcon,
  StyledInterstitialStepContainer,
} from './styles';

interface InterstitialCardProps {
  readonly classes?: string;
  Icon: React.FC<IconProps>;
  readonly description: string;
}

const InterstitialCard: React.FC<InterstitialCardProps> = props => {
  const { classes, Icon, description } = props;
  const isTablet = useIsMobile(Breakpoints.b960);
  return (
    <StyledInterstitialStepContainer className={classes}>
      <StyledInterstitialCard className="interstitial-card-content">
        <StyledInterstitialIcon className="interstitial-card-icon">
          {<Icon iconSize={isTablet ? 20 : 45} />}
        </StyledInterstitialIcon>
        <StyledInterstitialContent className="interstitial-card-description">
          <Richtext
            markdown={description}
            overrides={interstitialCardOverrides}
            dangerouslyParseRawHTML
          />
        </StyledInterstitialContent>
      </StyledInterstitialCard>
    </StyledInterstitialStepContainer>
  );
};

export default InterstitialCard;
