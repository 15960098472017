import { IpToZip } from '@volkswagen-onehub/zip-manager';
import { History } from 'history';
import isBrowser from 'is-browser';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { localModelOrder } from '../../../config/tier-1/model-order';
import {
  fetchInitialDataCSR,
  fetchInitialDataSSR,
} from '../../../fetch-data/tier-1';
import IncentiveConstants from '../../../hooks-store/incentive-store/actions/incentive-constants';
import { useStore } from '../../../hooks-store/store';
import { getStringifiedServiceConfigsServiceConfig } from '../../../services/get-stringified-service-configs-service-config';
import { useFeatureAppConfig } from '../../../services/use-feature-app-config';
import { useFeatureServices } from '../../../services/use-feature-services';
import { useIsNationalOffers } from '../../../services/use-is-national-offer';
import { ShowroomModel } from '../../../typings/general';
import { AppConstants } from '../../../utils/app-constants';
import { manageClassById } from '../../../utils/general';
import { getUrlParam, getUrlZip } from '../../../utils/navigation-url';
import { ErrorComponent } from '../../error-component';
import { PageLoad } from './page-load';

interface HandleDataLoadProps {
  readonly children: React.ReactNode;
  readonly baseUrl?: string;
  readonly page: string;
  readonly showRoomModel?: ShowroomModel;
  readonly modelKey?: string;
}

// This variable helps to prevent unnecessary calls to the endpoints
let fetchDataInProcess = false;

const HandleDataLoad: React.FC<HandleDataLoadProps> = (
  props,
): JSX.Element | null => {
  // Component props
  const { children, page, modelKey } = props;
  const isNationalOffer = useIsNationalOffers();
  // Call different services used to apply fetches and update the store data
  const {
    faServicesUrl,
    mockIds,
    mocksBaseUrl,
    modelOrder: modelOrderConfig,
    mappedTrimName,
    specialEventConfig,
  } = useFeatureAppConfig();
  const history = useHistory();
  const modelOrder = modelOrderConfig || localModelOrder;
  const {
    'locale-service': localeService,
    'service-config-provider': serviceConfigProvider,
    's2:server-request': serverRequest,
    'zip-manager': zipManager,
  } = useFeatureServices();

  const {
    configs: { 'iptozip': ipToZip, 'graphql-server': graphQLServer },
  } = serviceConfigProvider;

  const serviceConfigsServiceConfig = getStringifiedServiceConfigsServiceConfig(
    serviceConfigProvider,
    serverRequest,
  );
  const [urlZip] = useState(getUrlZip(history));
  const [urlOffer] = useState<any>(getUrlParam(history, 'offerId'));

  // App store
  const [
    store,
    { [IncentiveConstants.SetInitialData]: setInitialData },
  ] = useStore();

  useEffect(() => {
    if (page === AppConstants.PageLandingTier1) {
      manageClassById(
        true,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    } else {
      manageClassById(
        false,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    }
  }, [page]);

  // Result of fetch data in the SSR
  let initialData: any = null;
  // Tracking on SSR

  useEffect(() => {
    if (page === AppConstants.PageLandingTier1) {
      manageClassById(
        true,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    } else {
      manageClassById(
        false,
        AppConstants.FeatureAppSectionId,
        AppConstants.OffersFAClass,
      );
    }
  }, [page]);

  try {
    // Get data using SSR or CSR according to the app load
    if (!store.status && !isBrowser && !fetchDataInProcess) {
      fetchDataInProcess = true;
      initialData = fetchInitialDataSSR(
        page,
        urlZip,
        faServicesUrl,
        mockIds,
        mocksBaseUrl,
        modelOrder,
        mappedTrimName,
        specialEventConfig,
      );
    } else if (!store.status && isBrowser && !fetchDataInProcess) {
      fetchDataInProcess = true;
      fetchInitialDataCSR({
        page,
        zip:
          (!isNationalOffer && (urlZip || zipManager.getZipCookie())) ||
          undefined,
        localeService,
        serviceConfigsServiceConfig: serviceConfigsServiceConfig as string,
        zipManager,
        setInitialData,
        history: history as History,
        faServicesUrl,
        mockIds,
        mocksBaseUrl,
        modelOrder,
        mappedTrimName,
        ipToZip: ipToZip as IpToZip,
        isNationalOffer: isNationalOffer,
        specialEvent: specialEventConfig,
        graphQLServer,
        modelKey,
      });
    }
  } catch (error) {
    return <ErrorComponent />;
  }

  // Validate the application data before rendering the components
  const applicationData = initialData ? initialData : store;

  // If some error is detected an error page should be shown to the users
  if (
    applicationData &&
    applicationData.errorHandling.length &&
    !applicationData.zipNoDealers
  ) {
    return <ErrorComponent />;
  }

  return (
    <>
      {applicationData &&
        applicationData.status === AppConstants.StoreStatusLoaded &&
        faServicesUrl !== '' && (
          <PageLoad
            page={page}
            applicationData={applicationData}
            urlZip={urlZip}
            offerId={urlOffer}
            modelKey={modelKey}
            isNationalOffers={isNationalOffer}
            specialEvent={specialEventConfig}
          >
            {(fetchDataInProcess = false)}
            {children}
          </PageLoad>
        )}
      {faServicesUrl === '' && 'Error - no faservice'}
    </>
  );
};

export default HandleDataLoad;
