import {styled, Breakpoints} from '@vw-marketing/us-components';

export const DealerCardContainer: any = styled.div`
  display: flex;
  flex-flow: wrap;
  border-bottom: solid 1px #dfe4e8;
  margin-bottom: 27px;
  padding-right: 24px;
`;

export const DealerInfoContainer: any = styled.div`
  padding-top: 9px;
  padding-bottom: 17px;
  display: grid;
  grid-row-gap: 6px;
  width: 100%;
`;

export const DealerSiteContainer: any = styled.div`
  padding-bottom: 35px;
`;

export const DistanceContainer: any = styled.div`
  width: 100%;
`;

export const NameContainer: any = styled.div`
  padding-bottom: 5px;
  color: #000000;
  @media (min-width: ${Breakpoints.b960}px) {
    cursor: pointer;
    &:hover {
      color: rgb(0, 64, 197);
    }
  }
`;

export const DealerListContainer: any = styled.div`
         max-height: 450px;
         overflow-y: scroll;
         overflow-x: hidden;
         scroll-behavior: smooth;
         @media (min-width: ${Breakpoints.b960}px) {
           max-height: 523px;
         }
         ::-webkit-scrollbar {
           width: 7px;
           border-radius: 3.5px;
         }
         ::-webkit-scrollbar-thumb {
           background: #001e50;
           border-radius: 3.5px;
           height: 61px;
         }
       `;
