import {styled} from '@vw-marketing/us-components';

export const StyledFilterAreaWrapper: any = styled.div`
  width: 100%;
  padding-bottom: 33px;
`;

export const StyledTextWrapper: any = styled.div`
  cursor: pointer;
  display: inline-block;
`;

export const StyledFilterList: any = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;
