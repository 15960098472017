import React from 'react';

import {
  DealerModel,
  OfferInformation,
} from '../../../../hooks-store/typings/incentive-store';
import { ModelCard } from '../model-card/ModelCard';
import { NewOfferCardSection } from '../offer-card-section/NewOfferCardSection';
import {
  NewStyledOfferSectionWrapper,
  StyledModelCardWrapper,
  StyledOfferCardWrapper,
} from '../styled';

interface ModelOfferSectionProps {
  readonly offerInformation: OfferInformation;
  readonly dealer?: DealerModel;
  readonly isTier1?: boolean;
  readonly modelMsrp?: string;
}

export const NewModelOfferSection = ({
  offerInformation,
  dealer,
  isTier1,
  modelMsrp,
}: ModelOfferSectionProps): JSX.Element | null => {
  return (
    <NewStyledOfferSectionWrapper>
      <StyledModelCardWrapper>
        <ModelCard offerInformation={offerInformation} modelMsrp={modelMsrp} />
      </StyledModelCardWrapper>
      {offerInformation.offerCount !== 0 && (
        <StyledOfferCardWrapper>
          <NewOfferCardSection
            offerInformation={offerInformation}
            dealer={dealer}
            isTier1={isTier1}
          />
        </StyledOfferCardWrapper>
      )}
    </NewStyledOfferSectionWrapper>
  );
};
